import {React,Component,connect,$,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";

let axios = require('axios').default;

class ShippingCountries extends Component {

    state = {
        'shippingCountries':this.props.shippingCountries,
        'countryId':this.props.countryId,
    }

    constructor(props){
        super(props)
      
        helpers.initConstruct(this);

    }

    componentDidUpdate(prevProps) {
        if(prevProps.shippingCountries !== this.props.shippingCountries) {
          this.setState({
              'shippingCountries': this.props.shippingCountries,
              'countryId': this.props.countryId              
            });
        }
    }

    changeCountryState = (event)=>{        
         let changeValue = event.target.value;
        // let index = $(event.target).data('shipment-index');

        // var shipmentItems = this.state.shipmentItems;

        // shipmentItems.forEach(function(item){
        //     item.selected = false;
        // });
        
        // shipmentItems[index]['selected'] = true;
        // this.state.shipmentId = shipmentItems[index]['id'];

        // this.setState({
        //     'shipmentItems':shipmentItems});

        // save to redux
        this.props.saveShippingCountry(changeValue);
        this.props.handleUpdateParentState({'countryId':changeValue});
    }

    render () {
        return (
                this.state.shippingCountries.length > 1 ? (
                   
                    <div className="form-group hide">
                    <label for="country_id">{tLang('shipping_country')}</label> 
                    <select className="form-control" name="country_id" value={this.state.countryId || ''} onChange={this.changeCountryState} >
                    {
                    this.state.shippingCountries.map((item,i) => {
                            return (
                            <option key={i} value={item.id}>{item.name}</option>   
                            )
                        })
                    }
                    </select>
                   
                    </div>
                    
                )   
                :
                (<p></p>)
        )
    }

}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ShippingCountries));



