import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config, AddressCard, AddressForm,Loading,ShipmentItems,CheckoutTotals,PaymentItems,MetaTag,tLang,ProductName,DisplayPrice}  from "Imports/web";

let axios = require('axios').default;

class Checkout extends Component{

    state = {
        'shipmentAddress':{},
        'shipmentAddressList':[],
        'cartItems':[],
        'shipmentItems':[],
        'checkoutTotalItems':[],
        'paymentItems':[],
        'orderRemark':'',
        'orderRemarkContact':'',
        'orderRemarkGiftCard':'',
        'orderRemarkShipment':'',
        'addressId':this.props.cart.addressId,
        'loading':true,
        'shipmentId':this.props.cart.shipmentId,
        'countryId':this.props.cart.countryId,
        'storeId':this.props.cart.storeId,
        'paymentId':this.props.cart.paymentId,
        'orderRemark':this.props.cart.orderRemark,
        'orderRemarkContact':this.props.cart.orderRemarkContact,
        'orderRemarkGiftCard':this.props.cart.orderRemarkGiftCard,
        'orderRemarkShipment':this.props.cart.orderRemarkShipment,
        'orderRemarkShipmentTime':this.props.cart.orderRemarkShipmentTime,
        'couponCode':this.props.cart.couponCode,
        'paymentCode':''
      }

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this,true,'/checkout');

        this.handleUpdateParentState = this.handleUpdateParentState.bind(this);
        this.changeState = this.changeState.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
        this.goEditAddress = this.goEditAddress.bind(this);

        if (this.props.user.id != null) {
            this.getCheckout();        
        }

        // get user default shipping address
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })


        if (changeName == 'order_remark_contact') {
            this.setState({ ['orderRemarkContact']: event.target.value })

        }


        if (changeName == 'orderRemark') {
            this.props.saveOrderRemark(event.target.value);
        }

        if (changeName == 'order_remark_contact') {
            this.props.saveOrderRemarkContact(event.target.value);
        }

        if (changeName == 'orderRemarkGiftCard') {
            this.props.saveOrderRemarkGiftCard(event.target.value);
        }

        if (changeName == 'orderRemarkShipment') {
            this.props.saveOrderRemarkShipment(event.target.value);
        }

        if (changeName == 'orderRemarkShipmentTime') {
            this.props.saveOrderRemarkShipmentTime(event.target.value);
        }
    }

    handleUpdateParentState(value){
    
        var self = this;
        Object.keys(value).map((key) => {
            self.state[key] = value[key];
        });
        this.setState(value);

        //return false;
        this.getCheckout();
    }

    getCheckout() {
        var self = this;
        
        var params = {
            'shipment_id':this.state.shipmentId,
            'address_id':this.state.addressId,
            'store_id':this.state.storeId,
            'payment_id':this.state.paymentId,
            'country_id':this.state.countryId,
            'coupon_code':this.state.couponCode,
        };

        axios.get(helpers.apiLink('checkout'),{
            params: params}
        ).then(function (response) {

           
            if (typeof response.data.shipmentAddress.id == 'undefined') {
                self.props.saveShipmentAddress('');
            } else {
                self.props.saveShipmentAddress(response.data.shipmentAddress.id );
            }

            if (typeof response.data.shipmentAddress.store_id == 'undefined') {
                self.props.saveShipmentStore('');
            } else {
                self.props.saveShipmentStore(response.data.shipmentAddress.store_id );
            }

            
                // save payment id 
                var paymentId = '';
                var paymentCode = '';
                response.data.paymentItems.forEach(function(item){
                    if (item.selected == true) {
                        paymentId = item.id;
                        paymentCode = item.code;
                        self.props.savePaymentMethod(item.id); 
                    }
                });

                // save shipment id 
                var shipmentId = '';
                response.data.shipmentItems.forEach(function(item){
                    if (item.selected == true) {
                        shipmentId = item.id;
                        self.props.saveShipmentMethod(item.id); 
                    }
                });

            self.setState(
                {   'addressId':response.data.shipmentAddress.id,
                    'storeId':response.data.shipmentAddress.store_id,
                    'shipmentAddress':response.data.shipmentAddress,
                    'shipmentAddressList':response.data.shipmentAddressList,
                    'cartItems':response.data.cartItems,
                    'shipmentItems':response.data.shipmentItems,
                    'paymentItems':response.data.paymentItems,
                    'paymentId':paymentId,
                    'paymentCode':paymentCode,
                    'shipmentId':shipmentId,
                    'checkoutTotalItems':response.data.checkoutTotalItems,
                    'loading':false,
            });
           
        })
        .catch(function (error) {

            // check logined
            if (self.props.user.id == null) {
                self.props.saveSuccessMessage(tLang('please_login'));
                var link = helpers.buildLink("/login");
                window.location = link;
                return true;
            }

            self.props.saveErrorMessage(self.t(error.response.data.error),false);
            helpers.redirect('cart');
           // window.location = "/login"            
        });
    }

    getStateShipmentInfo(shipmentId){
        for (const [key, value] of Object.entries(this.state.shipmentItems)) {
            if (value.id  == shipmentId) {
                return value;
            }
        }
    }

    goNewAddress(){
        helpers.redirect('account/address-book/address?checkout=1');
        return false;
    }

    goEditAddress(){
        helpers.redirect('account/address-book/address/'+ this.state.addressId +'/?checkout=1');
        return false;
    }

    confirmOrder(){
        // var selectedShipment = this.getStateShipmentInfo(this.state.shipmentId);
        // console.log('selectedShipment',selectedShipment);

        var orderData = {
            'discount_amount': 0,
            'store_credit_total': 0,
            'voucher_total': 0,
            'order_remark': this.state.orderRemark,
            'order_remark_contact': this.state.orderRemarkContact,
            'order_remark_gift_card': this.state.orderRemarkGiftCard,
            'order_remark_shipment': this.state.orderRemarkShipment,
            'order_remark_shipment_time': this.state.orderRemarkShipmentTime,
            'shipment_id': this.state.shipmentId,
            'payment_id': this.state.paymentId,
            'address_id':this.state.addressId,
            'store_id':this.state.storeId,
            'coupon_code':this.state.couponCode,
        };

        $('.checkout-submit-button').attr("disabled", true);

        var self = this;
        axios.post(helpers.apiLink('account/orders'),
            orderData
        ).then(function (response) {
           
            if (response.data > 0) {
                // check need redirect payment page?
                if (self.state.paymentCode == 'STRIPE') {
                    helpers.redirect('checkout/payment-stripe/'+response.data);
                } else if (self.state.paymentCode == 'APPLE_PAY') {
                    helpers.redirect('checkout/payment-apple-pay/'+response.data);
                } else {
                    // clean cart data
                    self.props.cleanCart();
                    // go to order detail page
                    self.props.saveSuccessMessage(tLang('order_created'));
                    helpers.redirect('account/orders/'+response.data+'?purchase=1');
                }
            }
        })
        .catch(function (error) {
            $('.checkout-submit-button').attr("disabled", false);
            self.props.saveErrorMessage(self.t(error.response.data.error),false);
            var error_data = error.response.data.error;

            if (error.response.status == '403') {
                helpers.formError(self,error_data);
                return false;
            } else {
                helpers.redirect('cart');
                window.location = "/login"  
            }      
        });
    }

    componentDidUpdate(prevProps) {
       
    }

    render(){

        var self = this;

        let addedCartItems = this.state.cartItems.length ?
        (  
            this.state.cartItems.map((item,i) =>{
                return(
                    <tr key={i}>
                    <td className="product-thumbnail"><img alt='' src={config.imagePathPrefix + item.main_photo}/></td>
                    <td className="cart-title ellipsed">
                        <span className=" ">
                            <a href={item.permalink}><ProductName productName={item.title}  variationName={item.price.variation_name} productType={item.product_type} /></a>
                        </span>      
                        <span className="responsive-content-show-md">
                            <b><DisplayPrice originalPrice={item.price.original_price_format} price={item.price.price_format} />
                            </b>
                        </span>
                        <span className="responsive-content-show-sm">

                        <b>{tLang('quantity')}</b>: {item.qty}
                        <br/>
                        <b>{tLang('total')}</b>: {item.price_total_format}
                        </span>
                    </td>

                    <td className="responsive-hide-col-md"><b><DisplayPrice originalPrice={item.price.original_price_format} price={item.price.price_format} /></b></td>
                    <td className="responsive-hide-col-sm">
                    <b><span className="qty">{item.qty}</span></b>
                    </td>
                    <td className="responsive-hide-col-sm" ><b>{item.price_total_format}</b> </td>
                    </tr>     
                )
            })
        ):

        (
        <tr>
        <td>Nothing.</td>
        </tr>
        );


        function changeShipmentAddress($aId){
            self.props.saveShipmentAddress($aId);
            helpers.reloadPage(1);
        }

        function changeShipmentStore($sId){
            self.props.saveShipmentStore($sId);
            helpers.reloadPage(1);
        }

        let addressList = this.state.shipmentAddressList.map((item,i)=>{
            return (
                <div className="card checkout-address change-address-list darken-1" key={i}>
                    <AddressCard item={item}/>
                    <div className="card-action">
                    {typeof item.store_id == 'undefined' ? 
                        <button type="submit" className="button btn-small" name="save" value="edit" onClick={()=>changeShipmentAddress(item.id) } >{tLang('use_this_address')}</button>
                        :
                        <button type="submit" className="button btn-small" name="save" value="edit" onClick={()=>changeShipmentStore(item.store_id) } >{tLang('use_this_address')}</button>                    
                    }
                    </div>
                    <hr/>
                </div>
                
            )
        });

       return(  
    <div>
        <div class="modal fade" id="newAddressPop"  data-keyboard="false" tabindex="-1" aria-labelledby="newAddressPopLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newAddressPopLabel">{tLang('add_new_address')}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <AddressForm  chooseAddressModalId={'modal-sf-address-new'} aId={''} saveShipmentAddress={true} redirectLink={'checkout'}/>
                    
            </div>
            </div>
        </div>
        </div>

        <div class="modal fade" id="editAddressPop"  data-keyboard="false" tabindex="-1" aria-labelledby="editAddressPopLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editAddressPopLabel">{tLang('address_edit')}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <AddressForm chooseAddressModalId={'modal-sf-address-edit'} aId={this.state.addressId} saveShipmentAddress={true}  redirectLink={'checkout'}/>

                    
            </div>
            </div>
        </div>
        </div>

        <div class="modal fade" id="changeAddressPop"  data-keyboard="false" tabindex="-1" aria-labelledby="changeAddressPopLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="changeAddressPopLabel">{tLang('address_edit')}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              {addressList}
           
            </div>
            </div>
        </div>
        </div>

            <div className="container checkout min-height">
            <Loading loading={this.state.loading} />
            <MetaTag  title={helpers.buildHTitle(tLang('checkout'))} />
                <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"} pt-3`}>
                <h5 className="center">{tLang('checkout')} </h5>          
               
                { this.state.cartItems.length ?

                    <div>  

                        <table className="cart table">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">{tLang('product')}</th>
                            <th scope="col" className="responsive-hide-col-md">{tLang('price')}</th>
                            <th scope="col" className="responsive-hide-col-sm">{tLang('quantity')}</th>
                            <th scope="col" className="responsive-hide-col-sm">{tLang('total')}</th>
                     
                        </tr>
                        </thead>

                        <tbody>

                        {addedCartItems}
                        </tbody>
                        </table>



                        
                    </div>
                    :

                    <p>Nothing.</p>

                    }  

                    <div className="row">
                        <div className="col-12 col-md-6">    
                            <div class="card" >
                            <div class="card-header">
                            {tLang('shipments')} - 請閱讀<a target="_blank" href={helpers.buildLink("/shipping-policy")}>《送貨政策》</a>
                            </div>
                            <form action="#">
                            <ShipmentItems shipmentItems={this.state.shipmentItems} handleUpdateParentState={this.handleUpdateParentState} />
                            </form>
                            </div>
            
                            <div class="card" >
                                <div class="card-header">
                                {typeof this.state.storeId == 'undefined' ? tLang('shipping_address') : tLang('self_pickup_address')} 
                                </div>
                      
                                    <div className="col s12 m9 checkout-shipment-address-block">
                                    {helpers.isEmpty(this.state.shipmentAddress) ?
                                        <div className="card checkout-address default-empty">
                                            <button type="submit" className="button btn-small modal-trigger" data-toggle="modal"  data-target="#newAddressPop"  name="save" value="Register">{tLang('new_shipping_address')}</button>
                                        </div>
                                    :  
                                        <div className="card checkout-address darken-1">
                                            <AddressCard item={this.state.shipmentAddress}/>
                                            <div className="card-action">
                                            {typeof this.state.storeId == 'undefined' ? 
                                                <button type="button" className="button btn-small modal-trigger" data-toggle="modal"  data-target="#newAddressPop"  name="save" value="Register">{tLang('new')}</button>

                                              
                                                :""
                                            }

                                            {this.state.shipmentAddressList.length > 1 ? 
                                                <button type="submit" className="button btn-small modal-trigger" data-toggle="modal"  name="change" data-target="#changeAddressPop"  value="Register">{ typeof this.state.storeId == 'undefined' ? tLang('change'):  tLang('change') }</button>
                                                :""
                                            }
                                            
                                            {typeof this.state.storeId == 'undefined' ? 
                                            
                                                <button type="button" className="button btn-small modal-trigger" data-toggle="modal" data-target="#editAddressPop" name="edit"  value="Register" >{tLang('edit')}</button>
                                                : ""
                                            }
                                            </div>       
                                        </div>
                                    }
                                    </div>
                            </div>

                            <div class="card " >
                            <div class="card-header">
                            {tLang('order_summary')} 
                            </div>
                            <CheckoutTotals items={this.state.checkoutTotalItems}  />
                            </div>   


                        </div>



                        <div className="col-12 col-md-6">   

                        <div class="card" >
                        <div class="card-header">
                        {tLang('payments')}
                        </div>
                        <form action="#">
                        <PaymentItems paymentItems={this.state.paymentItems} handleUpdateParentState={this.handleUpdateParentState} />
                        </form>
                        </div>   

                        

                        <div class="card " >
                            <div class="card-header">
                            {tLang('order_remark')} 
                            </div>
                            <div className="order-remark card-body">
                            <form action="#">
                            <div class="form-group">
                                <label for="order-remark-contact"> <span dangerouslySetInnerHTML={{__html: tLang('order_remark_contact')}} ></span> </label>
                               
                                <input type="text"  id="order_remark_contact" name="order_remark_contact" class="form-control"  onChange={this.changeState} value={this.state.orderRemarkContact} />
                                <small className="helper-text text-danger" >聯絡電話這個是必須的</small>

                                <br/>
                                <label for="order-remark-gift-card"> <span dangerouslySetInnerHTML={{__html: tLang('order_remark_gift_card')}} ></span> </label>
                                <textarea  id="order-remark-gift-card" name="orderRemarkGiftCard" class="form-control" id="order-remark-gift-card" rows="3" onChange={this.changeState} value={this.state.orderRemarkGiftCard} >{this.state.orderRemarkGiftCard}</textarea>
                                <br/>
                                <label for="order-remark-shipment-time">{tLang('order_remark_shipment_time')}</label>
                                <textarea  id="order-remark-shipment-time" name="orderRemarkShipmentTime" class="form-control" id="order-remark-shipment-time" rows="3" onChange={this.changeState} value={this.state.orderRemarkShipmentTime} >{this.state.orderRemarkShipmentTime}</textarea>
                                <br/>
                                <label for="order-remark-shipment">{tLang('order_remark_shipment')}</label>
                                <textarea  id="order-remark-shipment" name="orderRemarkShipment" class="form-control" id="order-remark-shipment" rows="3" onChange={this.changeState} value={this.state.orderRemarkShipment} >{this.state.orderRemarkShipment}</textarea>
                            </div>                        
                            </form>
                            </div>

                            
                        </div>
                        
                        <div className="pt-3 pb-5">
                        <button type="submit" className="checkout-submit-button checkout button full-width  large right" name="save" value="checkout" onClick={this.confirmOrder} >{tLang('confirm_order')}</button>
                        </div>

                        </div>
                    
                    </div>

                  
               
                </div>
            </div>
        </div>     
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Checkout))