import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,Breadcrumbs,helpers,config,Loading,MetaTag,tLang}  from "Imports/web";
import queryString from 'query-string';

let axios = require('axios').default;

 class FbDataDeletion extends Component{
     
    constructor(props){
        super(props)
        helpers.initConstruct(this);
       
        this.state = {
            loading:true,
            data:'',
            status:0,
            pageTitle : helpers.buildHTitle(tLang('about'))

        }

        var self = this;
        this.id = this.props.match.params.id;
        let params = queryString.parse(this.props.location.search);

        axios.get( config.apiEndPoint + 'auth/get-facebook-login-delete-status/'+params.id,
        ).then(function (response) {
            self.setState({
                'status':response.data,
                loading:false
            });
        })
        .catch(function (error) {
            console.log(error);
            // window.location = "/login"
            
        });
    }

    state = {
        data:{}
    };
    
    componentDidMount(){
    }

    render(){

        return(
            <div className="container min-height">
                <MetaTag  title={this.state.pageTitle} />
                <Breadcrumbs type="static" title={'Facebook Login Deletion'}/>

                <Loading loading={this.state.loading} />  
                <div className="box">

                <div className="row">
                    <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`} >
                    <div className={`center`}>
                        <h6>{tLang('status_progress')} : {tLang('status_progress_'+this.state.status)}</h6>
                    </div>    
                   
                    </div> 
                </div>
                
               
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(FbDataDeletion))