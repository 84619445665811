
import {React,Component,connect,$,withTranslation,Splide} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ProductItem,tLang}  from "Imports/web";

let axios = require('axios').default;

class AdBannerHomeGrid extends Component {

  state = {
    'data':this.props.data
  }

  constructor(props){
    super(props)
    helpers.initConstruct(this);

    var self = this;
    // axios.get( helpers.apiLink('ads/banners/home_grid_'+this.props.p)
    //     ).then(function (response) {              
    //       self.setState({'data':response.data});
          
    //     })
    //     .catch(function (error) {
    //         console.log(error);            
    //     });
  }

  
  // action increment
  componentDidMount() {

  };

  componentDidUpdate(prevProps) {

    //if(prevProps.type !== this.props.type) {

   // }

  }

  render () {
  
    let adItemList = this.state.data.map((item,i)=>{
      return(
        <div className="col s12 m4 " key={i}>
        <a target={item.popup == 1 ? '_blank' : '_self' } href={item.url}><img width={`100%`} height={`100%`} className="responsive-img" src={config.imagePathPrefix + item.photo} alt={item.banner_title} /></a>
        </div>
      )
    });

    return (
      
      this.state.data.length ?
         <div className="no-side-m ad-banner-content">
            {adItemList}
         </div>
      :
      ""
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AdBannerHomeGrid));
