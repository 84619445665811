import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config, AddressCard, AddressForm,Loading,ShipmentItems,CheckoutTotals,PaymentItems,MetaTag,tLang,ProductName,DisplayPrice}  from "Imports/web";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


let axios = require('axios').default;

class CheckoutPaymentCheckStatus extends Component{

    state = {
        'clientSecret' :'',
        'orderTotal' :'',
        'loading':true,
    }

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this,true,'/checkout');
        this.oId = this.props.match.params.oId;
        this.checkPaymentStatus = this.checkPaymentStatus.bind(this);
        
        this.checkPaymentStatus();
        setInterval(this.checkPaymentStatus,1000);
    }


    checkPaymentStatus() {
        var self = this;
        self.props.cleanCart();
        var params = {
        };

        // get order id

        axios.get(helpers.apiLink('checkout/payment-check-status/'+self.oId),{
            params: params}
        ).then(function (response) {
            console.log('response',response);
            if (response.data == config.paymentStatusPaid || response.data == config.paymentStatusAwaitingConfirm) {
                var link = helpers.buildLink("/account/orders/"+self.oId+"?purchase=1");
                // clean cart data
                self.props.cleanCart();
                // go to order detail page
                self.props.saveSuccessMessage(tLang('order_created'));
                helpers.redirect(link);
            }
        })
        .catch(function (error) {

            // check logined
            if (self.props.user.id == null) {
                self.props.saveSuccessMessage(tLang('please_login'));
                var link = helpers.buildLink("/login");
                window.location = link;
                return true;
            }          
        });
    }

    componentDidUpdate(prevProps) {
 
       
    }

    render(){
        var self = this;
        return(
            <div className="container checkout min-height">
            <Loading loading={this.state.loading} />
            <MetaTag  title={helpers.buildHTitle(tLang('checkout'))} />
                <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`}>
                    <br/>
                    <div className="section shipment">
                    <div className="row">
                        <div className="col l6 s12  offset-l3 ">
                        </div>
                    </div>
                    </div>
                </div>
            </div>
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CheckoutPaymentCheckStatus))