import {React,useTranslation} from "Imports";
import {config}  from "Imports/web";

import { useIntl } from 'react-intl';

import {helpers} from "Imports/web";

import zh_Hk_Wording from 'langs/zh-HK.js';

import en_wording from 'langs/en.js';


function tLang(keyword) {

    //console.log('keyword',keyword);
   
    var currentLang = helpers.getCurrentLang();

    var tKeyword = '';

    if (currentLang == 'zh-HK') {
        tKeyword = zh_Hk_Wording[keyword];
    } else if (currentLang == 'en') {
        tKeyword = en_wording[keyword];
    } else {
        tKeyword = zh_Hk_Wording[keyword];
    }

    return tKeyword;
}

export default tLang;

