
import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,ProductItem,Pagination,config,Breadcrumbs,helpers,MetaTag,tLang}  from "Imports/web";

const axios = require('axios').default;

 class OnSaleList extends Component{

    handleClick = (id)=>{
       // this.props.addToCart(id); 
    }

    state = {
        items: [],
        pageTitle:helpers.buildHTitle(tLang('on_sale')),
        pageDesc:''
    };

    constructor(props){
        super(props)

        var self = this;
        helpers.initConstruct(self);
        self.id = this.props.match.params.id;
      
        axios.get(helpers.apiLink('discount/products-list?page='+self.pageNum)
        ).then(function (response) {
            self.activePage = response.data.current_page;
            self.lastPage = response.data.last_page;
            self.setState({
                'items':response.data.data,
                'pageDesc':helpers.isEmpty(response.data.meta_desc)? helpers.buildHTitle(tLang('on_sale')) : response.data.meta_desc,
            });
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });

        //this.setState([{'a':'1'}]);
        //this.updateLanguage = this.updateLanguage.bind(this);
    }



    componentDidUpdate(){
        helpers.checkPaginationUpate(this); 
    }

    render(){
        let itemList = this.state.items.map(item=>{
            return(
                <div className="col s6 m6 l4 ">   
                <ProductItem item={item}/>
                </div>  
            )
        })
        return(       
            <div className="container product-list min-height">
                <MetaTag  title={this.state.pageTitle} desc={this.state.pageDesc} />
                <Breadcrumbs type="static" title={tLang('on_sale')}/>

                {/* <h3 className="center">Product List{this.stats}</h3> */}
                <div className="box">
                <div className="row">
                    {itemList}
                </div>
                <Pagination totalPages={this.lastPage} originLink={this.originLink} activePage={this.activePage} />
                </div>
            </div>
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//      //   addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OnSaleList))