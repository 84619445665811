import {React,useTranslation} from "Imports";
import {config,tLang}  from "Imports/web";

const CheckoutTotals = (props) => {

    var item = props.items;
    const {t} = useTranslation();

    let orderDiscount = '';
    
    return (
        <table className="table">
        <tbody>
        <tr>  
            <td>{tLang('product_total')} </td>
            <td>{item.product_total_format}</td>
        </tr>
        { item.has_discount  > 0 ?
        <tr>
            <td>{tLang('order_discount')} </td>
            <td>{item.order_discount_format} ({item.order_discount_remark})</td>
        </tr> : ''
        }
        <tr>
            <td>{tLang('shipment_fee')} </td>
            <td>{item.shipment_fee_cod == 1 ? tLang('sf_shipping_cod') : item.shipping_fee_format}</td>
        </tr>
        <tr>     
            <td>{tLang('order_amount')} </td>
            <td class="final-order-total">{item.order_amount_format}</td>
        </tr>
        </tbody>
        </table>
    )
}

export default CheckoutTotals;

