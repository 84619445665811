import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Navbar from './components/common/Navbar';
import SideMenu from './components/common/NavbarSideMenu';
import Home from './components/routes/Home';
import Cart from './components/routes/Cart';
import Checkout from './components/routes/Checkout';
import ProductList from './components/routes/ProductList';
import ProductDetail from './components/routes/ProductDetail';
import Footer from './components/common/Footer';
import Init from './components/common/Init';
import Login from './components/routes/Login';
import Account from './components/routes/Account';
import ChangePassword from './components/routes/ChangePassword';
import Logout from './components/routes/Logout';
import config from './config';
import { useIntl } from 'react-intl';
import SearchList from './components/routes/SearchList';
import AddressDetail from 'components/routes/AddressDetail';
import AddressList from 'components/routes/AddressList';
import OrderDetail from 'components/routes/OrderDetail';
import OrderList from 'components/routes/OrderList';
import About from 'components/routes/About';
import Contact from 'components/routes/Contact';
import SalesPromoteDetail from './components/routes/SalesPromoteDetail';
import HotPicksList from './components/routes/HotPicksList';
import OnSaleList from './components/routes/OnSaleList';
import ForgotPassword from './components/routes/ForgotPassword';
import VerifyCode from 'components/routes/VerifyCode';
import TermAndCondition from 'components/routes/TermAndCondition';
import PrivacyPolicy from 'components/routes/PrivacyPolicy';
import ShippingPolicy from 'components/routes/ShippingPolicy';
import Article from 'components/routes/Article';
import CheckoutPaymentStripe from 'components/routes/CheckoutPaymentStripe';
import CheckoutPaymentStripeApplePay from 'components/routes/CheckoutPaymentStripeApplePay';
import CheckoutPaymentCheckStatus from 'components/routes/CheckoutPaymentCheckStatus';
import FbDataDeletion from 'components/routes/FbDataDeletion';
import TopNotice from 'components/common/TopNotice';

function App() {
 
  const intl = useIntl()
  let langBase = '';
  if (intl.locale === config.defaultLang) {
    langBase = '';
  } else {
    langBase = intl.locale;
  }

  //console.log('langBase',intl.locale);

  //const { t, i18n } = useTranslation()
  //const intl = useIntl()
  //i18n.changeLanguage('zh-HK');
  return (
    <BrowserRouter basename={langBase}>
    <Init />
    <div className="App">
        <TopNotice />
        <Navbar/>
        <SideMenu/>
        <Switch>   
          <Route exact path="/" component={Home}/>
          <Route path="/cart" component={Cart}/>
          <Route path="/checkout/payment-stripe/:oId" component={CheckoutPaymentStripe}/>
          <Route path="/checkout/payment-apple-pay/:oId" component={CheckoutPaymentStripeApplePay}/>
          <Route path="/checkout/payment-check-status/:oId" component={CheckoutPaymentCheckStatus}/>
          <Route path="/checkout" component={Checkout}/>
          <Route path="/search/:keyword" component={SearchList}/>
          <Route path="/**-c-:cId" component={ProductList}/>
          <Route path="/**-p-:pId" component={ProductDetail}/>
          <Route path="/login" component={Login} />    
          <Route path="/account/orders/:oId" component={OrderDetail} />
          
          <Route path="/account/orders" component={OrderList} />

          <Route path="/account/address-book/address/:aId" component={AddressDetail}/>
          <Route path="/account/address-book/address" component={AddressDetail}/>
          <Route path="/account/address-book" component={AddressList}/>
          <Route path="/account/change-password" component={ChangePassword} />
          <Route path="/account" component={Account} />
          <Route path="/logout" component={Logout} />
          <Route path="/about-us" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/sale-promotion/:id" component={SalesPromoteDetail}/>
          <Route path="/hot-picks" component={HotPicksList}/>
          <Route path="/on-sale" component={OnSaleList}/>
          <Route path="/forgot-password" component={ForgotPassword}/>       
          <Route path="/verify-code" component={VerifyCode}/>
          <Route path="/tnc" component={TermAndCondition} />    
          <Route path="/privacy-policy" component={PrivacyPolicy} /> 
          <Route path="/shipping-policy" component={ShippingPolicy} />
          <Route path="/**-a-:id" component={Article} />
          <Route path="/fb-data-deletion" component={FbDataDeletion} />
        </Switch>
      <Footer/>
     
      
    </div>
    </BrowserRouter>
  );
}

// App.propTypes = {
//   intl: intlShape.isRequired,
// };

export default App;
