
import {React,Component,connect,$,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";
import { HelpBlock } from "react-bootstrap";

let axios = require('axios').default;

class ProductDetailOptions extends Component {

  state = {
    'data':[],
    'product_quantity':'1',
    'product_quantity_max':'12',
    'remark':'',
    'addToCartBtn':true,
    //'attributeData'
  }

  constructor(props){
    super(props)
  
    helpers.initConstruct(this);

     //axios = helpers.reviseApiHeader(this,axios);
    //  axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.user.token}`
    //  axios.defaults.headers.common['lang'] =  config.defaultLang;
    //  axios.defaults.headers.common['channel-id'] =  config.channelId;
    this.changeQtyState = this.changeQtyState.bind(this);
    this.changeQtyStateIncrease = this.changeQtyStateIncrease.bind(this);
    this.changeQtyStateDecrease = this.changeQtyStateDecrease.bind(this);
    
  }

  changeQtyState(event){
    let changeName = event.target.name
    this.setState({ [changeName]: event.target.value })
  }

  changeQtyStateIncrease(event){
    var product_quantity =  this.state.product_quantity;
    if (this.state.product_quantity < this.state.product_quantity_max) {
        product_quantity++;
    }
    this.setState({ 'product_quantity':product_quantity })
  }

  changeQtyStateDecrease(event){
    var product_quantity =  this.state.product_quantity;
    if (this.state.product_quantity > 1) {
      product_quantity--;
    }
    this.setState({ 'product_quantity': product_quantity })
  }

  // action increment
  componentDidMount() {

  };

  componentDidUpdate(prevProps) {

    if(prevProps.selectOptionsData !== this.props.selectOptionsData) {    
      this.setState({data: this.props.selectOptionsData});
    }

    if(prevProps.pId !== this.props.pId) {

      var self = this;
      axios.get( config.apiEndPoint + 'product/'+this.props.pId+'/attribute-options'
          ).then(function (response) {
              var all_is_out_stock = true;
              var remark = tLang('out_of_stock');
              response.data.map((item,i)=>{
                item.productattributeoptions.map((item2,k) => {
                    if ( item2.is_available == 1 ) {
                      all_is_out_stock = false;
                      remark = '';
                    }
                });
              });

              // self.selectOptionsData = response.data.attribute_options;
              // console.log('self.setState',self.selectOptionsData);
              self.setState({
                'data':response.data,
                'addToCartBtn':!all_is_out_stock,
                'remark':remark,
              });

              // console.log('self.selectOptionsData',self.selectOptionsData);

          })
          .catch(function (error) {
              console.log(error);
            // window.location = "/login"
              
          });
    }

  }

  addToCart = (event) => {
   
    var selected_option_values = [];
    var checkAddToCartError = false;
    $('[class^=product_attribute_option]').each(function(index){
      selected_option_values.push($(this).val());
      if ($(this).val() == '')
      {
          helpers.showErrorMsg('Please select the product options');

          checkAddToCartError = true;
          return false;
      }
    });

    if (!checkAddToCartError) {
      var productCode = selected_option_values.join('-');

      if (productCode != '') {
       
        var addQty = $('.quantity-input').val(); 
        var cartData = {
          'action':'add',
          'productCode':productCode,
          'qty':addQty}
    
        var self = this;
        axios.put( config.apiEndPoint + 'cart/update',cartData
        ).then(function (response) {
            console.log('addToCart:',response.data);
            helpers.showMsg(tLang('added_to_cart'));
            
             // update cart redux
            self.props.saveCartItemCount(helpers.getCartItemCount()+1);


        })
        .catch(function (error) {
            console.log(error);
            // window.location = "/login"
            
        });
      }   
    }
  }

  changeOptions = (event) => {
    var triggerAttributeId = $(event.currentTarget).data('attribute-id');
    var optionId = $(event.currentTarget).val();
    if(typeof this.props.attributePhotoMap[optionId] != 'undefined') {
      this.props.splidePhotoMain.go(this.props.attributePhotoMap[optionId]);
    }

    //$('#image-slider').go( '+1' );
    // set first search by attribute id
    if (typeof this.firstSelectAttributeId == 'undefined') {
      this.firstSelectAttributeId = triggerAttributeId;
    }

    if (this.firstSelectAttributeId == triggerAttributeId) {
      $('[class^=product_attribute_option]').each(function(index){
          if ($(this).data('attribute-id') != triggerAttributeId) {
            $(this).val('');
          }
      });
    }

    var selected_option_values = [];
    var productCodeTemp = [];
    $('[class^=product_attribute_option]').each(function(index){
        productCodeTemp.push($(this).val());
        selected_option_values.push($(this).data('attribute-id')+':'+ ( $(this).val() == '' ? '?' :  $(this).val()) ) ;
    });

    //var trigger_attribute = 
    selected_option_values = selected_option_values.join('-');

   

    var self = this;
    if (selected_option_values.indexOf('?') > -1)
    {
      //get select options
      axios.get( config.apiEndPoint + 'product/'+this.props.pId+'/attribute-options?trigger_attribute_id='+ triggerAttributeId +'&selected_attribute_options='+ selected_option_values 
          ).then(function (response) {
              // self.selectOptionsData = response.data.attribute_options;
              // console.log('self.setState',self.selectOptionsData);
              self.setState({'data':response.data
                            });

          })
          .catch(function (error) {
              console.log(error);
             // window.location = "/login"
              
          });
  
    } else {
        //get price and Qty
        var productCode = productCodeTemp.join('-');
        axios.get( config.apiEndPoint + 'product/price-qty?product_code='+productCode
        ).then(function (response) {
       
            $('.price-block .final-price').html(response.data.price_format);
            if(response.data.discount_price_saved > 0) {
                $('.price-block .price').html(response.data.original_price_format);
                $('.price-block .price').show();
            } else {
              $('.price-block .price').hide();
            }

            // self.setState({ ['product_quantity_max']: response.data.qty });
            // self.setState({ ['remark']: response.data.remark});
            self.setState({ 
              'remark': response.data.remark,
              'product_quantity_max': response.data.allow_add_cart_qty,
              'addToCartBtn': (response.data.allow_add_cart_qty) ? true:false, 
            });

            if(response.data.allow_add_cart_qty > 0) {
              $('.quantity-block .button').css({'background-color':'black','cursor': 'pointer'})
            } else {
              $('.quantity-block .button').css({'background-color':'#4848502b','cursor': 'not-allowed'})
            }

            //console.log('get price and Qty ');
            
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });
    }

    
    //this.setState({data: this.props.selectOptionsData});

    //this.state.data.attribute_options = [];
  }

  render () {
    let attributeOptions = this.state.data.map((item,k )=>{

      return(
        <div class="form-group">
            <label htmlFor="ac-bm">{item.name}</label>    
              <select className={"product_attribute_option form-control"} data-attribute-id={item.attribute_id} id={"product_attribute_option_" + item.attribute_id} name={"product_attribute_option_" + item.attribute_id} onChange={this.changeOptions} >
              { this.props.productType == 1 ? 
                  '' : <option value="">{tLang('please_choose')}</option>
              }  
              
              { item.hasOwnProperty('productattributeoptions')?       
                  item.productattributeoptions.map( (item2,i) => {
                  return ( <option key={i} disabled={item2.is_available ? '' : 'disabled'}  value={item2.product_attribute_option_id}>{item2.name} {item2.is_available ? '' : '('+ tLang('out_of_stock')+')'}</option>  )
                      }
                  )
                  : '' 
              }
              </select>
            
             
         
        </div>
      )
      
    })

    return (
      <div className="product-option-block container">
         <div className="variation-block">
         <div className="row">
         <div className="col-sm-12 col-md-6 m12 l12 p-0"> 
          <form className={`variation-select-option ${this.props.productType == 1 ? "hide" : ''}`}>
            {attributeOptions}
          </form>
         </div>
         </div>
         </div> 
         
         <div className="quantity-block">
         <div className="row">
          <div className="quantity">
              <span className="product-decrease qty-button" onClick={this.changeQtyStateDecrease}>
              <i className="fas fa-minus"></i>
              </span>
              <input type="number" className="quantity-input" name="product_quantity" value={this.state.product_quantity} onChange={this.changeQtyState} min="1" max={this.state.product_quantity_max} />
              <span className="product-increase  qty-button" onClick={this.changeQtyStateIncrease}>
              <i className="fas fa-plus"></i>
              </span>     
          </div>
        </div>
        </div>
        <br/><br/>
          <button type="submit" name="add-to-cart" value="246" disabled={this.state.addToCartBtn ? '' : 'disabled'} className="single-add-to-cart-button button alt col-sm-12 col-md-6" onClick={this.addToCart}> {this.state.addToCartBtn ? tLang('add_to_cart') : tLang('out_of_stock')}</button>
         
      </div>

    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ProductDetailOptions));

//export default SideMenu;