
import {React,Component,connect,withTranslation,$,Helmet,Splide} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,AdProductsSlide}  from "Imports/web";
import 'reactjs-popup/dist/index.css';

let axios = require('axios').default;

class TopNotice extends Component{

    state = {
        'show': false,
        'id' :'',
        'url':'',
        'popup':0,
        'title':''
    }
 
    constructor(props){
        super(props)
        helpers.initConstruct(this);
        var self = this;
        axios.get( helpers.apiLink('ads/ad-notice-top')
        ).then(function (response) {              
            console.log('response.data',response.data);
            if (response.data.length > 0) {
                var adNotice = response.data[0];

                self.setState({
                    'show': true,
                    'url':adNotice['url'],
                    'title':adNotice['title'],
                });   
            }
        })
        .catch(function (error) {
            console.log(error);            
        });
    }
 

    componentWillUnmount() {
       
    }

    handleChecked = (e)=>{
        this.state.show = false;
        this.setState({show:this.state.show})
    }
    componentDidUpdate() {
        $(document).ready(function(){
           // $('.modal').modal();
        });
        //$('#popup-banner-modal').modal();
    }

    render(){ 
        return( 
                this.state.show == true ? 
                <div className='ad-notice-block'>
                    <div className='ad-notice-content'>{this.state.title}</div>
                </div>
                :
                ""     
        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(TopNotice)