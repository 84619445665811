import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config, AddressCard, AddressForm,Loading,ShipmentItems,CheckoutTotals,PaymentItems,MetaTag,tLang,ProductName,DisplayPrice}  from "Imports/web";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from 'components/common/CheckoutForm';
import CheckoutFormApplePay from 'components/common/CheckoutFormApplePay';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_YVzIqUTwiCYcEXO1DPqDrM98');
//const stripePromise = loadStripe('pk_test_51JiyX0Jm72DVqpckDtbbE0xz9tohhtTU9tM6lScIPrj6JkELlkM3ucDP2uVIj6VI6estu3eo8Iq0xUOHPsyTECx600ltSWCQxx');
let stripePromise = '';

let axios = require('axios').default;

class CheckoutPaymentStripeApplePay extends Component{

    state = {
        'clientSecret' :'',
        'stripeKey':'',
        'orderTotal' :'',
        'orderCurreny' :'',
        'orderAmount' : '',
        'loading':true,
      }

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this,true,'/checkout');
        this.changeState = this.changeState.bind(this);
        this.oId = this.props.match.params.oId;
        this.goPayment();
        
        // get user default shipping address
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })

        if (changeName == 'orderRemark') {
            this.props.saveOrderRemark(event.target.value);
        }

        if (changeName == 'orderRemarkContact') {
            this.props.saveOrderRemarkContact(event.target.value);
        }

        if (changeName == 'orderRemarkGiftCard') {
            this.props.saveOrderRemarkGiftCard(event.target.value);
        }

        if (changeName == 'orderRemarkShipment') {
            this.props.saveOrderRemarkShipment(event.target.value);
        }

        if (changeName == 'orderRemarkShipmentTime') {
            this.props.saveOrderRemarkShipmentTime(event.target.value);
        }
    }

    goPayment() {
        var self = this;
        // get order id

        axios.get(helpers.apiLink('checkout/payment-stripe-init/'+this.oId),{}
        ).then(function (response) {
            if (response.data.stripe_key != '') {
                stripePromise = loadStripe(response.data.stripe_key);      
            }

            self.setState({
               'clientSecret':response.data.client_secret,
               'orderTotal': response.data.currency.toUpperCase() + ' ' + response.data.order_amount,
               'orderCurreny': response.data.currency.toUpperCase(),
               'orderAmount':  response.data.order_amount,
               'loading': false
            });
        })
        .catch(function (error) {

            // check logined
            if (self.props.user.id == null) {
                self.props.saveSuccessMessage(tLang('please_login'));
                var link = helpers.buildLink("/login");
                window.location = link;
                return true;
            }          
        });
    }

    componentDidUpdate(prevProps) {

    }

    render(){
        var self = this;
        const options = {
            // passing the client secret obtained from the server
            clientSecret: this.state.clientSecret,
        };

        console.log('stripe options',options);
       return(  
    
            <div className="container checkout min-height">
            <Loading loading={this.state.loading} />
            <MetaTag  title={helpers.buildHTitle(tLang('checkout'))} />
                <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`}>
                <h5 className="center">{tLang('apply_pay_payment')} </h5>  
                    <br/>        
                    <h4 className="center">{tLang('order_amount')} {this.state.orderTotal}</h4>
                    <br/> 
                    <div className="section shipment">
                    <div className="">
                        <div className="col l6 s12 notice-block ">
                        {this.state.clientSecret != '' ?
                            <Elements stripe={stripePromise} options={options}>
                            <CheckoutFormApplePay oId={this.oId} currency={this.state.orderCurreny} amount={this.state.orderAmount} clientSecret={options.clientSecret} />
                            </Elements>
                            :''
                        }
                        </div>
                       
                    </div>
                    </div>
                    <p className="center"><a href={helpers.buildLink('checkout')}><b>{tLang('return_other_payments')}</b></a></p>
                </div>
            </div>
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CheckoutPaymentStripeApplePay))