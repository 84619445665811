const initState = {
    category:[],
    test6:[],
    viewProduct:[]
}
const pageReducer= (state = initState,action)=>{
       
    if(action.type === 'CLEAN_PAGE_RECORD'){
        state = {};
        return{
            ...state,
       }
    }

    if(action.type === 'SAVE_CATEGORY'){
       
        //console.log('action.data',action)
       // if (!state.category.includes(action.data)){
            state.category=action.data;
       // }
        
        return{
            ...state,
        }
    }

    if(action.type === 'CHECK_INIT'){
        console.log('CHECK_INIT inini',state);
      //  const returnedTarget = Object.assign(state,initState);
       // console.log('returnedTarget',returnedTarget);
        // for (const [key, value] of Object.entries(initState)) {
        //     console.log(`${key}: ${value}`);
        // }

        return{
            ...state,
        }
    }
    
    return state
}

export default pageReducer