import {React,useTranslation} from "Imports";

const ProductName = (props) => {
    const {t} = useTranslation();

    if ( props.variationName == undefined ) {
        return (
            <span>
            {
                props.productName.includes('---') ? 
                <span> {props.productName.split(' --- ')[0]}<br/>({props.productName.split(' --- ')[1]})</span>
                : props.productName
            }
            </span>
        )
    } else {
        return (
            <span>
            { props.productType == 1 ?
                <span>{props.productName}</span>
                : 
                <span>{props.productName} <br/> ({props.variationName})</span>
            }
            </span>
        )
    }
   
}

export default ProductName;

