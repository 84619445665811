
import {React,Component,connect} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers}  from "Imports/web";


let axios = require('axios').default;

 class Logout extends Component{
    
    logout(){
        var self = this;
       
        self.props.logout(self.state);
        self.props.getSession('');
        axios.get( helpers.apiLink('auth/logout'),this.state
        )
          .then(function (response) {

            self.props.logout(self.state);
            self.props.getSession('');
            window.location = helpers.buildLink("/login");

            //self.props.login(response.data);

            //if (response.data.token != '') {
                //window.location = "/cart"
            //} else {
            //    window.location = "/login"
            //}

          })
          .catch(function (error) {
            console.log(error);
           
            self.props.logout(self.state);
            self.props.getSession('');
            window.location = helpers.buildLink("/login");
            
          });

    }
    
    constructor(props){
        super(props)
        axios = helpers.reviseApiHeader(this,axios);
        this.state = {
            name :'',
            phone :'',
            email :'',
            address_1 :'',
            birthday_month :'',
            loading : true,
        };
        this.changeState = this.changeState.bind(this);
       // axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.user.token}` 
        //if (typeof this.props.user.token != 'undefined') {
           this.logout();
        //} else {
        //   window.location = helpers.buildLink("/login");
        //   return false;
        //}    
    }

    changeState(event){    
    }

    componentDidMount(){    
    }
    
    render(){
        return(<div></div>);
    }
}
// const mapStateToProps = (state)=>{
//     return {
//       user: state.userReducer,
//       session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//         logout: (data)=>{dispatch(logout(data))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(Logout)