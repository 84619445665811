import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import config  from './config';
// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
    const path = window.location.pathname;
    const pathAr = path.split("/");

    if (config.langSupport.indexOf(pathAr[1]) > -1) {
        if (state.userReducer.lang !== pathAr[1]) {
            // set state language
            state.userReducer.lang = pathAr[1];
        }
    }else{
        // change to default language
        if (typeof(state.settingReducer.defaultLang) == "undefined") {
            state.userReducer.lang = config.defaultLang;
        } else {
            state.userReducer.lang = state.settingReducer.defaultLang;
        }
    }

   

    if (typeof(state.userReducer.lang) != "undefined") {
        const  lang  = state.userReducer.lang;
        return { locale: lang, key: 'zh-HK' };
    } else {
        var lang = state.settingReducer.defaultLang;
        return { locale: lang, key: lang };
    }
}

export default connect(mapStateToProps)(IntlProvider);