import {React,useTranslation} from "Imports";
import {useState, useEffect} from 'react';
import {useStripe, useElements, PaymentElement, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import {mapStateToProps,mapDispatchToProps,helpers,config,Init,tLang}  from "Imports/web";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const oId = props.oId;
  const total = props.total;
  const currency = props.currency;
  const amount = props.amount;
  const clientSecret = props.clientSecret;


  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'HK',
        currency: currency.toLowerCase(),
        total: {
          label: 'Order total',
          amount: amount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

       // Check the availability of the Payment Request API.
       pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

    }
  }, [stripe]);
  console.log('aaaaaaaaa');
  if (paymentRequest) {
    console.log('paymentRequest');

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            helpers.showErrorMsg('The payment failed, please try again.');
          } else {
            // The payment has succeeded.
            helpers.redirect(config.shopDomain+helpers.buildLink('checkout/payment-check-status/'+oId));
          }
        } else {
          // The payment has succeeded.
          helpers.redirect(config.shopDomain+helpers.buildLink('checkout/payment-check-status/'+oId));
        }
      }
    });

    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }

  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: config.shopDomain+helpers.buildLink('checkout/payment-check-status/'+oId),
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
   
    <form onSubmit={handleSubmit}>
      <div>{tLang('apple_pay_remark')}</div>
      <br/>
      <li>{tLang('apple_pay_check_0')}</li> 
      <li dangerouslySetInnerHTML={{__html: tLang('apple_pay_check_1')}} ></li>
      <li>{tLang('apple_pay_check_2')}</li> 
      <li>{tLang('apple_pay_check_3')}</li>
      <li>{tLang('apple_pay_check_4')}</li>
      <br/><br/>
    </form>
    
  )
};

export default CheckoutForm;