import {React,Component,connect,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";
import PropTypes from 'prop-types';

let axios = require('axios').default;

class SideMenu extends Component {

  state = {
    navItems: [],
  };

  constructor(props){
    super(props)
    helpers.initConstruct(this);  

    var self = this;
    axios.get(config.apiEndPoint + 'page/navigation'
    ).then(function (response) {

    self.props.saveCategory(response.data.top);
        
        self.setState({
           'navItems':response.data.list});
           
       // self.navCatData = response.data;
    })
    .catch(function (error) {
        console.log(error);
       // window.location = "/login"
        
    });

  }


    // action increment
    componentDidMount() {
   
  };

  updateLanguage(lang){

    helpers.updateLanguage(lang);
    // var currentPath = window.location.href.replace(window.location.origin, "");
    // var url = helpers.buildLink(currentPath,lang);
    // helpers.redirect(url);
  }

  closeNav(){
    //console.log('closeNav');
    //document.getElementById("mySidenav").style.width = "0";

  }

  render () {

    let itemList = this.state.navItems.map((item,i)  =>{
      return(
<form class="accordion nav-side-item" id="accordion-item">
       
          { item.hasOwnProperty('children')?


          <div>
              <a class="side-nav-link" data-toggle="collapse" data-target={"#collapseItem-"+ item.id} aria-expanded="true" aria-controls={"#collapseItem-"+ item.id}>
              {item.name}<i class="fas fa-angle-down"></i>
              </a>
              <div id={"collapseItem-"+ item.id} class="collapse subItem " aria-labelledby="headingOne" data-parent="#accordion-item">
              {item.children.map( (item2,k) => {
                  return (<a  href={ item.permalink.includes("http") ? item2.permalink : '/'+ item2.permalink}>{item2.name}</a>)
                  }
              )}
              </div>
          </div>


             
              :  <a href={ item.permalink.includes("http") ? item.permalink : '/'+ item.permalink}>{item.name}</a>
          }

</form>
         
         

         
      )
    })


    return (

<div>
<div className="user-menu-overlay"></div>
      <div className="sideUserMenu">
          <div> <i className="fas fa-times closeSideUserMenu"></i> </div>
          <div className="sideUserMenuContent">
           
            <ul className="user-menu-ul">
                <li><a className="" href={helpers.buildLink("/account")}><i class="fas fa-user user-menu-icon"></i>{tLang('my_profile')}</a></li>
                <li><a className="" href={helpers.buildLink("/account/orders")}><i class="fas fa-file-invoice-dollar  user-menu-icon"></i>{tLang('my_orders')}</a></li>
                <li><a className="" href={helpers.buildLink("/account/address-book")}><i class="fas fa-map-marker user-menu-icon"></i>{tLang('address_book')}</a></li>
                <li><a className="" href={helpers.buildLink("/account/change-password")}><i class="fas fa-key user-menu-icon"></i>{tLang('change_password')}</a></li>
                <li><a className="" href={helpers.buildLink("/logout")}><i class="fas fa-key user-menu-icon"></i>{tLang('logout')}</a></li>

                </ul>
          </div>
      </div>

<div id="mySidenav" class="sidenav">
          <a href="#" class="closebtn"><i role={"link"}  onClick={this.closeNav} aria-label="menu"  class="fas fa-times"></i></a>

          <div className="sideSearchMenuContent">
            <div className="searchBox">
              <input type="text" name="search" className="search-input focused" placeholder={tLang('search')} />
              <i className="fas fa-search"></i> 
            </div>
          </div>
         


          {itemList}
</div>
</div>

    );
  }
}

SideMenu.propTypes = {
  navigation: PropTypes.object
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SideMenu));
