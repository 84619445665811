
import {React,Component,connect,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,OrderItem,Pagination,Breadcrumbs,helpers,AccountSideBar,Loading,MetaTag,tLang}  from "Imports/web";



const axios = require('axios').default;

 class OrderList extends Component{

    handleClick = (id)=>{
        this.props.addToCart(id); 
    }

    state = {
        items: [],
        loading : true,
    };

    constructor(props){
        super(props)

        var self = this;
        helpers.initConstruct(self);
        helpers.checkLoginRedirect(this);
        self.cId = this.props.match.params.cId;
      
        axios.get(helpers.apiLink('account/orders?page='+self.pageNum)
        ).then(function (response) {
            self.activePage = response.data.current_page;
            self.lastPage = response.data.last_page;
            self.setState({
                    'items':response.data.data,
                    loading:false
                });
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });
    }

    componentDidUpdate(){
      
        helpers.checkPaginationUpate(this);
        
    }

    render(){
        let itemList = this.state.items.map((item,i)=>{
            return(
                <div className="card order-list-item darken-1" key={i}>
                <OrderItem item={item}/>
                </div>   
            )
        })
        return(
            <div className="container order-list min-height">
                <MetaTag  title={helpers.buildHTitle(tLang.my_account)} />
                <Loading loading={this.state.loading} />

                <div className={`row  ${this.state.loading ? "hide" : " show"} `}>
                    <div className="account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3 " >
                        <AccountSideBar />
                    </div>  

                    <div className={`col-12 col-md-9 pt-3`  }>
                    <h3 className="center" >{tLang('my_orders')}</h3>
                
                    <div className="row justify-content-center justify-content-md-center">
  
                    { this.state.items.length ? 
                    
                    <div id="profile" className="col-sm-12 col-md-6">
                    
                    <div className="box">
                    
                        <br/>
                        {itemList}
                    
                    <Pagination totalPages={this.lastPage} originLink={this.originLink} activePage={this.activePage} />
                    
                    </div>
                    </div>
                    :
                        <div><br/>{tLang('order_empty')}</div>
                    }
                    </div>
                    </div>
                </div>

            </div>
        )
    }
}
// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//         session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//         addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OrderList))