import {React} from "Imports";
import {config}  from "Imports/web";

const AddressCard = (props) => {

    var item = props.item;

    return (


<div class="card address-display" >
  <div class="card-text">
  <div class="card-body">
    <h5 class="card-title"><span>{item.recipient_name}</span> <span className="phone">{item.recipient_phone}</span> </h5>
    <p class="card-text">
         <p>{item.address_1}</p>
        <p>{item.address_2}</p>
        
        <p><span>{item.city_name}</span> <span className="">{item.state_name}</span></p>
        
        <p><span>{item.country_name}</span></p>
    </p>
  </div>
</div>
</div>

       
    )
   
}

export default AddressCard;

