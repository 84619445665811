import {React,Helmet} from "Imports";
import {config,helpers,LdDataProduct}  from "Imports/web";

const MetaTag = (props) => {  
    return (
        <Helmet>
        <html lang={helpers.getLang()} />
        {/* <title1>{props.title}</title1> */}
        <link rel="icon" href={helpers.getFavicon()}></link>
        {/* <meta name="description1" content={ helpers.isEmpty(props.desc) ? helpers.getMetaDesc() :  props.desc + helpers.getMetaDesc() }/> */}
        </Helmet>
    )
}

export default MetaTag;

