import { createStore ,combineReducers} from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import cartReducer from './components/reducers/cartReducer';
import userReducer from './components/reducers/userReducer';
import notifyReducer from './components/reducers/notifyReducer';
import sessionReducer from './components/reducers/sessionReducer';
import settingReducer from './components/reducers/settingReducer';
import pageReducer from './components/reducers/pageReducer';
import versionReducer from './components/reducers/versionReducer';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import config from 'config.js';

  const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
  }

    const appReducer = combineReducers({
    cartReducer,
    userReducer,
    notifyReducer,
    sessionReducer,
    settingReducer,
    pageReducer,
    versionReducer,
  })

  const currentVersion = '';

  const rootReducer = (state, action) => {

    
    if (action.type === 'CHECK_VERSION') {
      //pageReducer(state, {type:'CHECK_INIT'})
     
      //console.log('version',state.versionReducer.version);
      if (config.version != state.versionReducer.version) {
           // do reset reducer
            state.notifyReducer = undefined;
            state.pageReducer = undefined;
            state.versionReducer = undefined;
           return appReducer(state, action)
      }
    }

    if (action.type === 'LOGOUT') {
      state.cartReducer = undefined;
      state.userReducer = undefined;
      return appReducer(state, action)
    }
  
    return appReducer(state, action)
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, 
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  export default store ;
