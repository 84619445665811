import config from 'config.js';

const initState = {
    version:config.version
}
const versionReducer= (state = initState,action)=>{
   
    //INSIDE HOME COMPONENT
    // if(action.type === 'CHECK_VERSION'){
    //     console.log('current',action.data);
    //     console.log('initState',initState.version);

    //     if (action.data != '' && action.data != initState.version) {
           

    //     }

    //     //state.token = action.data.token;

    //     state = {};
    //     state.version = action.data;
        
    //     return{
    //         ...state,
    //        // email: '2222'
    //     }
    // }
   
    return state
}

export default versionReducer