import {React,Component,connect,$,withTranslation,PhotoSwipe} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";
import 'react-photoswipe/lib/photoswipe.css';
let axios = require('axios').default;

class OrderReceiptImage extends Component {

    state = {
        'ImageSrc':'',
        'isOpen':false,
        'items': [],
    }

    constructor(props){
        super(props)
      
        helpers.initConstruct(this);
    
        this.getPaymentReceipt();
    }

    openPhotoSwipe = (e) => {
      console.log('open');
      e.preventDefault();
      this.setState({
        isOpen: true,
        options: {
          closeOnScroll: false
        }
      });
    };

    handleClose = () => {
      this.setState({
        isOpen: false
      });
    };

    getPaymentReceipt() {
        var self = this;
        axios.get(helpers.apiLink('account/orders/'+this.props.orderId+'/get-payment-receipt/'+this.props.receiptId),{ responseType: 'arraybuffer' }
        )
          .then(function (response) {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              );

              const img = new Image();
              img.onload = function() {
                self.setState({ 
                  items:[
                    {
                      src:"data:;base64," + base64,
                      w: img.width,
                      h: img.height,
                      title: ''
                    }
                  ]
                });
              }
              img.src = "data:;base64," + base64;

              self.setState({ ImageSrc: "data:;base64," + base64 });

            
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.receiptId !== this.props.receiptId) {
            this.getPaymentReceipt();
        }
    }

    render () {
        return (
            <div className="payment-receipt-item">
                <img onClick={this.openPhotoSwipe} width="150" src={this.state.ImageSrc}   />
                <PhotoSwipe 
                isOpen={this.state.isOpen} items={this.state.items}
                options={this.state.options}
                onClose={this.handleClose}
                />
            </div>
           
        )
    }

}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OrderReceiptImage));



