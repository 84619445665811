import React, { Component, useEffect} from 'react';
import { connect } from 'react-redux'
import queryString from 'query-string'
import $ from 'jquery';
import {withTranslation} from 'react-i18next'
import {useTranslation as useT} from 'react-i18next'
import {PhotoSwipe} from 'react-photoswipe';
import Splide from '@splidejs/splide';
import { Link ,useLocation,withRouter} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ImageUploader from 'react-images-upload';
import Popup from 'reactjs-popup';


// this is basic imports
const CustomT = (value, t, ready) => {
    return ready? t(value) : '';
}

export function useTranslation() {
   
    const {t,i18n,ready} = useT();
   
    return {
        i18n,
        t:(value) => CustomT(value,t,ready),
    }
};

export {
    React,Component, 
    useEffect,
    connect,
    queryString,
    $,
    withTranslation,
    PhotoSwipe,
    Splide,
    Link,
    useLocation,
    withRouter,
    Helmet,
    ImageUploader,
    Popup
}