

export const saveViewProduct=(data)=>{
    return{
        type: 'SAVE_VIEW_PRODUCT',
        data:data
    }
}

export const saveCategory=(data)=>{
    return{
        type: 'SAVE_CATEGORY',
        data:data
    }
}