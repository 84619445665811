import {SAVE_SHIPMENT_ADDRESS,SAVE_SHIPMENT_METHOD,SAVE_SHIPPING_COUNTRY,SAVE_SHIPMENT_STORE,SAVE_PAYMENT_METHOD,SAVE_ORDER_REMARK,SAVE_ORDER_REMARK_CONTACT,SAVE_ORDER_REMARK_GIFT_CARD,SAVE_ORDER_REMARK_SHIPMENT,SAVE_ORDER_REMARK_SHIPMENT_TIME,CLEAN_CART,SAVE_CART_ITEM_COUNT,SAVE_COUPON_CODE} from './action-types/cart-actions'


export const saveShipmentStore=(id)=>{
    return{
        type: SAVE_SHIPMENT_STORE,
        id
    }
}

export const saveShipmentAddress=(id)=>{
    return{
        type: SAVE_SHIPMENT_ADDRESS,
        id
    }
}

export const saveShipmentMethod=(id)=>{
    return{
        type: SAVE_SHIPMENT_METHOD,
        id
    }
}

export const saveShippingCountry=(id)=>{
    return{
        type: SAVE_SHIPPING_COUNTRY,
        id
    }
}

export const savePaymentMethod=(id)=>{
    return{
        type: SAVE_PAYMENT_METHOD,
        id
    }
}

export const saveOrderRemark=(id)=>{
    return{
        type: SAVE_ORDER_REMARK,
        id
    }
}

export const saveOrderRemarkContact=(id)=>{
    return{
        type: SAVE_ORDER_REMARK_CONTACT,
        id
    }
}

export const saveOrderRemarkGiftCard=(id)=>{
    return{
        type: SAVE_ORDER_REMARK_GIFT_CARD,
        id
    }
}

export const saveOrderRemarkShipment=(id)=>{
    return{
        type: SAVE_ORDER_REMARK_SHIPMENT,
        id
    }
}

export const saveOrderRemarkShipmentTime=(id)=>{
    return{
        type: SAVE_ORDER_REMARK_SHIPMENT_TIME,
        id
    }
}

export const cleanCart=()=>{
    return{
        type: CLEAN_CART,
    }
}

export const saveCartItemCount=(id)=>{
    return{
        type: SAVE_CART_ITEM_COUNT,
        id
    }
}

export const saveCouponCode=(id)=>{
    return{
        type: SAVE_COUPON_CODE,
        id
    }
}

