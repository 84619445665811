
import {React,Component,connect,$,withTranslation,Splide} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ProductItem,tLang,AdProductsGrid}  from "Imports/web";

let axios = require('axios').default;

class ProductsRelated extends Component {

  state = {
    'data':[]
  }

  constructor(props){
    super(props)
    helpers.initConstruct(this);
    window.onresize = this.initSplide;
  }

  initSplide() {
    var $windowWidth = $( window ).width();

    var perPage = 4;
    if ($windowWidth < 414) {
      perPage = 2;
    } else if ($windowWidth < 767) {
      perPage = 3;
    }
    if ($('#related-products-splide').length) {
      new Splide( '#related-products-splide', {
        // type   : 'loop',
        perPage: perPage,
        perMove: 1,
        pagination  : false,
        gap: 4
      } ).mount(); 
    }
  }

  // action increment
  componentDidMount() {

  };

  componentDidUpdate(prevProps) {

    if(prevProps.pId !== this.props.pId) {
      var self = this;
      axios.get( config.apiEndPoint + 'product/'+this.props.pId+'/related'
          ).then(function (response) {              
            self.setState({'data':response.data});
            self.initSplide();
          })
          .catch(function (error) {
              console.log(error);            
          });
    }

  }

  render () {
  
    let relatedItemList = this.state.data.map((item,i)=>{
      return(
          <li className="splide__slide  related-products-block" key={i}> 
              <ProductItem item={item}/>
          </li>
      )
    });

    return (
      
      this.state.data.length ?
      
      <div>
      <div className="divider"></div>

        <div className="product-detail-you-may-like">{tLang('you_may_also_like')}</div>
        <div className="related-content">
              <AdProductsGrid  perPage="4" title={''} type='' data={this.state.data}/>                   
        </div>
      </div>
      :
      ""
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ProductsRelated));
