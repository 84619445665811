import {React,Component,connect,$,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";

let axios = require('axios').default;

class ShipmentItems extends Component {

    state = {
        'shipmentItems':this.props.shipmentItems,
        'shipmentId':''
    }

    constructor(props){
        super(props)
      
        helpers.initConstruct(this);
        this.setDefault();
    }

    setDefault() {
         var shipmentId = '';
         var self = this;
         this.props.shipmentItems.forEach(function(item){
            if (item.selected == true) {
                shipmentId = item.id;
                self.props.saveShipmentMethod(item.id); 
                self.props.handleUpdateParentState({'shipmentId':item.id});   
            }
        });

        if (shipmentId == '' && !helpers.isEmpty(this.props.shipmentItems)) {
            shipmentId = this.props.shipmentItems[0]['id'];
            self.props.saveShipmentMethod(shipmentId); 
            self.props.handleUpdateParentState({'shipmentId':shipmentId});   
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.shipmentItems !== this.props.shipmentItems) {
            // console.log('gogo',this.props.shipmentItems);
            this.setState({'shipmentItems': this.props.shipmentItems});
        }
    }

    changeShipmentState = (event)=>{        
        let changeValue = event.target.value;
        let index = $(event.target).data('shipment-index');

        var shipmentItems = this.state.shipmentItems;

        shipmentItems.forEach(function(item){
            item.selected = false;
        });
        
        shipmentItems[index]['selected'] = true;
        this.state.shipmentId = shipmentItems[index]['id'];

        this.setState({
            'shipmentItems':shipmentItems});

        // save to redux
        this.props.saveShipmentMethod(shipmentItems[index]['id']);
    
        this.props.handleUpdateParentState({'shipmentId':shipmentItems[index]['id']});    
       
    }

    render () {
        var addedShipmentItems = this.state.shipmentItems.length ?
        (
            this.state.shipmentItems.map((item,i) =>{
                return(
                    <li key={i} class="list-group-item">

                    <div class="form-check">
                    <input id={`shipment_option_`+i} name="shipment" type="radio" className="shipment with-gap" value={item.id}  data-shipment-index={i}  checked={item.selected? true : false}  onChange={this.changeShipmentState}/>
                    
                    {item.code == 'SF-EXPRESS-HK' && item.shipping_fee_cod == 1 ?
                        <label for={`shipment_option_`+i} className="form-check-label options">{item.name} {item.free_shipping ? '('+ tLang('free_shipping')+ ')' : '(' + tLang('sf_shipping_cod') + ')'} <span className="remark">{item.remark}</span></label> 
                    :
                        <label for={`shipment_option_`+i} className="form-check-label options">{item.name} {item.fee_format} <span className="remark">{item.remark}</span></label> 
                    }

                   
                    </div>


                   
                    </li>
                )
            })
        ):
        (
            <p>There is no shipment options available.</p>
        )

        return (
            <div>
                <ul class="list-group list-group-flush">    
                {addedShipmentItems}
                </ul>
            </div>
            )
    }

}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ShipmentItems));



