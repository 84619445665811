import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ShipmentItems,CheckoutTotals,AccountSideBar,AddressCard,OrderReceiptImage,Loading,ProductName,MetaTag,tLang,DisplayPrice}  from "Imports/web";
import ImageUploader from 'react-images-upload';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

let axios = require('axios').default;

class OrderDetail extends Component{

    state = {
        'order':[],
        'orderItems':[],
        'orderReceipts':[],
        'orderPaymentHistory':[],
        'photos':[],
        'paymentInfo':[],
        'loading':true,
    }

    constructor(props){
        super(props)
       
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this);
        this.onDrop = this.onDrop.bind(this);
        this.submitPayment = this.submitPayment.bind(this);   
        this.submitPaymentConfirm = this.submitPaymentConfirm.bind(this);   
        this.goToCheckoutStripe = this.goToCheckoutStripe.bind(this);   
        this.goToCheckoutApplePay = this.goToCheckoutApplePay.bind(this);   
        
        this.oId = this.props.match.params.oId;
     
        this.getOrderDetail();
    }

    getOrderDetail(){
        var self = this;
        axios.get(helpers.apiLink('account/orders/'+this.oId)
        ).then(function (response) {  
            self.setState(
                {   
                    'order':response.data.order,
                    'orderItems':response.data.orderItems,
                    'orderReceipts':response.data.orderReceipts,
                    'orderPaymentHistory':response.data.orderPaymentHistory,
                    'photos':[],
                    'paymentInfo':response.data.paymentInfo,
                    'loading':false,
                });
        })
        .catch(function (error) {
            console.log(error);
            //window.location = "/account/orders"
            
        });
    }

    onDrop(photoFiles, photoDataURLs) {
      
        this.setState({
            photos: photoFiles
        });
    }

    

    submitPaymentConfirm(){
        // check if selected?
        if ((this.state.photos).length == 0) {
            helpers.showErrorMsg(tLang('please_choose_images'));
           
        } else {
            confirmAlert({
                title: tLang('confirm_to_upload_image?'),
                message: tLang('cannot_undo_desc'),
                buttons: [
                  {
                    label: 　tLang('yes'),
                    onClick: () => this.submitPayment()
                  },
                  {
                    label: tLang('no'),
                    onClick: () => ''
                  }
                ]
            });
        }
    }

    submitPayment(){
        var self = this;
        const formData = new FormData();
        Array.from(this.state.photos).forEach(image => {
          formData.append('files[]', image);
        });

        axios.post(helpers.apiLink('account/orders/'+this.oId+'/upload-payment-receipt'),formData ,{
            headers: { 'content-type': 'multipart/form-data' }
          }   
        )
          .then(function (response) {
            if (response.data == true) {
                self.getOrderDetail();
                $(".deleteImage").trigger('click');
                helpers.showMsg(tLang('upload_success')+ ' ' + tLang('awaiting_payment_confirm'));
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            //window.location = "/login"
          });
    }

    handleUpdateParentState(value){

        var self = this;
        Object.keys(value).map((key) => {
            self.state[key] = value[key];
        });
        this.setState(value);

        //return false;
        this.getUpdate();
    }


    componentDidUpdate(prevProps) {
       
    }


    goToCheckoutStripe(){
        var self = this;
        helpers.redirect('/checkout/payment-stripe/'+self.state.order.id);
    }

    goToCheckoutApplePay(){
        var self = this;
        helpers.redirect('/checkout/payment-apple-pay/'+self.state.order.id);
    }

    render(){

        let orderReceipts = this.state.orderReceipts.map((item,i)=>{
            return(
                <OrderReceiptImage key={i} orderId={item.order_id} receiptId={item.receipt_id} />
            )
        });

        let orderItems = this.state.orderItems.length ?
        (
            this.state.orderItems.map((item,i) =>{
                return(
                    <tr key={i}>
                    <td className="product-thumbnail"><img alt='' src={config.imagePathPrefix + item.product_photo}/></td>
                    <td className="cart-title ellipsed">
                        <span className="order-product-title"><a href={item.permalink}> <ProductName productName={item.product_name} />  </a></span><br/>
                        <span className="order-product-title responsive-content-show-sm"><b><DisplayPrice  originalPrice={item.product_original_price_format} price={item.product_price_format} /></b></span>
                        <span className="responsive-content-show-sm"><b>{tLang('quantity')}</b>: {item.product_qty}</span>
                    </td>
                    <td className="order-product-title responsive-hide-col-sm"><b><DisplayPrice originalPrice={item.product_original_price_format} price={item.product_price_format} /></b></td>
                    <td  className="responsive-hide-col-sm">
                        <b >{item.product_qty}</b>
                    </td>
                    <td><b>{item.sub_total_format}</b> </td>
                 
                    </tr>     
                )
            })
        ):

         (
            <tr>
            <td>Nothing.</td>
            </tr>
         );
       return(
            <div className="container min-height">
                <MetaTag  title={helpers.buildHTitle(tLang('order Detail'))} />
                <Loading loading={this.state.loading} />  

                <div className={`row  order-detial  ${this.state.loading ? "hide" : " show"} pt-3 `}>
                    <div className="account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3 " >
                    <AccountSideBar />
                    </div>      

                 
                    <div className={`col-12 col-md-9 `}>
                    <h3 className="center" >{tLang('order_detial')}</h3>
                
                    <div className="row order-detail-content-block justify-content-md-center">

                        <table className="table ">
                        <tbody>
                        <tr>  
                            <td width="150px">{tLang('order_number')} </td>
                            <td>{this.state.order.order_number}</td>
                        </tr>
                        <tr>
                            <td>{tLang('payment_status')} </td>
                            <td>{this.state.order.payment_name} {this.state.order.order_amount_format} ({this.state.order.payment_status_name})</td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_status')} </td>
                            <td>{this.state.order.order_status_name}</td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_date')} </td>
                            <td>{this.state.order.created_at}</td>
                        </tr>
                       

                        <tr>     
                            <td>{tLang('shipments')} </td>
                            <td>{this.state.order.shipment_name} {this.state.order.shipment_fee_remark} </td>
                        </tr>

                        <tr>     
                            <td>{tLang('address')} </td>
                            <td className="order-detail-address"> <AddressCard item={this.state.order}/></td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_remark_contact')} </td>
                            <td>{this.state.order.order_remark_contact}</td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_remark_gift_card_title')} </td>
                            <td>{this.state.order.order_remark_gift_card}</td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_remark_shipment_time_title')} </td>
                            <td>{this.state.order.order_remark_shipment_time}</td>
                        </tr>
                        <tr>     
                            <td>{tLang('order_remark_shipment_title')} </td>
                            <td>{this.state.order.order_remark_shipment}</td>
                        </tr>

                        </tbody>
                        </table>

                        <br/>
                        <br/>
                        <br/>
                        
                        <table className="cart table">
                        <thead>
                        <tr>
                            <th></th>
                            <th >{tLang('product')}</th>
                            <th className="responsive-hide-col-sm">{tLang('price')}</th>
                            <th className="responsive-hide-col-sm">{tLang('quantity')}</th>
                            <th >{tLang('total')}</th>
                           
                        </tr>
                        </thead>

                        <tbody>
                            {orderItems}    
                        </tbody>
                        </table>

                        <CheckoutTotals items={this.state.order}  />
                        
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>{tLang('payments')} - {this.state.order.payment_name} </td>  
                            </tr>

                            <tr>  

                                <td>
                                    <div className="text-content" >
                                        {
                                            this.state.paymentInfo.code == 'STRIPE' && this.state.order.order_status == 1 ?
                                            <button type="button" className="red button large" name="checkout" onClick={this.goToCheckoutStripe} >{tLang('pay_now')}</button>
                                            : ''
                                        }

{
                                            this.state.paymentInfo.code == 'APPLE_PAY' && this.state.order.order_status == 1 ?
                                            <button type="button" className="red button large" name="checkout" onClick={this.goToCheckoutApplePay} >{tLang('pay_now')}</button>
                                            : ''
                                        }


                                        {this.state.paymentInfo.desc}
                                        {
                                            !helpers.isEmpty(this.state.paymentInfo.pay_qrcode) ? 
                                            <div className="center" ><br/><b>{tLang('order_payment_qrcode')}</b><br/> <img width="250" src={config.imagePathPrefix+this.state.paymentInfo.pay_qrcode}/></div>
                                            :''
                                        }

                                        {
                                            !helpers.isEmpty(this.state.paymentInfo.pay_link) != '' && helpers.isMobile() ? 
                                            <div className="center"><br/><b>{tLang('order_payment_link')}：</b><br/> <br/> <a href={this.state.paymentInfo.pay_link}> <button className="large red button">按我付款連絡</button><br/><br/></a></div>
                                            :''
                                        } 
                                    </div>
                                 </td>
                            </tr>
                            </tbody>
                        </table>

                        {
    this.state.paymentInfo.code == 'STRIPE' || this.state.paymentInfo.code == 'APPLE_PAY'  ?
                            '' :<table className="table">
                            <tbody>
                            <tr><td>{tLang('payment_records')} </td>
                            </tr>
                            <tr>  
                                <td>{orderReceipts} </td> 
                            </tr>
                            <tr>
                                <td> 
                                <ImageUploader
                                    withPreview={true}
                                    withIcon={true}
                                    buttonText={tLang('please_choose_images')}
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif','jpeg']}
                                    maxFileSize={8242880}
                                    label={tLang('order_receipt_upload_msg')}
                                />
                                     <div className=" center">
                                     <button type="submit" className=" button large" name="register" value="submit" onClick={this.submitPaymentConfirm}  >{tLang('submit')}</button>
                                     <br/>
                                     <br/>
                                     <br/>
                                     <a className="" href={helpers.buildLink("/account/orders")} >{tLang('back_my_orders')}</a>
                                  
                                     </div> 
                                </td>       
                            </tr>
                            </tbody>
                            </table>
}

                    </div>
                   
               

                    </div>
                </div>
            </div>
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OrderDetail))