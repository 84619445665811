
import {React,Component,connect,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,ProductItem,Pagination,Breadcrumbs,helpers,tLang,MetaTag}  from "Imports/web";

const axios = require('axios').default;

 class ProductList extends Component{

    handleClick = (id)=>{
        this.props.addToCart(id); 
    }

    state = {
        items: [],
        title:'',
        metaDesc:'',
        loading:true
    };

    constructor(props){
        super(props)

        var self = this;
        helpers.initConstruct(self);
        self.cId = this.props.match.params.cId;
      
        axios.get(helpers.apiLink('category/products/'+self.cId+'?&page='+self.pageNum)
        ).then(function (response) {
            self.activePage = response.data.current_page;
            self.lastPage = response.data.last_page;
            self.setState({
                'loading':false,
                'items':response.data.data,
                'title':response.data.title,
                'metaDesc':response.data.meta_desc,
            });
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });
    }

    componentDidUpdate(){
       
        helpers.checkPaginationUpate(this);
    }

    render(){
        let itemList = this.state.items.map((item,i)=>{
            return(
                <div className="col-6 col-md-3 " key={i}>
                <ProductItem item={item}/>
                </div>   
            )
        })
        return(
            
            <div className="container product-list min-height">
                <MetaTag desc={ helpers.isEmpty(this.state.metaDesc)? this.state.title : this.state.metaDesc }  title={helpers.buildHTitle(this.state.title)} />  
                <Breadcrumbs type="category" id={this.cId}/>

                { this.state.loading  == false?

                    this.state.items.length ?
                    <div> 
                    <div className="row">
                        {itemList}
                    </div>
                    <Pagination totalPages={this.lastPage} originLink={this.originLink} activePage={this.activePage} />
                    </div>

                    :
                    <div className="center">
                    <p>{tLang('product_is_empty')}</p>
                    <br/>

                    </div>

                :
                    ''    
                }   


            </div>
        )
    }
}
// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//         session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//         addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ProductList))