import React, { Component } from 'react';
import { connect } from 'react-redux'
import {mapStateToProps,mapDispatchToProps,helpers,config,Init,tLang}  from "Imports/web";
import { toast } from 'react-toastify';

let axios = require('axios').default;

class Footer extends Component{

    constructor(props){
        super(props);
        
        axios = helpers.reviseApiHeader(this,axios);
        this.addChaty();
    }

    state = {
        addChaty:false 
    }

    addChaty(){
        var  path = window.location.pathname;
        if (path.indexOf('/checkout/') > -1) {
            this.state.addChaty = false;
        } else {
            this.state.addChaty = true;
        }
    }

    render(){
        return(
            <footer className=" light text-dark">
                <div className="divider"></div>
                <div className="container site-footer">
               
                <div className="row ">
                <div className="col-12 col-md text-center item">
                    <a href={helpers.buildLink("/privacy-policy")}>私隱政策</a>
                    <a href={helpers.buildLink("/tnc")} >條款及細則</a>
                    <a href={helpers.buildLink("/shipping-policy")}>貨品政策</a>  
                    <a href={helpers.buildLink("/contact")} >聯絡我們</a>
                </div>
                </div>

                <div className="row ">
                <div className="col-12 col-md text-center item">
                    <a target="_blank" href={"https://www.facebook.com/plantaholic.hk"}><em class="fab fa-facebook">&nbsp;</em></a>
                    <a target="_blank" href={"https://www.instagram.com/plantaholic.hk"} ><em class="fab fa-instagram">&nbsp;</em></a>
                </div>
                </div>

                <div className="row item">
                <div className="col-12 col-md text-center">
                ©{new Date().getFullYear()} {config.shopName}. All rights reserved
                </div>
                </div>

                <div className="row item">
                <div className="col-12 col-md text-center">
                <img className="footer-payment-logo" src="/image/footer_icons.jpg" />

                </div>
                </div>



             
                </div>
                           
            </footer> 

        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//       notify: state.notifyReducer,
//       user: state.userReducer,
//       session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//         cleanMessage: (data)=>{dispatch(cleanMessage({}))},
//         getSession: (data)=>{dispatch(getSession(data))}
//     }
// }


export default connect(mapStateToProps,mapDispatchToProps)(Footer)
//export default Footer;