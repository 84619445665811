import {React,useTranslation} from "Imports";
import {config,helpers,tLang}  from "Imports/web";

const LdDataProduct = (props) => {
  
    var prod = props.data;
    var availability = '';
    if (prod.all_is_out_of_stock) {
        availability = 'https://schema.org/OutOfStock';
    } else {
        availability = 'https://schema.org/InStock';
    }

    var lowPrice = 0;
    var highPrice = 0;
    var offerCount = 2;

    var aggregateOffer = false;
    if (!helpers.isEmpty(prod.price_range)) {
        if (prod.price_range.indexOf('-') >=0 ) {
            aggregateOffer = true;
            var priceRangeAr  = prod.price_range.split('-');
            lowPrice = priceRangeAr[0];
            if ( prod['price']['price'] < lowPrice ) {
                lowPrice = prod['price']['price'];
            }
            highPrice = priceRangeAr[1];
        }

        offerCount = prod.product_variations.length;
    }



    let data = {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": `${prod.title}`,
        "image": prod.photos.map((item) => config.imagePathPrefix  + item),
        "description": helpers.isEmpty(prod['meta_desc'])?  `${prod.title} ${helpers.getMetaDesc()}` : `${prod['meta_desc']}`,
        "url": (prod['permalink'] ? config.shopDomain + helpers.buildLink(prod['permalink']) : ''),
        "sku": `${prod.id}`,
       
        "offers": {
            "@type": "Offer",
            "priceCurrency": `${prod['currency'] || "HKD"}`,
            "price": prod['price']['price'] ? `${parseFloat(prod['price']['price'])}` : 0,
            "availability": `${availability}`,
            "itemCondition":"https://schema.org/NewCondition",
            "priceValidUntil": `${prod['price']['price_valid_until']}`
        }
    };

    if (!helpers.isEmpty(prod.brand_name)) {
        let brandType = "Brand";
        if (!helpers.isEmpty(prod.brand_type)) {
            brandType = prod.brand_type;
        } 
        data.brand ={
            "@type": brandType,
            "name": `${prod.brand_name}`
        }
    }

    if (aggregateOffer) {
        data.offers = {
            "@type": "AggregateOffer",
            "priceCurrency": `${prod['currency'] || "HKD"}`,
            "availability": `${availability}`,
            "itemCondition":"https://schema.org/NewCondition",
            "lowPrice":`${lowPrice}`,
            "highPrice":`${highPrice}`,
            "offerCount": offerCount,
        }
    }

    // return false;
    return <script type="application/ld+json">{ JSON.stringify(data)}</script>;
   
}

export default LdDataProduct;

