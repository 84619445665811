const initState = {
    key : '',
    // id:'',
    // name:'',
    // phone:'',
    // address_1:'',
    // address_2:'',
    // address_3:'',
    // email:''
}
const sessionReducer= (state = initState,action)=>{
   
    //INSIDE HOME COMPONENT
    if(action.type === 'GETSESSION'){

        //state.token = action.data.token;
        state = {};
        state.key = action.data;

        return{
            ...state,
           // email: '2222'
        }
    }

    

    return state
}

export default sessionReducer