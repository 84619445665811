import {React,Component,connect,withTranslation,$,PhotoSwipe,Splide,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,Breadcrumbs,helpers,ProductDetailOptions,config,ProductsRelated,MetaTag,tLang,LdDataProduct}  from "Imports/web";
import 'react-photoswipe/lib/photoswipe.css';
import { Fragment } from 'react';

let axios = require('axios').default;

 class ProductDetail extends Component{

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        //axios = helpers.reviseApiHeader(this,axios);

        var self = this;
        self.pId = this.props.match.params.pId;
        this.photoSwipePhtots = [];
        self.selectOptionsData = [];
       
        axios.get( config.apiEndPoint + 'product/'+self.pId
        ).then(function (response) {
            // load image 
            response.data.photos.map((item,i)=>{
                const img = new Image();
                img.onload = function() {
                    self.photoSwipePhtots.push(
                        {
                            'w':this.width,
                            'h':this.height,
                            'src':this.src,
                        }
                    );
                }
                img.src = config.imagePathPrefix + item;
            });

            self.setState({
                'data':response.data
            });
            
            
            self.splidePhotoMain = new Splide( '#image-slider', {
                type   : 'loop',
                pagination : true, 
                arrows : false
            } );

            self.splidePhotoMainThumb = new Splide( '#image-slider-thumb', {
                fixedWidth: 50,
                gap       : 10,
                rewind    : true,
                pagination: false,
               
             
            } );
            

            self.splidePhotoMain.sync( self.splidePhotoMainThumb );
            self.splidePhotoMain.mount();
            self.splidePhotoMainThumb.mount();


            self.setState({
                'splidePhotoMain':self.splidePhotoMain,
                'splidePhotoMainThumb':self.splidePhotoMainThumb
            });
           


            var thumbnails = document.getElementsByClassName( 'thumbnail' );

            console.log('thumbnails',thumbnails);
    
            for ( var i = 0; i < thumbnails.length; i++ ) {
            initThumbnail( thumbnails[ i ], i );
            }
    
            // The function to initialize each thumbnail.
            function initThumbnail( thumbnail, index ) {
            thumbnail.addEventListener( 'click', function () {
                // move the carousel
                console.log('click');
                self.splidePhotoMainThumb.go( index );
            } );
            }

            // save view product
            self.props.saveViewProduct(self.pId);
           
           if (response.data.all_is_out_of_stock) {
               //$('.qty-remark').html('Out of Stock');
               //$('.quantity-block .button').css({'background-color':'#4848502b','cursor': 'not-allowed'})
           }
           
           //$('.quantity-block').hide();

        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });
        //this.setState([{'a':'1'}]);
        //this.updateLanguage = this.updateLanguage.bind(this);
    }

    state = {
        isOpen: false,
        items: [],
        options: {},
        data:{'photos':[],
            'categories':[],
            'price':[],
            'all_out_of_stock': false,
            'title':''
        },
        splidePhotoMain:'',
    };
    
    handleClick = (id)=>{
      
        this.props.addToCart(id); 

    }

    handleClose = () => {
        
        this.setState({
          isOpen: false
        });
      };

    openPhotoSwipe = (e) => {
        e.preventDefault();
     
        this.setState({
          isOpen: true,
          options: {
            closeOnScroll: false
          },
          items:this.photoSwipePhtots
        });
    };

    changeOptions = () => {
    }

    replaceWithBr(text) {

        if (!helpers.isEmpty(text)) {
            return text.replace(/\n/g, "<br />")
        } else {
            return text;
        }
       
    }

    componentDidMount(){
        //new Splide( '.splide' ).mount();

  

    }

    render(){
                   
        let imagesList = this.state.data.photos.map((item,i)=>{
            return(      
                <li className="splide__slide" key={i}>
                <img onClick={this.openPhotoSwipe} src={config.imagePathPrefix  + item} alt={this.state.data.title + ' img-' + (i+1)}/>
                </li>
            )
        })

        let imagesListThumb = this.state.data.photos.map((item,i)=>{
            return(      
                <li className="splide__slide thumbnail" key={i}>
                <img src={config.imagePathPrefix  + item} alt={this.state.data.title + ' img-' + (i+1)}/>
                </li>
            )
        })

        let categoryList = this.state.data.categories.map(item=> {
            return (
                item.name
            )

        })

        return(
            <div className="container product-detial min-height">
                <MetaTag desc={ helpers.isEmpty(this.state.data.meta_desc)? this.state.data.title : this.state.data.meta_desc }  title={helpers.buildHTitle(this.state.data.title)} />  
                <Breadcrumbs type="product" id={this.pId}/>

                <div className="">

                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div id="image-slider" className="splide">
                            <div className="splide__track">
                                <ul className="splide__list">
                                   {imagesList}
                                </ul>
                            </div>
                        </div>
                        <div id="image-slider-thumb" className="splide image-slider-thumb">
                            <div className="splide__track">
                                <ul className="splide__list">
                                   {imagesListThumb}
                                </ul>
                            </div>
                        </div>

                        <PhotoSwipe 
                            isOpen={this.state.isOpen} items={this.state.items}
                            options={this.state.options}
                            onClose={this.handleClose}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6  product-detail-right-block">
                    <h1 className="product-detail-title entry-title">{this.state.data.title}</h1>
                        <p className="price-block">
                            <span className="final-price">{this.state.data.price.price_format}</span>
                            { this.state.data.price.discount_price_saved > 0 ? <span className="price discount">{this.state.data.price.original_price_format}</span> : ''
                            }
                        </p>

                        <ProductDetailOptions pId={this.state.data.id} productType={this.state.data.product_type} attributePhotoMap={this.state.data.attributePhotoMap} splidePhotoMain ={this.state.splidePhotoMain}  />
                        <div className="product-meta-block">
                            <div className="product-detail-sub-title">
                            <span >{tLang('description')}</span>
                            <span className="product-detail-sub-title-accordion"></span>
                            </div>
                            <div className="desc-content">
                            <div dangerouslySetInnerHTML={{
                                __html: this.replaceWithBr(this.state.data.desc)
                            }}></div>
                            </div>
                        </div>

                        <div className="product-meta-block">
                            <div className="product-detail-sub-title">
                            <span >{tLang('productDetail')}</span>
                            <span className="product-detail-sub-title-accordion"></span>
                            </div>
                            
                            <div className="desc-content">
                            <div dangerouslySetInnerHTML={{
                                __html: this.replaceWithBr(this.state.data.spec)
                            }}></div>
                            </div>
                        </div>

                        <div className="product-meta-block">
                            <div className="product-detail-sub-title">
                            <span >{tLang('productShipment')}</span>
                            <span className="product-detail-sub-title-accordion"></span>
                            </div>
                            <div className="desc-content">
                            <div dangerouslySetInnerHTML={{
                                 __html: this.replaceWithBr(this.state.data.product_shipment_detail)
                            }}></div>
                            </div>
                        </div>
                       
                    </div>   
                </div>
                <div className="row pad40">
                    <div className="col s12">
                    <ProductsRelated pId={this.state.data.id}  />
                    </div>
                </div>
               
                </div>
                <LdDataProduct data={this.state.data}/>
            </div>
        )
    }
}
// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//         session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//       //  addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ProductDetail))