const wording =  {
  "lang": "語言",
  "shopping": "購物",
  "hot": "熱門產品",
  "on_sale": "折扣產品",
  "about": "關於我們",
  "contact": "聯絡我們",
  "birthday_month": "生日月份",
  "my_account": "我的帳號",
  "please_choose": "請選擇",
  "address": "地址",
  "email": "電郵",
  "telephone": "手提電話",
  "name": "姓名",
  "change_password": "更改密碼",
  "save": "儲存",
  "my_profile": "個人資料",
  "logout": "登出",
  "login": "登入",
  "confirm_password": "再次輸入密碼",
  "password": "密碼",
  "new_password": "新密碼",
  "wishlist": "最愛清單",
  "shopping_cart": "購物車",
  "product": "產品",
  "price": "價錢",
  "total": "總數",
  "quantity": "數量",
  "account": "帳號",
  "remember_me": "記住我",
  "create_account": "註冊帳號",
  "submit":"提交",
  "home":"主頁",
  "add_to_cart":"加入購物車",
  "description":"產品資料",
  "productDetail":"產品詳情",
  "productShipment":"送貨安排",
  "category":"分類",
  "tags":"標籤#",
  "cart_updated":"購物車已經更新",
  "please_login": "請登入帳號 或 註冊會員",
  "search": "搜尋",
  "updated":"更新成功",
  "email_format_error":"電郵格式錯誤",
  "login_error":"電話或密碼錯誤",
  "register_success":"註冊成功",
  "register_fail":"註冊失敗",
  "added_to_cart":"已加到購物車",
  "shipments":"送貨方式",
  "checkout":"結帳",
  "product_total":"產品總數",
  "shipment_fee":"運費",
  "continue_shopping":"繼續購物",
  "go_to_checkout":"去結帳",
  "shipping_address":"送貨地址",
  "address_1":"地址欄 1",
  "address_2":"地址欄 2(選填)",
  "recipient_name":"收件人",
  "address_create":"新增送貨地址",
  "address_edit":"修改送貨地址",
  "address_book":"地址簿",
  "add_new_address":"新增地址",
  "fill_in_shipping_address":"按我填寫送貨地址",
  "change_address":"轉另一地址",
  "edit":"修改",
  "use_this_address":"使用這個地址",
  "order_remark":"訂單備註",
  "delete":"刪除",
  "choose_address": "按我選擇地址",
  "shipment_services": "送貨速遞服務",
  "country": "國家/城市",
  "regions": "地域",
  "areas": "地區",
  "option": "(選填)",
  "self_pickup_address":"自取點地址",
  "change_self_pickup_address":"選擇自取點地址",
  "new_shipping_address":"新增一個送貨地址",
  "payments":"付款方式",
  "order_summary":"訂單總結",
  "confirm_order":"確定下單",
  "en_lang":"English",
  "zh-HK_lang":"中文",
  "default_lang":"預設語言",
  "order_amount": "總數",
  "my_orders":"我的訂單",
  "back_my_orders":"返回我的訂單",
  "order_detial":"訂單內容",
  "payment_status":"付款狀態",
  "order_status":"訂單狀態",
  "order_date":"訂單日期",
  "order_number":"訂單號碼",
  "upload_success":"上傳成功",
  "awaiting_payment_confirm":"等待核對記錄",
  "confirm_to_upload_image?":"確定上傳圖片？",
  "cannot_undo_desc":"確定後不能取消",
  "yes":"是",
  "no":"否",
  "please_choose_images":"請選擇圖片",
  "payment_records":"付款記錄",
  "order_receipt_upload_msg":"只接受上傳圖片",
  "shipping_country": "國家",
  "out_of_stock": "已售罄",
  "order_created":"訂單建立成功",
  "not_enough_stock":"庫存不足",
  "check_out_error_cart_items_need_adjuct":"產品數量需要調整",
  "check_out_error_order_number_error":"訂單號碼錯誤，請再試一次，或聯絡我們",
  "check_out_error_shipment_not_found":"沒有運送方式，請再試一次，或聯絡我們",
  "check_out_error_payment_not_found":"沒有付款方式，請再試一次，或聯絡我們",
  "check_out_error_store_not_found":"沒有門市資料，請再試一次，或聯絡我們",
  "check_out_error_address_not_found":"沒有地址，請再試一次，或聯絡我們",
  "check_out_error_cart_is_empty":"購物車沒有產品",
  "order_payment_qrcode":"掃QrCode進行支付",
  "order_payment_link":"或透過以下付款連結進行支付",
  "forgot_password": "忘記密碼",
  "Please_enter_your_registered_email":"請輸入註冊電郵",
  "confirm":"確認",
  "back_login":"返回登入",
  "verify_code":"驗證手機號碼",
  "Please_enter_your_verification_code":"請輸入驗證碼",
  "verification_code":"驗證碼",
  "email_remark":"以用於接收優惠資訊用途",
  "agree_newsletter":"本人願意收到優惠資訊，限時折扣優惠以及購物活動訊息等",
  "agree_register":"我已閱讀並同意<a target='_blank' href='/tnc'>《條款及細則》</a> 及 <a target='_blank' href='/privacy-policy'>《私隱政策》</a>",
  "terms_and_conditions" :"條款及細則",
  "privacy_notice" :"私隱政策",
  "cart_is_empty" :"購物車未有產品",
  "order_discount" :"折扣優惠",
  "credit_card_payment" : "信用卡付款",
  "status_progress" : "狀態進度",
  "status_progress_0" : "沒有",
  "status_progress_1" : "處理中(1-3工作日)",
  "status_progress_2" : "資料已經删除",
  "facebook_login_error" :"登入錯誤，請聯絡我們",
  "subscribe_newsletter" : "訂閱電子報",
  "subscribe_success":"訂閱成功",
  "subscribe":"立即訂閱",
  "subscribe_remark":"收到優惠資訊，限時折扣優惠以及購物活動訊息",
  "shipping_policy": "Shipping Policy",
  "apply_pay_payment" : "Apple Pay 付款",
  "return_other_payments" : "返回使用其他付款方式",
  "apple_pay_remark" : "你現在不能使用Apple Pay進行付款，請檢查以下設定：",
  "apple_pay_check_0" : "Apple Pay只能在 Safari 瀏覽器使用",
  "apple_pay_check_1" : "在iPhone/Mac上將付款卡儲存在「銀包」App，或看<a target=\"_blank\" href=\"https://support.apple.com/zh-hk/HT204506\">《參考資料》</a>",
  "apple_pay_check_2" : "使用iPhone 系統需要 iOS 10.1版本以上",
  "apple_pay_check_3" : "使用Macbook 系統需要 macOS 10.12.1 版本以上",
  "apple_pay_check_4" : "或聯絡我們尋求幫肋",
  "pay_now" : "即立付款",
  "coupon" : "優惠券",
  "input_coupon_code" : "輸入優惠碼Code", 
  "apply" : "使用", 
  "coupon_apply_success":"成功使用優惠劵",
  "delete_coupon":"删除優惠劵",
  "points":'積分',
  "points_total":'積分結餘',
  "point_history":'積分記錄',
  "no_points_history":'暫時沒有積分記錄',
  "redemption":'兌換專區',
  "need":'需要使用',
  "redeem_now":'立即兌換',
  "redeem":'兌換',
  "remark":'備註',
  "redeem_success":"兌換成功",
  "my_coupon" : "我的優惠券",
  "used" : "已使用",
  "use_date" : "使用日期",
  "use_condition" : "使用條件",
  "amount_condition" : "買滿金額",
  "qty_condition" : "買滿產品",
  "copy_code" : "複製優惠碼",
  "copied" : "已複製！",
  "use_date_start" : "使用日期",
  "use_date_end" : "結束日期",
  "please_input_coupon" : "請填入優惠券",
  "no_redeem":'暫時沒有兌換優惠產品',  
  "no_coupon_records":'暫時沒有優惠券',  
  "use_days":'日內使用',  
  "confirm_to_redeem_coupon?":"確定兌換？",
  "use_date_period" : "使用限期",
  "free_shipping" : "免運費",
  "sf_shipping_cod" : "到付",
  "memebership": "會員及積分",
  "you_may_also_like" : "你可能也喜歡",
  "order_empty":"沒有訂單記錄",
  "order_remark_contact":"WhatsApp/Phone (如有訂單問題方便通知)",
  "order_remark_gift_card":"請填寫心意卡內容（如有)",
  "order_remark_shipment_time":"請填寫送貨日期及時段（例如5 月8日下午2-4小時）",
  "order_remark_shipment":"請填寫自取日期（如到自取點自取）",
  "sf_address":"可填寫順豐編號或地址",
  "new":"新增",
  "edit":"修改",
  "change":"轉換",
  "order_remark_gift_card_title":"心意卡備註",
  "order_remark_shipment_time_title":"送貨時間備註",
  "order_remark_shipment_title":"自取日期備註",
  "product_is_empty" :"暫時未有產品提供",
  "product_not_found" :"找不到產品",
}

export default wording
