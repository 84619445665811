
import config from 'config.js';


const initState = {
    lang : config.default_lang,  // en
    forgotPasswordCountryCode:'',
    forgotPasswordPhone:'',
    redirectAfterLogin:''
    // id:'',
    // name:'',
    // phone:'',
    // address_1:'',
    // address_2:'',
    // address_3:'',
    // email:''
}
const userReducer= (state = initState,action)=>{
   
    //INSIDE HOME COMPONENT
    if(action.type === 'LOGIN'){

        //state.token = action.data.token;
        
        //state = {};
        // state.token = action.data.token;
        // state.name = action.data.name;
        // state.phone = action.data.phone;
        // state.countryCode = action.data.country_code;
        // state.id = action.data.id;
        // state.lang = action.data.default_lang;

        return{
            ...state,
            token: action.data.token,
            name:action.data.name,
            phone: action.data.phone,
            countryCode: action.data.country_code,
            id: action.data.id,
            lang: action.data.default_lang,
            forgotPasswordCountryCode:'',
            forgotPasswordPhone:'',
            // email: '2222'
        }

        // let addedItem = state.items.find(item=> item.id === action.id)
          //check if the action id exists in the addedItems
        //  let existed_item= state.addedItems.find(item=> action.id === item.id)
        //  if(existed_item)
        //  {
        //     addedItem.quantity += 1 
        //      return{
        //         ...state,
        //          total: state.total + addedItem.price 
        //           }
        // }
        //  else{
        //     addedItem.quantity = 1;
        //     //calculating the total
        //     let newTotal = state.total + addedItem.price 
            
        //     return{
        //         ...state,
        //         addedItems: [...state.addedItems, addedItem],
        //         total : newTotal
        //     }
            
        // }
    }
    if(action.type === 'LOGOUT'){

    //     state = {};
    //     return{
    //         ...state,
    //    }
    }

    if(action.type === 'SAVEUSER'){
        return{
            ...state,
            name: action.data.name,
            phone: action.data.phone,
            countryCode: action.data.country_code,
            lang:  action.data.default_lang
        }
    }

    if(action.type === 'SWITCHLANGUAGE'){
        return{
            ...state,
            lang: action.data
        }
    }

    if(action.type === 'SAVEFORGOTPASSWORDINFO'){
        return{
            ...state,
            forgotPasswordCountryCode: action.data.countryCode,
            forgotPasswordPhone: action.data.phone,
        }
    }

    if(action.type === 'SETREDIRECTAFTERLOGIN'){
        console.log('action.data',action.data);
        return{
            ...state,
            redirectAfterLogin: action.data,
        }
    }

    return state
}

export default userReducer