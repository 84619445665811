import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import configureStore from './configureStore';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
//import { createStore } from 'redux';

import { persistStore } from 'redux-persist'

import ConnectedIntlProvider from './ConnectedIntlProvider';
import config from 'config';

import TagManager from 'react-gtm-module'

// set language 
//import i18n from 'i18n'

// var lang = 'zh-HK';
// i18n
// .use(Backend)
// .use(initReactI18next)
// .init({
// lng: lang,
// backend: {
//     /* translation file path */
//     loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
// },
// fallbackLng: 'zh-HK',
// debug: true,
// /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
// ns: ['translations'],
// defaultNS: 'translations',
// keySeparator: false,
// interpolation: {
//     escapeValue: false,
//     formatSeparator: ','
// },
// react: {
//     wait: false,
//     useSuspense: false
// }

// });

//import storage from 'redux-persist/es/storage'
//import { PersistGate } from 'redux-persist/integration/react'

//let store = createStore(persistedReducer)
 //import '../node_modules/font-awesome/css/font-awesome.min.css'; 
// reducer 

// dispatch
const store = configureStore;
const persistor = persistStore(store);

//console.log('state eeeee',persistor.getState());

if (config.env == 'production') {
     // const tagManagerArgs = {
     //      gtmId: config.gtmID
     // }
        
     // TagManager.initialize(tagManagerArgs)     
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
     ReactDOM.hydrate( <Provider store={store}> 
          <PersistGate loading={null} persistor={persistor}> 
          <ConnectedIntlProvider> 
               <App /> 
          </ConnectedIntlProvider>
          </PersistGate></Provider>, rootElement);
} else {

     ReactDOM.render(
          <Provider store={store}> 
          <PersistGate loading={null} persistor={persistor}> 
          <ConnectedIntlProvider> 
               <App /> 
          </ConnectedIntlProvider>
          </PersistGate></Provider>, rootElement);

}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
