import {React,Component,connect,withTranslation,Helmet,$} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,AccountSideBar,Loading,AddressSelect, config,tLang}  from "Imports/web";

let axios = require('axios').default;

class AddressForm extends Component{

    constructor(props){
        super(props)
        
        helpers.initConstruct(this);

        var self = this;
        self.aId = this.props.aId;

        this.mode = 'edit';
        if (typeof self.aId == 'undefined' || self.aId == '') {
            this.mode = 'create';
        }

        this.state = {
            recipient_name : this.props.user.name ? this.props.user.name : '',
            recipient_phone : this.props.user.phone ? this.props.user.phone : '',
            address_1 :'',
            address_2 :'',
            country_id:'',
            state_id:'',
            city_id:'',
            loading : false,
            state_options:[],
            city_options:[],
            country_options:[]
        };
        this.changeState = this.changeState.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.submitFormProfile = this.submitFormProfile.bind(this);
        this.handleUpdateParentState = this.handleUpdateParentState.bind(this);

        if (this.mode == 'edit' && this.aId !='') {
            this.getAddressDetail();
        }else {
            self.getAddressOptions();
        }
      
        //this.getAddressOptions();

    }

    handleUpdateParentState(value){
        var self = this;
        Object.keys(value).map((key) => {
            self.state[key] = value[key];
        });
        this.setState(value);
        this.getAddressOptions();
        var element = document.querySelector('.modal.popupBlock.addressFrom');
        
        //return false;
        //this.getUpdate();
    }

    getAddressDetail(){
        var self = this;
        axios.get( helpers.apiLink('account/user/address/'+this.props.aId)
        )
          .then(function (response) {
            //self.setState(response.data);

            self.setState({
                'recipient_name' : response.data.recipient_name,
                'recipient_phone' : response.data.recipient_phone,
                'address_1' : response.data.address_1,
                'address_2' : response.data.address_2,
                'country_id' : response.data.country_id,
                'state_id' : response.data.state_id,
                'city_id' : response.data.city_id
            });

            self.getAddressOptions();

           
            self.setState({loading:false});
           
            self.iti.setNumber('+' + response.data.recipient_country_code + response.data.recipient_phone);
          
          })
          .catch(function (error) {
            console.log(error);
            helpers.axiosCatch(error,0);
          });
 
   
    }

    changeCountry(event){
        let changeName = event.target.name;
        
        this.setState({ [changeName]: event.target.value })

        if (changeName == 'country_id') {
            this.state['country_id'] = event.target.value;
            this.getAddressOptions();
        }
    }

    changeState(event){
        let changeName = event.target.name;
        
        this.setState({ [changeName]: event.target.value })

        if (changeName == 'state_id') {
            this.state['state_id'] = event.target.value;
            this.getAddressOptions();
        }
    }

    getAddressOptions() {
        var self = this;
        axios.get(helpers.apiLink('address/address-options'),{params:{
            'country_id':this.state.country_id,
            'state_id':this.state.state_id
            }}   
        )
        .then(function (response) {
       
            self.setState({
                'state_options' : response.data.stateOptions,
                'city_options' : response.data.cityOptions,
                'country_options' : response.data.countryOptions,
                'country_id':response.data.selectedCountryId,
                'state_id':response.data.selectedStateId
            })

            var elems = document.querySelectorAll('select');
           
            self.setState({loading:false});
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    submitFormProfile(event){
        event.preventDefault()
        var self = this;
        this.state.recipient_country_code = '+'+this.iti.getSelectedCountryData().dialCode;

        if (this.mode == 'create') {
            axios.post(helpers.apiLink('account/user/address'),this.state    
            )
            .then(function (response) {
                if (response.data.success === true) {
                    self.props.saveSuccessMessage(tLang('updated'));
                     
                    if (typeof self.props.saveShipmentAddress != 'undefined') {
                        self.props.saveShipmentAddress(response.data.id);  
                    }

                    var link = '';
                    if (typeof self.props.redirectLink != 'undefined' && self.props.redirectLink != '') {
                        link =  helpers.buildLink(self.props.redirectLink);
                    } else {
                        link = helpers.buildLink("/account/address-book");
                    }
                    
                   
                    window.location = link;
                    return true;
                }
            })
            .catch(function (error) {
                console.log(error);
                var error_data = error.response.data.error;
                helpers.formError(self,error_data);
            });
        } else {
            axios.put(helpers.apiLink('account/user/address/'+this.props.aId),this.state    
            )
              .then(function (response) {
                if (response.data.success === true) {
                    self.props.saveSuccessMessage(tLang('updated'));
                    var link = '';
                    console.log('self.props.redirectLink',self.props.redirectLink)
                    if (typeof self.props.redirectLink != 'undefined' && self.props.redirectLink !=''  ) {
                        link =  helpers.buildLink(self.props.redirectLink);
                    } else {
                        link = helpers.buildLink("/account/address-book");
                    }
                    window.location = link;
                    return true;
                }
              })
              .catch(function (error) {
                console.log(error);
                var error_data = error.response.data.error;
                helpers.formError(self,error_data);
              });
        }
    }

    componentDidMount(){
       var self = this;
       if (this.mode == 'create') {
        self.setState({loading:false});
       }

       this.iti = helpers.intiPhoneInput('.'+this.props.chooseAddressModalId+' .recipient_phone');
       //this.iti = helpers.intiPhoneInput('.recipient_phone');
    }

    componentDidUpdate(prevProps) {
        if(prevProps.aId !== this.props.aId) {
            if (typeof this.props.aId != 'undefined' && this.props.aId !='') {
                this.getAddressDetail();
                this.mode = 'edit';
            } 
        }

       
    }
    
    render(){
        return(  
            <div>
                    <div id={this.props.chooseAddressModalId} className="modal popupBlock addressFrom">
                    <div className="modal-content select-address-popup">
                    <br/>    
                           <AddressSelect handleUpdateParentState={this.handleUpdateParentState}/>
                    </div>
                    </div>
                    <form className={`address-form ${this.props.chooseAddressModalId}`} address-form onSubmit={this.submitFormProfile}>
                        <div className="row ">
                        <div className="col-12">
                            <div class="form-group">
                            <label for="recipient_name">{tLang('recipient_name')}*</label>
                            <input type="text" class="form-control" id="recipient_name"  placeholder={tLang('recipient_name')}  name="recipient_name"  value={this.state.recipient_name} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.recipient_name_error}</small>
                            
                            </div>

                            <div class="form-group">
                            <label for="phone">{tLang('telephone')}</label>
                            <input id="recipient_phone" type="text" name="recipient_phone" className="form-control phone recipient_phone" value={this.state.recipient_phone} onChange={this.changeState} />
   
                            <small className="helper-text text-danger" >{this.state.recipient_phone_error}</small>
                            
                            </div>

                          
                            <div class="form-group">
                            <label for="address_1">{tLang('address_1')}* ({tLang('sf_address')}) </label>
                              
                            <input id="address_1" type="text" name="address_1" className="form-control"  value={this.state.address_1 || ''} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.address_1_error}</small>
                            </div>


                            <div class="form-group">
                            <label for="address_2">{tLang('address_2')}</label>
                            <input id="address_2" type="text" name="address_2" className="form-control"  value={this.state.address_2 || ''} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.address_2_error}</small>
                            </div>

                            {this.state.country_options.length > 1? 
                            <div class="form-group">
                          
                                <label for="country_id">{tLang('country')}</label>
                                <select className="form-control" name="country_id" value={this.state.country_id || ''} onChange={this.changeCountry} >
                                <option value=""  >{tLang('please_choose')}</option>
                                {
                                    this.state.country_options.map((item,i) => {
                                        return (
                                        <option key={i} value={item.id}>{item.text}</option>
                                        )
                                    })
                                }
                                </select>
                           
                           
                            </div>
                            :
                            ''
                            }

                            {this.state.state_options.length > 1? 
                            <div class="form-group">
                            <div className="">
                                    <label for="state_id">{tLang('regions')} {tLang('option')}</label>
                                    <select className="form-control" name="state_id" value={this.state.state_id || ''} onChange={this.changeState} >
                                    <option value=""  >{tLang('please_choose')}</option>
                                    {
                                        this.state.state_options.map((item,i) => {
                                            return (
                                            <option key={i} value={item.id}>{item.text}</option>
                                            )
                                        })
                                    }
                                    </select>
                                    
                            </div>
                           
                            </div>
                            :
                            ''
                            }

                           
                            {this.state.city_options.length > 1? 
                            <div class="form-group">
                            <div className="">
                                    <label for="state_id">{tLang('areas')} {tLang('option')}</label>
                                    <select className="form-control" name="city_id" value={this.state.city_id || ''} onChange={this.changeState} >
                                    <option value=""  >{tLang('please_choose')}</option>
                                    {
                                        this.state.city_options.map((item,i) => {
                                            return (
                                            <option key={i} value={item.id}>{item.text}</option>
                                            )
                                        })
                                    }
                                    </select>
                                    
                            </div>
                           
                            </div>
                            :
                            ''
                            }

                           

                        </div>
                        </div>

                        <div className="row">
                            <div className="col offset-m2  offset-l2 s12 m8 l8">
                        
                            <button type="submit" className=" button large" name="save" value="Register">{tLang('save')}</button>
                        
                            </div>   
                        </div>

                    </form>           
            </div>    
        )
    }
}




export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AddressForm))