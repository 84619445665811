import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,MetaTag,tLang}  from "Imports/web";
import FacebookLogin from 'react-facebook-login';
 
import ReactDOM from 'react-dom';
import GoogleLogin from 'react-google-login';

const axios = require('axios').default;

 class Login extends Component{

    constructor(props){
        super(props)

        helpers.initConstruct(this);

        this.state = {
            phone : '',
            password:'',
            cre_name:'',
            cre_email:'',
            cre_phone:'',
            cre_password:'',
            cre_confirm_password:'',
            cre_agree_newsletter:false,
            cre_agree_register:false,
            fbAppId:'',
            googleClientId:'',

        };
        
        this.changeState = this.changeState.bind(this);
        this.submitFormLogin = this.submitFormLogin.bind(this)
        this.submitFormRegister = this.submitFormRegister.bind(this)
        this.doFacebookLogin = this.doFacebookLogin.bind(this)
        this.doGoogleLogin = this.doGoogleLogin.bind(this)

        this.checkLogin();
        this.getFacebookLoginAppId();
        this.getGoogleLoginClientId();

    }

    getGoogleLoginClientId(){
        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;
        axios.get(helpers.apiLink('auth/get-google-login-client-id'))
          .then(function (response) {
            //return false;
            if (response.data.status == 1) {
                self.setState({ 'googleClientId': response.data.google_client_id});
            }

          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            if (error.response.status === 401) {
                helpers.showErrorMsg(tLang('login_error'));
            }
        });
    }

    getFacebookLoginAppId(){
        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;
        axios.get(helpers.apiLink('auth/get-facebook-login-app-id'))
          .then(function (response) {
            //return false;
            if (response.data.status == 1) {
                self.setState({ 'fbAppId': response.data.fb_app_id});
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            if (error.response.status === 401) {
                helpers.showErrorMsg(tLang('login_error'));
            }
            //window.location = helpers.buildLink("/login");
            
        });
    }

    doFacebookLogin(response){
        if (response.status == 'unknown') {
            return false;
        }
        var self = this;
        axios.post(helpers.apiLink('auth/facebook-login'), response)
          .then(function (response) {

            self.props.login(response.data);

            if (response.data.token !== '') {
                var redirectAfterLogin = self.props.user.redirectAfterLogin;
                if (redirectAfterLogin != '') {
                    self.props.setRedirectAfterLogin('');
                    window.location = helpers.buildLink(redirectAfterLogin,response.data.default_lang);
                } else {
                    window.location = helpers.buildLink("/account",response.data.default_lang);
                } 
            } else {
                window.location = helpers.buildLink("/login");
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            if (error.response.status === 401) {
                helpers.showErrorMsg(tLang('login_error'));
            }
            //window.location = helpers.buildLink("/login");
            
          });

    }

    doGoogleLogin(response){
        console.log('responseGoogle',response);
        if (typeof response.error != 'undefined' && response.error != 'popup_closed_by_user') {  
            helpers.showErrorMsg(tLang('facebook_login_error') + response.error);
            return false;
        }

        if (typeof response.profileObj != 'undefined') {
            var self = this;
            axios.post(helpers.apiLink('auth/google-login'), response.profileObj)
            .then(function (response) {
            
                self.props.login(response.data);

                if (response.data.token !== '') {
                    var redirectAfterLogin = self.props.user.redirectAfterLogin;
                    if (redirectAfterLogin != '') {
                        self.props.setRedirectAfterLogin('');
                        window.location = helpers.buildLink(redirectAfterLogin,response.data.default_lang);
                    } else {
                        window.location = helpers.buildLink("/account",response.data.default_lang);
                    } 
                } else {
                    window.location = helpers.buildLink("/login");
                }
            })
            .catch(function (error) {
                console.log(error);
                helpers.showErrorMsg(tLang('login_error'));        
            });
        }
    }

    checkLogin(){
        if (typeof this.props.user.token !== 'undefined' &&  this.props.user.token !== ''){
            window.location = "/account";
        }
    }


    changeState(event){
       let changeName = event.target.name
        if (event.target.type == 'checkbox') {
            this.setState({ [changeName]: event.target.checked })
        } else {
            this.setState({ [changeName]: event.target.value })
        }
    }

    submitFormLogin(event){
        // console.log(`現在輸入的tel是：${this.state.phone}`)
        // console.log(`現在輸入的password是：${this.state.password}`)
        //.get(`${someUrl}`, { withCredentials: true })
        event.preventDefault();

        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;
        //axios = helpers.reviseApiHeader(this,axios);
        
        axios.post(helpers.apiLink('auth/login'), {
            email:this.state.email,
            password:this.state.password,
        })
          .then(function (response) {
            
            self.props.login(response.data);

            if (response.data.token !== '') {
                var redirectAfterLogin = self.props.user.redirectAfterLogin;
                if (redirectAfterLogin != '') {
                    self.props.setRedirectAfterLogin('');
                    window.location = helpers.buildLink(redirectAfterLogin,response.data.default_lang);
                } else {
                    window.location = helpers.buildLink("/account",response.data.default_lang);
                } 
            } else {
                window.location = helpers.buildLink("/login");
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            if (error.response.status === 401) {
                helpers.showErrorMsg(tLang('login_error'));
            }
            //window.location = helpers.buildLink("/login");
            
          });
       
        

    }

    submitFormRegister(event){
        event.preventDefault();
        //const data = new FormData(event.target);

        //console.log(data);

        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;

        var self = this;
        axios.post( helpers.apiLink('auth/signup'), {
            country_code:this.state.cre_country_code,
            name:this.state.cre_name,
            email:this.state.cre_email,
            password:this.state.cre_password,
            confirm_password:this.state.cre_confirm_password,
            agree_register:this.state.cre_agree_register,
        })
          .then(function (response) {

            // console.log('response',response);
            // return false;

            //self.setState({ 'token': response.token});
            //self.props.login(response.data);
           
            if (response.data.token !== '') {
                self.props.login(response.data);
                self.props.saveSuccessMessage(tLang('register_success'));

                var redirectAfterLogin = self.props.user.redirectAfterLogin;
                if (redirectAfterLogin != '') {
                    self.props.setRedirectAfterLogin('');
                    window.location = helpers.buildLink(redirectAfterLogin,response.data.default_lang);
                } else {
                    window.location = helpers.buildLink("/account");
                }
                
            } else {
                window.location = helpers.buildLink("/login");
            }

          })
          .catch(function (error) {
            console.log(error);
            
            var error_data = error.response.data.error;
            helpers.formError(self,error_data,'cre_');

            if (error.response.status === 401) {
                self.props.saveErrorMessage(tLang('register_fail'));
               //helpers.showErrorMsg(tLang('register_fail'));
               window.location =  helpers.buildLink("/login");
               return true;
            }
            //window.location = "/login"
            
          });
          event.preventDefault();
        
    }
    

    componentDidMount(){

    }
    
    render(){

        const responseFacebook = (response) => {
            console.log(response);
        }

        const responseGoogle = (response) => {
            console.log(response);
          }

        return(
            <div className="container">
                <MetaTag  title={helpers.buildHTitle(tLang('login'))} />
                <br/><br/>
                <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-6">    
                <ul class="nav nav-tabs">
                    <li class="nav-item login-block-top">
                    <a class="nav-link active" data-toggle="tab" href="#home">{tLang('create_account')}</a>
                    </li>
                    <li class="nav-item login-block-top">
                    <a class="nav-link" data-toggle="tab" href="#menu1">{tLang('login')}</a>
                    </li>
                   
                </ul>

                
                <div class="tab-content login-block">
                    <div id="home" class="container tab-pane active"><br/>
                   

                    <form onSubmit={this.submitFormRegister}>
                    <div class="form-group">
                        <input type="text" class="form-control" id="cre_name" aria-describedby="emailHelp" placeholder={tLang('name')}  name="cre_name"  value={this.state.cre_name} onChange={this.changeState} />
                        <small className="helper-text text-danger" >{this.state.cre_name_error}</small>
                        
                    </div>

                    <div class="form-group">
                        <input type="email" class="form-control" id="cre_email" aria-describedby="emailHelp" placeholder={tLang('email')}   name="cre_email" value={this.state.cre_email} onChange={this.changeState}/>
                        <small className="helper-text text-danger" >{this.state.cre_email_error}</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="cre_password" placeholder={tLang('password')}  name="cre_password" value={this.state.cre_password} onChange={this.changeState} />
                        <small className="helper-text text-danger" >{this.state.cre_password_error}</small>
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" id="cre_confirm_password" placeholder={tLang('confirm_password')}   name="cre_confirm_password" value={this.state.cre_confirm_password} onChange={this.changeState}  />
                        <small className="helper-text text-danger" >{this.state.cre_confirm_password_error}</small>
                    </div>

                    <div class="form-check login-form">
                        <input type="checkbox" class="form-check-input" id="cre_agree_register"  name="cre_agree_register" checked={this.state.cre_agree_register} onChange={this.changeState} />
                        <label class="form-check-label" for="exampleCheck1"> <span dangerouslySetInnerHTML={{__html: tLang('agree_register')}} ></span></label>
                        <div><small className="helper-text text-danger" >{this.state.cre_agree_register_error}</small></div>
                        
                    </div>

                    <br/>
                    <button type="submit" class="  button large" name="register" value="Register" >{tLang('submit')}</button>
                    </form>

                    </div>


                    <div id="menu1" class="container tab-pane fade"><br/>
                    
                    

                    <form onSubmit={this.submitFormLogin}>
                    
                    <div class="form-group">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={tLang('email')}   name="email" value={this.state.email} onChange={this.changeState}/>
                        <small className="helper-text text-danger" >{this.state.email_error}</small>

                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder={tLang('password')}  name="password" value={this.state.password} onChange={this.changeState} />
                        <small className="helper-text text-danger" >{this.state.password_error}</small>

                    </div>

                 
                    <button type="submit" class="  button large" name="login" value="login" >{tLang('login')}</button>
                    <a className="forgot-password-link" href={helpers.buildLink("/forgot-password")} >{tLang('forgot_password')}?</a>
                         
                    </form>
                    
                        <div className="row hide" >
                            <div className="col offset-m3  offset-l3 s12 m6 l6 center social-login-btn">
                            { this.state.fbAppId != '' ? <FacebookLogin
                                appId={this.state.fbAppId}
                                autoLoad={false}
                                fields="name,email,picture"    
                                callback={this.doFacebookLogin}
                                icon="fa-facebook"
                                textButton={'Login with Facebook'} /> :''
                            }                              
                            </div>
                            
                            <div className="col offset-m3  offset-l3 s12 m6 l6 center social-login-btn">
                            { this.state.googleClientId != '' ? <GoogleLogin
                                clientId={this.state.googleClientId}
                                buttonText="Login with Google"
                                onSuccess={this.doGoogleLogin}
                                onFailure={this.doGoogleLogin}
                                cookiePolicy={'single_host_origin'}
                                autoLoad ={false}
                                /> : ''
                            }
                            </div> 
                        </div>
                    
                    </div>
                   
                </div>
                </div>
                </div>



                

                
            </div>
        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Login))