import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,AccountSideBar,AddressCard,Loading,MetaTag,tLang}  from "Imports/web";

let axios = require('axios').default;

class AccountList extends Component{

    constructor(props){
        super(props)
        
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this);
        this.state = {
            addressItems :[],
            loading:true,
        };
        var self = this;

        // get address book
        axios.get( helpers.apiLink('account/user/address')
        )
          .then(function (response) {
            self.setState({"addressItems":response.data});
            self.setState({loading:false});
          })
          .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
          });

    }

    componentDidMount(){
    }

    render(){
        var self = this;
        function deleteAddress(id){
           axios.delete( helpers.apiLink('account/user/address/'+id)
           )
             .then(function (response) {
               self.props.saveSuccessMessage(tLang('updated'));
               helpers.reloadPage();
             })
             .catch(function (error) {
               console.log(error);
               helpers.axiosCatch(error);
               
             });

        }

        let addressItems = this.state.addressItems.map((item,i) => {
            return (
                <div className="card checkout-address darken-1" key={i}>
                    <AddressCard item={item}/>
                    <div className="card-action">
                    <button type="submit" className="button btn-small modal-trigger" data-target="modal1"  name="delete" value="Register" onClick={() => deleteAddress(item.id)} >{tLang('delete')}</button>      
                    
                    <button type="submit" className="button btn-small" name="save" value="edit" onClick={()=>{window.location.href = helpers.buildLink('/account/address-book/address/'+ item.id );}} >{tLang('edit')}</button>

                    </div>
                </div>
            )
        });
        return(     
            <div className="container min-height">
            <MetaTag  title={helpers.buildHTitle(tLang('account'))} />
            <Loading loading={this.state.loading} />
            <div className={`row  ${this.state.loading ? "hide" : " show"} pt-3`}>
                
                <div className="account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3 " >
                    <AccountSideBar />
                </div>         


                <div className={`col-12 col-md-9 `}>
                    <h3 className="center" >{tLang('address_book')}</h3>
                
                    <div className="row  justify-content-md-center">
                    <div id="profile" className="col-sm-12 col-md-6">
                    
                        <div className="account  box">
                        <br/>
                        <div className="account address-list-new-address">    
                        <button type="submit" className="button btn-small" name="save" value="edit" onClick={()=>{window.location.href = helpers.buildLink('/account/address-book/address');}} >{tLang('add_new_address')}</button>
                        </div>
                        <br/>
                        {addressItems} 
                        </div>
                    </div>    
                    </div>
                    
                    

                </div>
            </div>
            </div>
        )
    }
}




export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AccountList))