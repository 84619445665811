
import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,ProductItem,Pagination,Breadcrumbs,helpers,MetaTag,tLang}  from "Imports/web";

const axios = require('axios').default;

 class SearchList extends Component{

    handleClick = (id)=>{
       // this.props.addToCart(id); 
    }

    state = {
        loading:true,
        items: [],
    };

    constructor(props){
        super(props)

        var self = this;
        helpers.initConstruct(self);
        self.keyword = this.props.match.params.keyword;
      
        axios.get(helpers.apiLink('product/search/'+self.keyword+'?page='+self.pageNum)
        ).then(function (response) {
            self.activePage = response.data.current_page;
            self.lastPage = response.data.last_page;
            self.setState({
                'items':response.data.data,
                'loading':false,
            });
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });

   
        //this.setState([{'a':'1'}]);
        //this.updateLanguage = this.updateLanguage.bind(this);
    }



    componentDidUpdate(){
        helpers.checkPaginationUpate(this); 
    }

    render(){
        let itemList = this.state.items.map(item=>{
            return(
                <div className="col-6 col-md-3">
                <ProductItem item={item}/>
                </div>  
            )
        })
        return(
            
            <div className="container product-list min-height">
                <MetaTag  title={helpers.buildHTitle(this.keyword)} />

              
                <Breadcrumbs type="search" id={this.keyword}/>

                { this.state.loading  == false ?
                    this.state.items.length ?

                        <div className="box">
                        <div className="row">
                            {itemList}
                        </div>
                        <Pagination totalPages={this.lastPage} originLink={this.originLink} activePage={this.activePage} />
                        </div>
                    :
                        <div className="center">
                        <p>{tLang('product_not_found')}</p>
                        <br/>
                        </div>
                
                :
                    ''
                }



            </div>
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//      //   addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SearchList))