import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ShipmentItems,CheckoutTotals,Loading,ProductName,MetaTag,tLang,DisplayPrice}  from "Imports/web";
import ShippingCountries from "components/common/ShippingCountries";

let axios = require('axios').default;

class Cart extends Component{

    state = {
        'cartItems':[],
        'shipmentItems':[],
        'checkoutTotalItems':[],
        'remark':'',
        'countryId':(helpers.isEmpty(this.props.cart.countryId) ? config.defaultCountry : this.props.cart.countryId),
        'shipmentId':this.props.cart.shipmentId,
        'shippingCountries':[],
        'cartItemCount':0,
        'couponCode':(helpers.isEmpty(this.props.cart.couponCode) ? '' : this.props.cart.couponCode),
        'couponCode_error':'',
        'loading':true,
    }

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        this.changeQtyState = this.changeQtyState.bind(this);
        //this.getUpdate = this.getUpdate.bind(this);
        this.handleUpdateParentState = this.handleUpdateParentState.bind(this);
        this.changeState = this.changeState.bind(this);
        this.checkCouponCode = this.checkCouponCode.bind(this);
        this.deleteCouponCode = this.deleteCouponCode.bind(this);
        
        var self = this;
        self.getUpdate();

        //this.setState([{'a':'1'}]);
        //this.updateLanguage = this.updateLanguage.bind(this);

        $(document).on('click','.cart .product-delete',function(){
            //return false;
            var productCode = $(this).data('product-code');
            self.updateCart('delete',productCode,0);
        });


    $(document).on('click','.cart .product-increase',function(){
        var $itemQtyTarget = $(this).closest('.quantity-block').find('.quantity-input');
        var itemQtyValue = $itemQtyTarget.val();

        itemQtyValue++;            
        $itemQtyTarget.val(itemQtyValue);

        var productCode = $itemQtyTarget.data('product-code');
  
        // update cart 
        self.updateCart('update',productCode,itemQtyValue);

        // $(this).closest('.quantity-block').find('.update-cart-button').show();
    });

    $(document).on('click','.cart .product-decrease',function(){
        var $itemQtyTarget = $(this).closest('.quantity-block').find('.quantity-input');
        var itemQtyValue = $itemQtyTarget.val();

        if (itemQtyValue > 1) {
            itemQtyValue--;
            $itemQtyTarget.val(itemQtyValue);
            var productCode = $itemQtyTarget.data('product-code');
            self.updateCart('update',productCode,itemQtyValue);
        }  

    });
 
    }

    changeQtyState(event){
      
        let changeValue = event.target.value;
        let index = $(event.target).data('cart-index');
        let productCode = $(event.target).data('product-code');
      
        var cartItems = this.state.cartItems;
        cartItems[index]['qty'] = changeValue;

        //this.state.cartItems[index]['qty'] = changeValue;

        this.setState({
            'cartItems':cartItems});

        this.updateCart('update',productCode,changeValue);

    }

    updateCart(action,productCode,updateQty) {
        var self = this;
        var cartData = {
            'action':action,
            'productCode':productCode,
            'qty':updateQty}

          axios.put( helpers.apiLink('cart/update'),cartData
          ).then(function (response) {
               if (action == 'delete'){
                self.props.saveSuccessMessage(tLang('cart_updated'));
                    helpers.reloadPage();
               }else {
                    self.getUpdate();
                    helpers.showMsg(tLang('cart_updated'));
               }
               
          })
          .catch(function (error) {
              console.log(error);
              // window.location = "/login"
              
          });
    }

    handleUpdateParentState(value){
        var self = this;
        Object.keys(value).map((key) => {
            self.state[key] = value[key];
        });
        this.setState(value);

        //return false;
        this.getUpdate();
    }

    getUpdate() {
       // console.log('shipmentid',shipmentid);
        var self = this;
        
        // self.setState({
        //     'loading':true
        // });
        axios.get(helpers.apiLink('cart?shipment_id='+this.state.shipmentId+'&country_id='+this.state.countryId+'&coupon_code='+this.state.couponCode)
        ).then(function (response) {
            self.props.saveCartItemCount(response.data.cartItemCount);

            self.setState(
                {
                    'cartItems':response.data.cartItems,
                    'shipmentItems':response.data.shipmentItems,
                    'checkoutTotalItems':response.data.checkoutTotalItems,
                    'shippingCountries':response.data.shippingCountries,
                    'countryId':response.data.countryId,
                    'cartItemCount':response.data.cartItemCount,
                    'couponCode':response.data.couponCode,
                    'loading':false   
            });

            console.log('response.data.couponCode',response.data.couponCode);
           
            if (!helpers.isEmpty(response.data.couponCodeInfo)) {
                if ( response.data.couponCodeInfo.error_message != '') {
                    var error_data = {'couponCode':[response.data.couponCodeInfo.error_message]};
                    helpers.formError(self,error_data);
                    $('.helper-text').css("margin-bottom","10px");
                    $('.delete-coupon-btn').hide();
                } else {
                    self.props.saveCouponCode(self.state.couponCode);
                    $('.helper-text').css("margin-bottom","0px");
                    $('.delete-coupon-btn').show();
                    $('.display_coupon_apply_success').show();
                }
            }
            
            console.log('response.data.couponCode',response.data.couponCode);

            if (response.data.couponCode == '') {
                console.log('response.data.couponCode',response.data.couponCode);
                $('.delete-coupon-btn').hide();
                $('.display_coupon_apply_success').hide();
            }
        })
        .catch(function (error) {
           // console.log(error);
           // window.location = "/login"
            
        });
    }

    //to remove the item completely
    handleRemove = (id)=>{
        this.props.removeItem(id);
    }
    //to add the quantity
    handleAddQuantity = (id)=>{
        this.props.addQuantity(id);
    }
    //to substruct from the quantity
    handleSubtractQuantity = (id)=>{
        this.props.subtractQuantity(id);
    }

    // changeShipmentState = (event)=>{
        
    //     console.log('xxxx');
    //     let changeValue = event.target.value;
    //     let index = $(event.target).data('shipment-index');

       
    //     //let productCode = $(event.target).data('product-code');

    //     var shipmentItems = this.state.shipmentItems;

    //     shipmentItems.forEach(function(item){
    //         item.selected = false;
    //     });
        
    //     shipmentItems[index]['selected'] = true;
    //     this.state.shipmentId = shipmentItems[index]['id'];

    //     console.log('shipmentItems',shipmentItems);
    //     //this.state.cartItems[index]['qty'] = changeValue;

    //     this.setState({
    //         'shipmentItems':shipmentItems});

    //     this.getUpdate();    
    //     //this.updateCart('update',productCode,changeValue);
    // }

    // changeQtyState(event){
    //     let changeName = event.target.name
    //     this.setState({ [changeName]: event.target.value })
    // }

    // changeQtyStateIncrease(event){
    //     if (this.state.product_quantity < this.state.product_quantity_max) {
    //         this.state.product_quantity++;
    //     }
    //   //this.setState({ ['product_quantity']: this.state.product_quantity  })
    // }

    goToCheckout(){

        // check login

        // save shipment selected
        
        helpers.redirect('checkout');
    }

    goToShopping(){
        helpers.redirect('/');
    }

    checkCouponCode(){
        var self = this;

        console.log('checkCouponCode',this.state.couponCode);

        if (this.state.couponCode == '') {
            var error_data = {'couponCode':[tLang('please_input_coupon')]};
            helpers.formError(self,error_data);
            $('.helper-text').css("margin-bottom","10px");
            $('.delete-coupon-btn').hide();
        } else {
            self.getUpdate();
        }
        return true;
    }

    deleteCouponCode(){
        var self = this;
        self.props.saveCouponCode('');
        this.setState({'couponCode':''});
    
       window.setTimeout(
        function(){
            self.getUpdate();
        },1000);
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })
    }

    componentDidUpdate(prevProps) {

    }

    render(){

        

        let addedCartItems = this.state.cartItems.length ?
        (  
            this.state.cartItems.map((item,i) =>{
                return(
                    <tr key={i}>
                    <td className="product-thumbnail"><img alt='' src={config.imagePathPrefix + item.main_photo}/></td>
                    <td className="cart-title ellipsed">
                        <span className=" ">
                            <a href={item.permalink}><ProductName productName={item.title}  variationName={item.price.variation_name} productType={item.product_type} /> </a>
                        </span>      
                        <span className="responsive-content-show-md">
                            <b><DisplayPrice originalPrice={item.price.original_price_format} price={item.price.price_format} /></b> 
                            <br/>
                        </span>
                        <span className="responsive-content-show-sm">
                            <div className="quantity-block">
                            <div className="quantity">
                                <span className="cart product-decrease qty-button">
                                <i className="fas fa-minus"></i>
                                </span>
                                <input type="number" className="cart quantity-input" name="product_quantity"  data-cart-index={i} data-product-code={item.productCode} value={item.qty} data-qty={item.qty}  onChange={this.changeQtyState} min="1" />
                                <span className="cart product-increase  qty-button" >
                                <i className="fas fa-plus"  ></i>
                                </span>
                            </div>
                            {
                                item.need_adjust_stock == true ?
                                <div className="out-of-stock">{tLang('not_enough_stock')}：{item.real_qty}</div>       
                                :
                                ''
                            }
                            {/* <button type="submit" name="update_cart" className="update-cart-button button alt" onClick={this.addToCart}>Update</button> */}
                            </div> 
                            <div className="quantity-delete delete"><i  className="fas fa-trash product-delete" data-product-code={item.productCode} ></i></div>
                            <br/>
                            <b>{tLang('total')}: {item.price_total_format}</b>
                        </span>
                    </td>
                    <td className="responsive-hide-col-md"><b><DisplayPrice originalPrice={item.price.original_price_format} price={item.price.price_format} /></b></td>
                    <td className="responsive-hide-col-sm" ><b>          
                        <div className="quantity-block">
                        <div className="quantity">
                            <span className="cart product-decrease qty-button">
                            <i className="fas fa-minus"></i>
                            </span>
                            <input type="number" className="cart quantity-input" name="product_quantity"  data-cart-index={i} data-product-code={item.productCode} value={item.qty} data-qty={item.qty}  onChange={this.changeQtyState} min="1" />
                            <span className="cart product-increase  qty-button" >
                            <i className="fas fa-plus"  ></i>
                            </span>
                        </div>
                        {
                            item.need_adjust_stock == true ?
                            <div className="out-of-stock">{tLang('not_enough_stock')}：{item.real_qty}</div>       
                            :
                            ''
                        }
                        {/* <button type="submit" name="update_cart" className="update-cart-button button alt" onClick={this.addToCart}>Update</button> */}
                        </div> 
                        <div className="quantity-delete delete"><i  className="fas fa-trash product-delete" data-product-code={item.productCode} ></i></div>
                     
                    </b> </td>
                    <td className="responsive-hide-col-sm" ><b>{item.price_total_format}</b> </td>
                    </tr>     
                )
            })
        ):

         (
            <tr>
            <td>Nothing.</td>
            </tr>
         );


        let addedShipmentItems = this.state.shipmentItems.length ?
        (
            this.state.shipmentItems.map((item,i) =>{
                return(
                    <p key={i}>
                    <label>
                        <input name="shipment" type="radio" className="shipment with-gap" value={item.id}  data-shipment-index={i}  checked={item.selected? true : false}  onChange={this.changeShipmentState}/>
                        <span className="options">{item.name} {item.fee_format}  <span className="remark">{item.remark}</span></span> 
                    </label>
                    </p>
                )
            })
        ):
        (
            <p>There is no shipment options available.</p>
        )

       return(
            <div className="container min-height">
                <MetaTag  title={helpers.buildHTitle(tLang('shopping_cart'))} />
                <Loading loading={this.state.loading} />
                <div className={`cart  ${this.state.loading ? "hide" : " show"}`}>
                    <h4>{  tLang('shopping_cart')}</h4>
                
            { this.state.cartItems.length ?

                <div>  

                    <table className="cart table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{tLang('product')}</th>
                        <th scope="col" className="responsive-hide-col-md">{tLang('price')}</th>
                        <th scope="col" className="responsive-hide-col-sm">{tLang('quantity')}</th>
                        <th scope="col" className="responsive-hide-col-sm">{tLang('total')}</th>
                     
                    </tr>
                    </thead>

                    <tbody>
                   
                    {addedCartItems}
                    </tbody>
                    </table>
              

                    <div className="divider"></div>

                    <div className="row">
                        <div className="col-12 col-md-6">    
                            <div class="card" >
                            <div class="card-header">
                            {tLang('shipments')} - 請閱讀<a target="_blank" href={helpers.buildLink("/shipping-policy")}>《送貨政策》</a>
                            </div>
                            <ShippingCountries countryId={this.state.countryId} shippingCountries={this.state.shippingCountries} handleUpdateParentState={this.handleUpdateParentState} />  
                            <form action="#">
                            <ShipmentItems shipmentItems={this.state.shipmentItems} handleUpdateParentState={this.handleUpdateParentState} />
                            </form>
                            </div>
            
                            <div class="card" >
                                <div class="card-header">
                                {tLang('coupon')}
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="coupon_code">{tLang('input_coupon_code')}</label>
                                        <input id="coupon-code" type="text" className="validate form-control" name="couponCode" value={this.state.couponCode} onChange={this.changeState}/>

                                    
                                        <small className="helper-text text-danger" >{this.state.couponCode_error}</small>

                                    </div>
                                    <button type="button" class="button  left  apply-coupon-btn" name="apply" value="coupon_apply"  onClick={this.checkCouponCode} >{tLang('apply')}</button>
                                    <button type="button" class="button grey left delete-coupon-btn " name="apply" value="coupon_delete"  onClick={this.deleteCouponCode} >{tLang('delete_coupon')}</button>
                                    <br/><div className="display_coupon_apply_success"> {tLang('coupon_apply_success')}</div>

                                
                                </div>

                            </div>

                        </div>



                        <div className="col-12 col-md-6">   

                        <div class="card cart-checkout" >
                            <div class="card-header">
                            {tLang('checkout')} 
                            </div>
                            <CheckoutTotals items={this.state.checkoutTotalItems}  />
                        
                            <div className="col-12">
                            <button type="submit" className="checkout button  large right checkout-button" name="save" value="checkout" onClick={this.goToCheckout} >{tLang('go_to_checkout')}</button>

                            </div>
                        </div>
                        </div>
                    
                    </div>

                </div>
             :
                <div className="center">
                <p>{tLang('cart_is_empty')}</p>
                <br/>
            
                </div>
          
            }    
             </div>
            </div>
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Cart))