import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,AccountSideBar,Loading,MetaTag,tLang}  from "Imports/web";

const axios = require('axios').default;

 class ChangePassword extends Component{
   
    constructor(props){
        super(props)

        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this,false);

        this.state = {
            password :'',
            confirm_password :'',
            loading : true,
        };
        this.changeState = this.changeState.bind(this);
        this.submitFormProfile = this.submitFormProfile.bind(this)
        helpers.initConstruct(this);
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })
    }

    submitFormProfile(event){
        event.preventDefault();
        var self = this;
        axios.put( helpers.apiLink('account/user-password'),this.state    
        )
          .then(function (response) {
            if (response.data.success === true) {
                self.props.saveSuccessMessage(tLang('updated'));
                window.location = helpers.buildLink("/account/change-password");
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);
            //window.location = "/login"
          });
    }

    componentDidMount(){
        if (this.props.user.id != null) {
            this.setState({
                'loading':false,
            })
        }
    }
    
    render(){
        return(  
            <div className="container min-height">
            <MetaTag  title={helpers.buildHTitle(tLang('change_password'))} />
            <Loading loading={this.state.loading} />

            <div className={`row  ${this.state.loading ? "hide" : " show"}  pt-3 `}>
            <div className={"account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3"} >
                 <AccountSideBar />
            </div>       
        
            <div className={`col-12 col-md-9`}>

                <h3 className="center">{tLang('change_password')}</h3>
                <div className="row  account-info justify-content-md-center">
                <div id="profile" className="col-sm-12 col-md-6">
                <div className="account  box">
                    <form onSubmit={this.submitFormProfile}>
                            <div class="form-group">
                            <label for="exampleInputEmail1">{tLang('password')}*</label>
                            <input type="text" class="form-control" id="ac-name" aria-describedby="emailHelp" placeholder={tLang('password')}  name="password"  value={this.state.password} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.password_error}</small>
                            </div>

                            <div class="form-group">
                            <label for="exampleInputEmail1">{tLang('confirm_password')}*</label>
                            <input type="text" class="form-control" id="confirm_password" aria-describedby="emailHelp" placeholder={tLang('confirm_password')}  name="confirm_password"  value={this.state.confirm_password} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.password_error}</small>
                            </div>

                          
                      
                
                        <div className="row">
                            <div className="col offset-m2  offset-l2 s12 m8 l8">
                        
                            <button type="submit" className=" button large" name="save" value="Register">{tLang('save')}</button>
                        
                            </div>   
                        </div>   
                    </form>           
                </div>
                </div>
                </div>
            </div>

         
            </div>
            </div>
        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ChangePassword))