
import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,ProductItem,Pagination,config,Breadcrumbs,helpers,MetaTag}  from "Imports/web";

const axios = require('axios').default;

 class SalesPromoteDetail extends Component{

    handleClick = (id)=>{
       // this.props.addToCart(id); 
    }

    state = {
        items: [],
        title: ''
    };

    constructor(props){
        super(props)

        var self = this;
        helpers.initConstruct(self);
        self.id = this.props.match.params.id;
      
        axios.get(helpers.apiLink('sale-promotion/'+self.id+'?page='+self.pageNum)
        ).then(function (response) {
            self.activePage = response.data.current_page;
            self.lastPage = response.data.last_page;
            self.setState({
                'items':response.data.data,
                'title':response.data.sales_promote_data.title,
                'topBanner' :response.data.sales_promote_data.top_banner
            });
            
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });

        //this.setState([{'a':'1'}]);
        //this.updateLanguage = this.updateLanguage.bind(this);
    }



    componentDidUpdate(){
        helpers.checkPaginationUpate(this); 
    }

    render(){
        let itemList = this.state.items.map((item,i)=>{
            return(
                <div className="col-6 col-md-4 " key={i}>
                <ProductItem item={item}/>
                </div>  
            )
        })
        return(       
            <div className="container product-list min-height">
                <MetaTag  title={helpers.buildHTitle(this.state.title)} />

                <Breadcrumbs type="static" title={this.state.title}/>

                { this.state.topBanner ? 
                (
                    <div className="col-12 ">
                    <img width="100%" className="" src={config.imagePathPrefix + this.state.topBanner} alt={this.state.title}/>
                    </div>
                )
                :"" }
                <br/>
                <div className="row">
                    {itemList}
                </div>
                <Pagination totalPages={this.lastPage} originLink={this.originLink} activePage={this.activePage} />
            </div>
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//         items: state.cartReducer.items,
//         user: state.userReducer,
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//      //   addToCart: (id)=>{dispatch(addToCart(id))}
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SalesPromoteDetail))