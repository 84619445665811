import {React,useTranslation} from "Imports";
import {config,helpers,tLang}  from "Imports/web";

const OrderItem = (props) => {

    var item = props.item;
    const {t} = useTranslation();
    return (
        <div className="" key={item.id}>
        <a href={helpers.buildLink('account/orders/' + item.id)}>
            <div class="card-body">
                <h5 class="card-title">Order# <b>{item.order_number}</b></h5>

                <p>{item.order_status_name}({item.payment_status_name})</p>

                <p>{item.order_amount_format} ({item.orderproducts_count} Items)</p>
                       
                <p>{item.created_at}</p>
             
            </div>
        </a>
        <div className="card-add-wishlist">
          
        </div>
        
    </div>   
    )
   
}

export default OrderItem;

