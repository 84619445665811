
import {React,Component,connect,$,withTranslation,Splide} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ProductItem,tLang}  from "Imports/web";

let axios = require('axios').default;

class AdProductsSlide extends Component {

  state = {
    'data': (typeof this.props.data == 'undefined' ? [] : this.props.data)
  }

  constructor(props){
    super(props)
    helpers.initConstruct(this);
    
    var self = this;
    this.initSplide = this.initSplide.bind(this);
    // window.onresize = function(event) {
    //   self.initSplide();
    // }
    //var window = window;

    //self.initSplide();
   
        axios.get( helpers.apiLink('ads/ad-products/'+this.props.type)
        ).then(function (response) {              
          self.setState({'data':response.data});
          self.initSplide();
          //window.onresize = self.initSplide;

        })
        .catch(function (error) {
            console.log(error);            
        });
  }

  initSplide() {
    var $windowWidth = $( window ).width();
    var perPage = (typeof this.props.perPage == 'undefined' ? 4 : this.props.perPage);
    var pagination = false;
    var perMove = 1;
    var arrows = true;
    if ($windowWidth < 375) {
      perPage = 1;
      pagination = true;
      perMove = 1;
      arrows = false;
    } else if ($windowWidth < 767) {
      perPage = 2;
      pagination = true;
      perMove = 2;
      arrows = false;

    }
    if ($('#ad-products-splide-'+this.props.type).length) {
      new Splide( '#ad-products-splide-'+this.props.type, {
        // type   : 'loop',
        perPage: perPage,
        perMove: perMove,
        pagination : pagination,
        arrows : arrows,
        gap: 10
      } ).mount(); 
    }
  }

  // action increment
  componentDidMount() {
     if (!helpers.isMobile()) {
       window.addEventListener("resize", this.initSplide);
     }
  };

  componentDidUpdate(prevProps) {
    if(prevProps.data !== this.props.data) {
      this.setState({'data':this.props.data});
      this.initSplide();
    }
    this.initSplide();

  }

  render () {
  
    let adItemList = this.state.data.map((item,i)=>{
      return(
          <li className="splide__slide  related-products-block" key={i}> 
              <ProductItem item={item}/>
          </li>
      )
    });
    
    return (
     
      this.state.data.length ?
      
      <div >
         <h4 className="center">{this.props.title}</h4>
         <div className="ad-porduct-content">
            <div id={`ad-products-splide-${this.props.type}`} className="ad-products-splide splide">
                <div className="splide__track">
                    <ul className="splide__list">
                      {adItemList}                     
                    </ul>
                </div>
            </div>
        </div>
      </div>
      :
      ""
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AdProductsSlide));
