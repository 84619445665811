import {React,Component,connect,Helmet,withTranslation,$} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";

let axios = require('axios').default;

class AddressSelect extends Component{

    state = {
        'breadcrumbs':[[{'name':'Home','link':'/'}]]
    }

    hTitle = '';

    constructor(props){
        super(props)
        
        helpers.initConstruct(this);

        var self = this;
        //self.aId = this.props.aId;


        this.state = {
            loading : true,
            shipment_service : '',
            shipment_region : '',
            shipment_area : '',
            shipment_address : '',
            shipment_full_address : '',
            shipment_service_options :[],
            shipment_region_options :[],
            shipment_area_options :[],
            shipment_address_options :[],
            country_id: config.defaultCountry,
            state_id : '',
            city_id : '',
        };

        this.changeState = this.changeState.bind(this);
        this.confirmAddress = this.confirmAddress.bind(this);
        this.updateSelectOptions();
    }

    changeState(event){
        let changeName = event.target.name
        
        this.state[changeName] = event.target.value;

        if (changeName == 'shipment_address') {
            this.state['shipment_full_address'] = $('[name=shipment_address]').find("option:selected").text();
            this.state['state_id'] = $('[name=shipment_address]').find("option:selected").data('state-id');
            this.state['city_id'] = $('[name=shipment_address]').find("option:selected").data('city-id');

        }

        this.setState({ [changeName]: event.target.value })
        this.updateSelectOptions();
    }

    confirmAddress(event){
        

        this.props.handleUpdateParentState({
            'address_1':this.state.shipment_full_address.trim(),
            'state_id':this.state.state_id,
            'city_id':this.state.city_id
        }); 
        
        
    }
    

    updateSelectOptions() {
        var self = this;
        var params = {
            'shipment_service':this.state.shipment_service,
            'shipment_region':this.state.shipment_region,
            'shipment_area':this.state.shipment_area,
        }
        axios.get(helpers.apiLink('account/address-select-options'),{'params':params}
        ).then(function (response) {
            self.setState({
                'shipment_service_options' : response.data.shipmentServiceOptions,
                'shipment_region_options' : response.data.shipmentRegionOptions,
                'shipment_area_options' : response.data.shipmentAreaOptions,
                'shipment_address_options' : response.data.shipmentAddressOptions,
            })
        })
        .catch(function (error) {
            console.log(error);
            // window.location = "/login"
            
        });
    }

    componentWillUnmount() {
        

    }


    componentDidUpdate(prevProps) {
       
    }

    render(){
        return(           
            <div id="modal-sf-address">
                {this.state.shipment_service_options.length ? 
                    <div className="col offset-m2  offset-l2 s12 m8 l8">
                        <div className="input-field col s12">
                            <select name="shipment_service" value={this.state.shipment_service || ''} onChange={this.changeState} >
                            <option value=""  >{tLang('please_choose')}</option>
                            {
                                this.state.shipment_service_options.map((item,i) => {
                                    return (
                                    <option key={i} value={item.id}>{item.text}</option>
                                    )
                                })
                            }
                            </select>
                            <label htmlFor="ac-bm">{tLang('shipment_services')}</label>    
                        </div>
                    </div>
                :
                ''
                }

                {this.state.shipment_region_options.length ? 
                    <div className="col offset-m2  offset-l2 s12 m8 l8">
                        <div className="input-field col s12">
                            <select name="shipment_region" value={this.state.shipment_region || ''} onChange={this.changeState} >
                            <option value=""  >{tLang('please_choose')}</option>
                            {
                                this.state.shipment_region_options.map((item,i) => {
                                    return (
                                    <option key={i} value={item.id}>{item.text}</option>
                                    )
                                })
                            }
                            </select>
                            <label htmlFor="ac-bm">{tLang('regions')}</label>    
                        </div>
                    </div>
                :
                ''
                }

                {this.state.shipment_area_options.length ? 
                    <div className="col offset-m2  offset-l2 s12 m8 l8">
                        <div className="input-field col s12">
                            <select name="shipment_area" value={this.state.shipment_area || ''} onChange={this.changeState} >
                            <option value=""  >{tLang('please_choose')}</option>
                            {
                                this.state.shipment_area_options.map((item,i) => {
                                    return (
                                    <option value={item.id}>{item.text}</option>
                                    )
                                })
                            }
                            </select>
                            <label htmlFor="ac-bm">{tLang('areas')}</label>    
                        </div>
                    </div>
                :
                ''
                }

                {this.state.shipment_address_options.length ? 
                    <div className="col offset-m2  offset-l2 s12 m8 l8">
                        <div className="input-field col s12">
                            <select className="select-shipment-address" name="shipment_address" value={this.state.shipment_address || ''} onChange={this.changeState} >
                            <option value=""  >{tLang('please_choose')}</option>
                            {
                                this.state.shipment_address_options.map((item,i) => {
                                    return (
                                    <option value={item.id} data-state-id={item.state_id} data-city-id={item.city_id}  data-full-address={item.full_address} dangerouslySetInnerHTML={{__html: item.text}} ></option>   
                                    )
                                })
                            }
                            </select>
                            <label htmlFor="ac-bm">{tLang('address')}</label>    
                        </div>
                    </div>
                   
                :
                ''
                }

                {this.state.shipment_address != '' ? 
                     <div className="row">
                     <div className="col offset-m4  offset-l4 s12 m4 l4">
                     <button type="submit" className=" button small" name="confirm" value="confirm"  onClick={this.confirmAddress} >{tLang('confirm')}</button>
                     </div> 
                     </div>   
                :
                ''
                }
                
            </div>  
            
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//       user: state.userReducer,
//       notify: state.notifyReducer,
//       session: state.sessionReducer
//     }
//   }

// const mapDispatchToProps = (dispatch)=>{
//     return{
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AddressSelect))