import React from "react";
import { Link } from "react-router-dom";

import {helpers} from "Imports/web";

const makePaginationHref = (pages, active, originLink) => {
  pages = Number(pages);
  active = Number(active);
  
  let pageNumbersArray = [];
  var startIndex, endIndex;
  if (pages <= 10) {
    // less than 10 total pages so show all
    startIndex = 1;
    endIndex = pages;
  } else {
    // more than 9 total pages so calculate start and end pages
    if (active <= 6) {
      startIndex = 1;
      endIndex = 10;
    } else if (active + 4 >= pages) {
      startIndex = pages - 8;
      endIndex = pages;
    } else {
      startIndex = active - 5;
      endIndex = active + 4;
    }
  }

  // add first pagination if startIndex is not the 1
  if (startIndex > 1) {
    pageNumbersArray.push(
      <Link to={"/prodcut-list/" + (active - 1)} key="1">
        <span className="pagin-arrow">&lt;</span>
      </Link>
    );
  }

  // Outer loop to create parent
  for (let i = startIndex; i <= endIndex; i++) {
    pageNumbersArray.push(
      <Link to={ helpers.trimLangInLink(appendPageNum(originLink,i)) } key={i}  onClick={()=>{window.location.href = helpers.buildLink( appendPageNum(originLink,i) )  }   } className={active === i ? "active" : ""}>
        <span>{i}</span>
      </Link>
    );
  }

  // add last pagination if endPage is not the last pagination
  if (endIndex < pages) {
    pageNumbersArray.push(
      <Link  className="pagin-arrow"  to={"/prodcut-list/" + (active + 1)} key={pages}>
        <span >&gt;</span>
      </Link>
    );
  }

  return pageNumbersArray;
};

function appendPageNum(url , num) {

  var arr = url.split('?');
  if (url.length > 1 && arr[1] !== '' && typeof arr[1] != 'undefined') {
    url =  url + '&page=' + num;
  } else {
    url += '?page=' + num;
  }
  return url;
}

function Pagination(props) {
  //const activePage = parseInt(props.params.match.params.page_no);
//  alert(props.pageNumber);
  //const activePage = 9;
 
  return (
    <div className="row">     
    <div className="col-12 pagination center-align">
      {makePaginationHref(props.totalPages, props.activePage, props.originLink)}
    </div>
    </div>
  );
}

export default Pagination;
