
export const saveSuccessMessage=(data)=>{
    return{
        type: 'SAVE_SUCCESS_MESSAGE',
        data
    }
}

export const saveErrorMessage=(data,autoClose)=>{
    return{
        type: 'SAVE_ERROR_MESSAGE',
        autoClose,
        data
    }
}

export const cleanMessage=(data)=>{
    return{
        type: 'CLEAN_MESSAGE',
        data
    }
}