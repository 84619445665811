import {React,useTranslation} from "Imports";
import {config,helpers,tLang}  from "Imports/web";

const LdDataBreadcrumb = (props) => {
  
    var breadcrumbs = props.data;
    var listItem = [];
    var data = [];
    breadcrumbs.map((items,k) => {
        listItem = [];
        let rowLen = items.length;
        items.map((item,i)=>{
            if (rowLen === i + 1) {
                listItem.push(
                    {
                        "@type": "ListItem", 
                        "position": i+1,
                        "name": item.name
                    })
            } else {
                listItem.push(
                    {
                        "@type": "ListItem", 
                        "position": i+1,
                        "name": item.name,
                        "item": (item.link) ? config.shopDomain + helpers.buildLink(item.link) : '' 
                    })
            }
            
        });

        var breadcrumbList = {
            "@context": "http://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": listItem,
        };
        data.push(breadcrumbList); 
    })

    // return false;
    return <script type="application/ld+json">{ JSON.stringify(data)}</script>;
   
}

export default LdDataBreadcrumb;

