//add cart action
export const login= (data)=>{
    return{
        type: 'LOGIN',
        data
    }
}
//remove item action
export const logout=(data)=>{
    return{
        type: 'LOGOUT',
        data
    }
}

export const saveUser=(data)=>{
    return{
        type: 'SAVEUSER',
        data
    }
}

export const switchLanguage=(data)=>{
    return{
        type: 'SWITCHLANGUAGE',
        data
    }
}

export const saveForgotPasswordInfo= (data)=>{
    return{
        type: 'SAVEFORGOTPASSWORDINFO',
        data
    }
}

export const setRedirectAfterLogin=(data)=>{
    return{
        type: 'SETREDIRECTAFTERLOGIN',
        data
    }
}