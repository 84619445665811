import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,MetaTag,tLang}  from "Imports/web";
import ReactCodeInput from 'react-verification-code-input';

const axios = require('axios').default;

 class VerfiyCode extends Component{

    // handleClick = (id)=>{
    //     this.props.addToCart(id); 
    // }
   
    constructor(props){
        super(props)

        helpers.initConstruct(this);

        // axios.defaults.withCredentials = true
        // axios.defaults.headers.common['lang'] =  config.defaultLang;
        // axios.defaults.headers.common['channel-id'] =  config.channelId;

        this.state = {
            phone : ''
        };
        this.verificationCode = '';
        
        this.changeState = this.changeState.bind(this);
        this.submitForm = this.submitForm.bind(this)
        this.verifyonComplete = this.verifyonComplete.bind(this)
        this.checkLogin();
    }

    checkLogin(){
        if (typeof this.props.user.token !== 'undefined' &&  this.props.user.token !== ''){
            window.location = "/account";
        }
    }

    changeState(event){
       let changeName = event.target.name
       this.setState({ [changeName]: event.target.value })
    }

    verifyonComplete(verificationCode) {
        this.verificationCode = verificationCode;
    }

    submitForm(event){
        event.preventDefault();

        // check input size 6 digits
        if (this.verificationCode.length < 6) {
            helpers.showErrorMsg(tLang('Please_enter_your_verification_code'));
            return false;
        }

        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;
        //axios = helpers.reviseApiHeader(this,axios);
       
        axios.post(helpers.apiLink('auth/verify-security-code'), {
            country_code:this.props.user.forgotPasswordCountryCode,
            phone:this.props.user.forgotPasswordPhone,
            verification_code:this.verificationCode
        })
          .then(function (response) {
            
            self.props.login(response.data);

            if (response.data.token !== '') {
                window.location = helpers.buildLink("/account/change-password",response.data.default_lang);
            } else {
                window.location = helpers.buildLink("/login");
            }

            return true;
          })
          .catch(function (error) {
            console.log('error',error);
            helpers.responseError(error.response);
          });
        event.preventDefault()
    }

    componentDidMount(){
   
    }
    
    render(){
        return(
            <div className="container pt-3">
                <MetaTag  title={helpers.buildHTitle(tLang('verify_code'))} />

               
                <h3 className="center">{tLang('forgot_password')}</h3>
                <div className="row subtitle">
                    <div className="col-sm-12   center">
                        {this.props.user.forgotPasswordCountryCode} {this.props.user.forgotPasswordPhone}
                        <br/><br/>
                        {tLang('Please_enter_your_verification_code')} 
                        <br/><br/>
                    </div>
                </div>
                <div className="row">
                <div className="col col-sm-12 verify-code-block center">
                    <ReactCodeInput fieldWidth={50}  fieldHeight={50} fields={6} type={Number}  onComplete={this.verifyonComplete} />
                </div>
                </div>

                <div id="login" className="col s12">
                    <div className="login box">
                        <form onSubmit={this.submitForm}>
                        
                        <div className="row justify-content-md-center ">
                            <div className=" col-sm-12 col-md-6 center "> 
                            <button type="submit" className=" button large" name="register" value="Register"  >{tLang('confirm')}</button>
                            <br/>
                            <br/>
                            <br/>
                            <a className="login" href={helpers.buildLink("/login")} >{tLang('back_login')}</a>
                            </div>
                        </div> 

                        </form>             
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(VerfiyCode))