  
const config = {
    'shopName' : 'Plantaholic.hk',
    'shopLogo' : 'Plantaholic.hk',
    'langSupport' : ['en','zh-HK'],
    'defaultLang' : 'zh-HK',
    'defaultCountry' : '98',
    'channelId' : '1',
    'apiEndPoint' :'https://api.plantaholic.hk/api/',
    'imagePathPrefix' :'https://img.plantaholic.hk/images/',
    'apiWithCredentials' : true,
    'shopDomain' : 'https://www.plantaholic.hk',
    'gtmID' : '',
    'env' : 'production', // local / production
    'paymentStatusPaid' : '2',
    'paymentStatusAwaitingConfirm' : '1',
}


export default config;
                
