import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config, AddressCard, AddressForm,Loading,ShipmentItems,CheckoutTotals,PaymentItems,MetaTag,tLang,ProductName,DisplayPrice}  from "Imports/web";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from 'components/common/CheckoutForm';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_YVzIqUTwiCYcEXO1DPqDrM98');
  
let stripePromise = '';
let axios = require('axios').default;

class CheckoutPaymentStripe extends Component{

    state = {
        'clientSecret' :'',
        'stripeKey':'',
        'orderTotal' :'',
        'loading':false,
      }

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this,true,'/checkout');
        //stripePromise = loadStripe('pk_test_51JiyX0Jm72DVqpckDtbbE0xz9tohhtTU9tM6lScIPrj6JkELlkM3ucDP2uVIj6VI6estu3eo8Iq0xUOHPsyTECx600ltSWCQxx');
  
        this.changeState = this.changeState.bind(this);
        this.oId = this.props.match.params.oId;
        this.goPayment();
        
        // get user default shipping address
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })

        if (changeName == 'orderRemark') {
            this.props.saveOrderRemark(event.target.value);
        }

        if (changeName == 'orderRemarkContact') {
            this.props.saveOrderRemarkContact(event.target.value);
        }

        if (changeName == 'orderRemarkGiftCard') {
            this.props.saveOrderRemarkGiftCard(event.target.value);
        }

        if (changeName == 'orderRemarkShipment') {
            this.props.saveOrderRemarkShipment(event.target.value);
        }

        if (changeName == 'orderRemarkShipmentTime') {
            this.props.saveOrderRemarkShipmentTime(event.target.value);
        }
    }

    goPayment() {
        var self = this;
        // get order id

        axios.get(helpers.apiLink('checkout/payment-stripe-init/'+this.oId),{}
        ).then(function (response) {
            console.log('response',response.data);
            if (response.data.stripe_key != '') {
                stripePromise = loadStripe(response.data.stripe_key);   
            }

            self.setState({
               'clientSecret':response.data.client_secret,
               'stripeKey':response.data.stripe_key,
               'orderTotal': response.data.currency.toUpperCase() + ' ' + response.data.order_amount
            });

        })
        .catch(function (error) {

            // check logined
            if (self.props.user.id == null) {
                self.props.saveSuccessMessage(tLang('please_login'));
                var link = helpers.buildLink("/login");
                window.location = link;
                return true;
            }          
        });
    }

    componentDidUpdate(prevProps) {

    }

    render(){
        var self = this;
       
        const options = {
            // passing the client secret obtained from the server
            clientSecret: this.state.clientSecret,
            locale:helpers.getCurrentLang()
        };
       
        
        console.log('typeof', typeof stripePromise);

        console.log('stripe options',options);
       return(  
    
            <div className="container checkout min-height">
            <Loading loading={this.state.loading} />
            <MetaTag  title={helpers.buildHTitle(tLang('checkout'))} />
                <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`}>
                <h5 className="center">{tLang('credit_card_payment')} </h5>  
                    <br/>        
                    <h4 className="center">{tLang('order_amount')} {this.state.orderTotal}</h4>
                    <br/> 
                    <div className="section shipment">
                    <div className="row">

                        <div className="col l6 s12  offset-l3 ">
                
                        {(this.state.clientSecret != '' && typeof stripePromise != 'undefined' ) ?
                            <Elements stripe={stripePromise} options={options} >
                            <CheckoutForm oId={this.oId}/>
                            </Elements>
                            :''
                        }
                        </div>
                    </div>
                    </div>
                </div>
            </div>
       )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CheckoutPaymentStripe))