import {React,Component,connect,withTranslation,Link} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,SideMenu,config,CatTop,tLang}  from "Imports/web";
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import store from '../.././configureStore';
import Backend from 'i18next-xhr-backend'
let axios = require('axios').default;

class Navbar extends Component{  

    state = {
        navItems: [],
    };

    constructor(props){

        super(props)

        helpers.initConstruct(this);
        var self = this;

       


      
        self.navCatData = '';
        
        //axios = helpers.reviseApiHeader(this,axios);
        axios.get(config.apiEndPoint + 'page/navigation'
        ).then(function (response) {

        self.props.saveCategory(response.data.top);
            
            self.setState({
               'navItems':response.data.list});
               
           // self.navCatData = response.data;
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });
        //this.setState([{'a':'1'}]);
        this.updateLanguage = this.updateLanguage.bind(this);
    }

    forceUpdate() {
    }

    
    openNav() {
        console.log('openNav');  
        //document.getElementById("mySidenav").style.width = "250px";
    }
      
    updateLanguage(lang){
        helpers.updateLanguage(lang);
        // var currentPath = window.location.href.replace(window.location.origin, "");
        // var url = helpers.buildLink(currentPath,lang);
       
        // helpers.redirect(url);
    }

    buildBrandLogo() {
      return  <div className="logo-block">
        <a  role={"link"} aria-label="Home" href="/">
        {
            helpers.getStoreLogo() == '' ? 
            <b>{helpers.getStoreName()}</b>
            : 
            <img width={`100%`} height={`100%`} className="logo-item" src={helpers.getStoreLogo()} alt={helpers.getStoreName()} />
        }
        </a>
        </div> 
    }

    render(){
        

        // const { t,i18n } = this.props;
        //   let itemCatList = [];
        let itemList = this.state.navItems.map((item,i)  =>{
            return(


                <li class="nav-item dropdown" key={i}>
                <a class="nav-link dropbtn" href={ item.permalink.includes("http") ? item.permalink : '/'+ item.permalink} >{item.name}</a>

                { item.hasOwnProperty('children')?
                    <div class="dropdown-content">
                    {item.children.map( (item2,k) => {
                        return (<a  href={'/'+item2.permalink}>{item2.name}</a>)
                        }
                    )}
                    </div>
                    : '' 
                }


               
                </li>

               
            )
        })

        let brandLogo = {
        
        };
      
        return(
           
            <div className="header-stick">
            <div className="navbar-fixed">
                <nav className="nav-wrapper ">
                    
                    <div className="container">


                    <header>
                    <div class="bd-example">    
                    <nav class="navbar navbar-expand-lg navbar-dark ">
                        <div class="header-left">
                        <a href="#"  role={"link"}  aria-label="menu"  className="memu-trigger"><i role={"link"}  onClick={this.openNav} aria-label="menu"  class="fas fa-bars"></i></a>
                        
                        <a href={helpers.buildLink('/')} ><img className="logo" width="200px" src="/image/logo.png" /></a>
                        <a href={helpers.buildLink('/')}  className="logo-m-link" ><img className="logo_m" width="170px" src="/image/logo_m.png" /></a>
                        </div>
                    
                        <div class="navbar-collapse collapse items-bar left" id="navbarCollapse" >
                        <ul class="nav">
                        {itemList}
                        </ul>   
                        </div>

                        <ul class="nav">
                        <li class="nav-item">

                                <div className="header-search-box">
                                <a class="nav-link active" href="#">
                                <i class="fas fa-search"></i>
                                </a>  
                                    <input type="text" name="search" className="search-input " placeholder={tLang('search')} />
                                </div>
                                <div className="header-search-box-m">
                                <a class="nav-link active" href="#">
                                <i class="fas fa-search"></i>
                                </a>  
                                </div>

                          
                          
                        </li>
                       

                        { this.props.user.id != null ? 
                                helpers.isMobile() == true ? 
                                 <li className="nav-item "><a className=" nav-link header-user-m" href={"#"}> <i role={"link"}  aria-label="account" className="fas fa-user"></i> </a></li>
                                :
                                <li className="nav-item"><a className=" nav-link " href={helpers.buildLink('/account/orders')}> <i role={"link"}  aria-label="account" className="fas fa-user"></i> </a></li>
                                
                            :

                            <li className="nav-item">
                            <a className="nav-link" href={helpers.buildLink('/login')}><i class="fas fa-user"></i></a>
                            </li>

                            }


                      
                        <li class="nav-item">
                            <a class="nav-link" href={helpers.buildLink('/cart')}><span class="icon-shopping-bag"></span></a>
                            { helpers.getCartItemCount() > 0 ? <i role={"link"}  aria-label="cart" className="fas fa-circle shopping-cart-count"><span> { helpers.getCartItemCount() }</span> </i>:'' }
                        </li>
                        </ul>
                    </nav>
                    </div>
                    </header>


                      
                       
                    </div>
                </nav>
            </div> 
           
            </div>
           
            
             
            
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Navbar));
