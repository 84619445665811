import {React,useTranslation} from "Imports";
import {config,tLang,helpers,DisplayPrice}  from "Imports/web";

const ProductItem = (props) => {

    var item = props.item;
    const {t} = useTranslation();

    let isMobile = helpers.isMobile();

    return (
       
        <div className="card grid grid-item" key={item.id}>
        <a href={helpers.buildLink(item.permalink)}>
        <div className="card-image">
        <picture>
            <img width={`100%`} height={`100%`} src={config.imagePathPrefix+ item.main_photo} alt={item.title}/>   
        </picture>
        </div>
        <div className="card-content">
            <span className="card-title ">{item.title}</span>
            <span> <DisplayPrice originalPrice={item.original_price_format} price={item.price_format} /></span>
        </div>
        </a>
       
        
        </div>
       
    )
}

export default ProductItem;

