
import {React,Component,connect,$,withTranslation,Splide} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,ProductItem,tLang}  from "Imports/web";

let axios = require('axios').default;

class AdProductsGrid extends Component {

  state = {
    'data': (typeof this.props.data == 'undefined' ? [] : this.props.data)
  }

  constructor(props){
    super(props)
    helpers.initConstruct(this);
    
    var self = this;

        if (this.state.data.length == 0 && this.props.type != '') {
          axios.get( helpers.apiLink('ads/ad-products/'+this.props.type)
          ).then(function (response) {              
            self.setState({'data':response.data});
            //self.initSplide();
            //window.onresize = self.initSplide;
  
          })
          .catch(function (error) {
              console.log(error);            
          });
        }

      
  }

  // action increment
  componentDidMount() {
    
  };

  componentDidUpdate(prevProps) {
    if(prevProps.data !== this.props.data) {
      this.setState({'data':this.props.data});
      //this.initSplide();
    }
    //this.initSplide();

  }

  render () {
  
    let adItemList = this.state.data.map((item,i)=>{
      return(
        <div className="col-6 col-sm-6 col-md-3" key={i}>
              <ProductItem item={item}/>
        </div>
      )
    });
    
    return (
     
      this.state.data.length ?
      
      <div >
        { this.props.title != '' ?
         <h4 className="center feature-product-title">{this.props.title}</h4>
         :''}
         <div className="ad-porduct-content">
           
         <div className="row">    
                      {adItemList}                     
         </div> 
        </div>
      </div>
      :
      ""
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AdProductsGrid));
