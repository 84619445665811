import {React,Component,connect,$,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,tLang}  from "Imports/web";

let axios = require('axios').default;

class PaymentItems extends Component {

    state = {
        'paymentItems':this.props.paymentItems,
        'paymentId':''
    }

    componentDidUpdate(prevProps) {
        if(prevProps.paymentItems !== this.props.paymentItems) {
          this.setState({'paymentItems': this.props.paymentItems});

          
        }
    }

    changePaymentState = (event)=>{        
        let changeValue = event.target.value;
        let index = $(event.target).data('payment-index');

        var paymentItems = this.state.paymentItems;

        paymentItems.forEach(function(item){
            item.selected = false;
        });

        paymentItems[index]['selected'] = true;
        this.state.paymentId = paymentItems[index]['id'];

        this.setState({
            'paymentItems':paymentItems});

        // save to redux
        this.props.savePaymentMethod(paymentItems[index]['id']);
    
        this.props.handleUpdateParentState({'paymentId':paymentItems[index]['id']});    
       
    }

    render () {
        var addedPaymentItems = this.state.paymentItems.length ?
        (
            this.state.paymentItems.map((item,i) =>{
                return(

                    <li key={i} class="list-group-item">

                    <div class="form-check">
                    <input id={`payment_option_`+i} name="payment" type="radio" className="checkout-payment payment with-gap" value={item.id}  data-payment-index={i}  checked={item.selected? true : false}  onChange={this.changePaymentState}/>
                    
                    <label for={`payment_option_`+i} className="form-check-label options" >
                       {item.name} {item.fee_format}  <span className="remark">{item.remark}</span>
                    </label>
                    </div>
                    </li>
                )
            })
        ):
        (
            <p>There is no payment options available.</p>
        )

        return (addedPaymentItems)
    }

}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(PaymentItems));



