import {React} from "Imports";

const DisplayPrice = (props) => {
    return (
        <div class="display-inline-b">
        <b>{props.price}</b>
        <span className="discount-price">
           { typeof props.originalPrice !== 'undefined' && parseFloat(props.originalPrice.split(' ')[1]) > 0 ? props.originalPrice : '' } 
        </span>
        </div>
    )
}

export default DisplayPrice;

