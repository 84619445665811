import {saveSuccessMessage,saveErrorMessage,cleanMessage,saveUser,switchLanguage,getSession,saveShipmentAddress,saveShipmentMethod,saveShippingCountry,saveShipmentStore,savePaymentMethod,saveOrderRemark,saveOrderRemarkGiftCard,saveOrderRemarkContact,saveOrderRemarkShipment,saveOrderRemarkShipmentTime,cleanCart,saveCartItemCount,saveCouponCode,getSetting,saveForgotPasswordInfo,saveCategory,checkVersion,setRedirectAfterLogin} from 'Imports/action';
import {login,logout}  from "Imports/action";

export const mapStateToProps = (state)=>{
   // console.log('run mapStateToProps',state) // state
    return {
        items: state.cartReducer.items,
        user: state.userReducer,
        session: state.sessionReducer,
        notify: state.notifyReducer,
        cart: state.cartReducer,
        setting: state.settingReducer,
        page: state.pageReducer,
        version: state.versionReducer,
    }
}

export const mapDispatchToProps= (dispatch)=>{    
        return{
            saveUser: (data)=>{dispatch(saveUser(data))},
            saveSuccessMessage: (data)=>{dispatch(saveSuccessMessage(data))},
            saveErrorMessage: (data,autoClose)=>{dispatch(saveErrorMessage(data,autoClose))},
            cleanMessage: (data)=>{dispatch(cleanMessage({}))},
            login: (id)=>{dispatch(login(id))},
            logout: (data)=>{dispatch(logout(data))},
            switchLanguage: (data)=>{dispatch(switchLanguage(data))},
            getSession: (data)=>{dispatch(getSession(data))},
            saveShipmentAddress: (data)=>{dispatch(saveShipmentAddress(data))},
            saveShipmentMethod: (data)=>{dispatch(saveShipmentMethod(data))},
            saveShippingCountry: (data)=>{dispatch(saveShippingCountry(data))},
            saveShipmentStore: (data)=>{dispatch(saveShipmentStore(data))},
            savePaymentMethod: (data)=>{dispatch(savePaymentMethod(data))},
            saveOrderRemark: (data)=>{dispatch(saveOrderRemark(data))},
            saveOrderRemarkContact: (data)=>{dispatch(saveOrderRemarkContact(data))},
            saveOrderRemarkGiftCard: (data)=>{dispatch(saveOrderRemarkGiftCard(data))},
            saveOrderRemarkShipmentTime: (data)=>{dispatch(saveOrderRemarkShipmentTime(data))},
            saveOrderRemarkShipment: (data)=>{dispatch(saveOrderRemarkShipment(data))},
            saveCartItemCount: (data)=>{dispatch(saveCartItemCount(data))},
            saveCouponCode: (data)=>{dispatch(saveCouponCode(data))},
            cleanCart: (data)=>{dispatch(cleanCart({}))},
            getSetting: (data)=>{dispatch(getSetting(data))},
            saveForgotPasswordInfo: (data)=>{dispatch(saveForgotPasswordInfo(data))}, 
            saveCategory: (data)=>{dispatch(saveCategory(data))},
            checkVersion: (data)=>{dispatch(checkVersion(data))},
            setRedirectAfterLogin: (data)=>{dispatch(setRedirectAfterLogin(data))},
           

        }
}