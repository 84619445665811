const initState = {
    supportLangs : []
}
const settingReducer= (state = initState,action)=>{   
    //INSIDE HOME COMPONENT
    if(action.type === 'GETSETTING'){
        //state.token = action.data.token;
        state = {};
        state = action.data;

        return{
            ...state,
        }
    }

    return state
}

export default settingReducer