import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,MetaTag,tLang}  from "Imports/web";

const axios = require('axios').default;

 class ForgotPassword extends Component{

    // handleClick = (id)=>{
    //     this.props.addToCart(id); 
    // }
   
    constructor(props){
        super(props)

        helpers.initConstruct(this);

        // axios.defaults.withCredentials = true
        // axios.defaults.headers.common['lang'] =  config.defaultLang;
        // axios.defaults.headers.common['channel-id'] =  config.channelId;

        this.state = {
            email : '',  
        };
        
        this.changeState = this.changeState.bind(this);
        this.submitForm = this.submitForm.bind(this)
      
        this.checkLogin();
    }

    checkLogin(){
        if (typeof this.props.user.token !== 'undefined' &&  this.props.user.token !== ''){
            window.location = "/account";
        }
    }


    changeState(event){
       let changeName = event.target.name
       this.setState({ [changeName]: event.target.value })
    }

    submitForm(event){
        
        var self = this;
        axios.defaults.withCredentials = config.apiWithCredentials;
        axios.defaults.headers.common['lang'] =  config.defaultLang;
        axios.defaults.headers.common['channel-id'] =  config.channelId;
        axios.defaults.headers.common['session-key'] =  self.props.session.key;
        //axios = helpers.reviseApiHeader(this,axios);
       
      
        axios.post(helpers.apiLink('auth/forgot-password'), {
            email:this.state.email,
        })
          .then(function (response) {
            var data = {
                'email':self.state.email,
            }

            self.props.saveForgotPasswordInfo(data);
            helpers.responseError(response);

            window.location = helpers.buildLink("/verify-code",response.data.default_lang);

            return true;
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            if (error.response.status === 401) {
                helpers.showErrorMsg(tLang('login_error'));
            }
            //window.location = helpers.buildLink("/login");
            
          });
        
        event.preventDefault()
    }

    componentDidMount(){      
      
    }
    
    render(){
        return(
            <div className="container min-height">
                <MetaTag  title={helpers.buildHTitle(tLang('forgot_password'))} />

               
                <h3 className="center">{tLang('forgot_password')}</h3>
                <div className="row subtitle">

                <div className="col s12   center">
              
                {tLang('Please_enter_your_registered_email')} 
             
                </div>
                
                </div>

                <div id="login" className="col s12">
                    <div className="login box">
                        <form onSubmit={this.submitForm}>
                        
                        <div className="row justify-content-md-center">
                         <div className="col-sm-12 col-md-6 ">    
                            <div class="form-group">
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={tLang('email')}   name="email" value={this.state.email} onChange={this.changeState}/>
                            <small className="helper-text text-danger" >{this.state.email_error}</small>

                            </div>

                        <button type="submit" class="  button large" name="login" value="login" >{tLang('confirm')}</button>
                        <br/><br/><br/>

                        <div className="row justify-content-md-center ">
                        <div className="col-sm-12 col-md-6 center">    
                            <a className="login" href={helpers.buildLink("/login")} >{tLang('back_login')}</a>
                        </div>
                        </div>

                        </div>
                        </div>
                      
                      
                     

                        </form>             
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ForgotPassword))