import {React,Component,connect,withTranslation,Helmet,$} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,AccountSideBar,config,Loading,MetaTag,tLang}  from "Imports/web";
//import 'intl-tel-input/build/css/intlTelInput.css';
//import intlTelInput from 'intl-tel-input';

let axios = require('axios').default;

class Account extends Component{

    constructor(props){
        super(props)
        
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this);

        this.state = {
            name :'',
            country_code :'',
            phone :'',
            email :'',
            address_1 :'',
            birthday_month :'',
            default_lang:'',
            loading : true,
            tel_error :'',
            pageTitle : helpers.buildHTitle(tLang('my_account'))
        };
        this.changeState = this.changeState.bind(this);
        this.submitFormProfile = this.submitFormProfile.bind(this);

        this.iti = '';
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })
    }

    submitFormProfile(event){
        event.preventDefault()
        var self = this;

        this.state.country_code = '+'+this.iti.getSelectedCountryData().dialCode;
      
        axios.put(helpers.apiLink('account/user'),this.state    
        )
          .then(function (response) {
            if (response.data.success === true) {
                self.props.saveUser(self.state);               
                self.props.saveSuccessMessage(tLang('updated'));

                helpers.updateLanguage(self.state.default_lang);

                // var link = helpers.buildLink("/account");
                // window.location = link;
                // return true;
            }
          })
          .catch(function (error) {
            console.log(error);
            var error_data = error.response.data.error;
            helpers.formError(self,error_data);

            //window.location = "/login"
          });
    }

    componentDidMount(){
       var self = this;
       axios.get( helpers.apiLink('account/user')
       )
         .then(function (response) {  
           self.setState(response.data);
           
           
           if (!helpers.isEmpty(response.data.phone)) {
            self.iti.setNumber('+' + response.data.country_code + response.data.phone);
           }
           self.setState({loading:false});
         })
         .catch(function (error) {
           console.log('error',error);
           helpers.axiosCatch(error);
           //window.location = "/login"
           
         });

        
        
        // var elem = document.querySelector('#phone'); 
        // M.AutoInit(elem, {});

       // var country_code_class = '{$this->countryCodeClass}';


        this.iti = helpers.intiPhoneInput('#phone');

        // var input = document.querySelector(".phone");
        // var self = this;
        // this.iti =  intlTelInput(input, {
        //     separateDialCode: true,
        //     submitCountryCode: true,
        //     hiddenInput: "user_phone_intlTelNumber",
        //     preferredCountries: ['hk'],
        //     initialCountry: 'auto',
        //     geoIpLookup: function(callback) {
        //     var countryCode = '';
        //     if (countryCode == '') {
        //         $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
        //         var countryCode = (resp && resp.country) ? resp.country : "hk";
        //         callback(countryCode);
        //         self.iti.setCountry(countryCode);
        //         });
        //     }
        //     },
        //     utilsScript: "laravel-admin/intl-tel-input/build/js/utils.js?1549804213570",
        // });

        
       
        //iti.setNumber($(country_code_class).val()+ $(tel_class).val());
        

    }

    render(){
          //  console.log('accounttLang',tLang('my_account'));
        return(  
            
            <div className="container min-height">
            <MetaTag  title={this.state.pageTitle} />
            <Loading loading={this.state.loading} />
            
            <div className={`row  ${this.state.loading ? "hide" : " show"} pt-3`}>
                
            <div className="account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3 " >
                 <AccountSideBar />
            </div>       

            <div className={`col-12 col-md-9 `}>
                <h3 className="center" >{tLang('my_account')}</h3>
                
                <div className="row justify-content-md-center">
                <div id="profile" className="col-sm-12 col-md-6">
                <div className="account  box">
                    <form onSubmit={this.submitFormProfile}>
                        <div className="">
                            <div class="form-group">
                            <label for="exampleInputEmail1">{tLang('name')}*</label>
                            <input type="text" class="form-control" id="ac-name" aria-describedby="emailHelp" placeholder={tLang('name')}  name="name"  value={this.state.name} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.name_error}</small>
                            
                            </div>

                            <div class="form-group">
                            <label for="exampleInputEmail1">{tLang('email')}*</label>
                            <input type="email" class="form-control" id="ac-name" aria-describedby="emailHelp" placeholder={tLang('email')} readOnly  name="email"  value={this.state.email} onChange={this.changeState} />
                            <small className="helper-text text-danger" >{this.state.email_error}</small>
                            
                            </div>

                            <div class="form-group">
                            <label for="phone">{tLang('telephone')}</label>
                            <input id="ac-phone" type="text" id="phone" name="phone" className="form-control validate phone" value={this.state.phone} onChange={this.changeState} />
                               
                            <small className="helper-text text-danger" >{this.state.phone_error}</small>
                            
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">{tLang('default_lang')}</label>
                                <select name="default_lang" class="form-control" id="exampleFormControlSelect1" value={this.state.default_lang || ''} onChange={this.changeState}>
                                {
                                        this.props.setting.supportLangs.map((item,i)=>{
                                            return (
                                                <option key={i} value={item.code}>{item.name}</option>
                                            )
                                        }) 
                                    }
                                </select>
                            </div>

                         
                        </div>
                
                        <div className="row">
                            <div className="col offset-m2  offset-l2 s12 m8 l8">
                        
                            <button type="submit" className=" button large" name="save" value="Register">{tLang('save')}</button>
                        
                            </div>   
                        </div>   
                    </form>           
                </div>
                </div>
                </div>
            </div>

         
            </div>
            </div>
        )
    }
}




export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Account))