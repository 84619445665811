import {React,Component,connect,Helmet,withTranslation} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,MetaTag,tLang}  from "Imports/web";
import LdDataBreadcrumb from "./LdDataBreadcrumb";

let axios = require('axios').default;

class Breadcrumbs extends Component{

    state = {
        'breadcrumbs':[]
    }

    hTitle = '';
    hDesc = '';

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        var self = this;
        this.staticTitleUpdate = this.staticTitleUpdate.bind(this);

        if (this.props.type == 'static' || this.props.type == 'redeem') {
            this.staticTitleUpdate(); 
        } else {
            axios.get(helpers.apiLink('page/breadcrumbs?type='+this.props.type+'&id='+this.props.id)
            ).then(function (response) {
                if (self.props.type === 'category') {
                    let breadcrumbsData = response.data[0];
                    self.hTitle = breadcrumbsData[1].name;
                    self.hDesc = breadcrumbsData[1].metaDesc;
                }
               
                // return false;
                response.data.map((item1,i) => {
                    item1.map((item2,k) => {
                        if (k == 0) {
                            if (response.data[i][k]['name'] == 'home') {
                                response.data[i][k]['name'] = tLang(response.data[i][k]['name']);
                            }      
                        }
                    });
                });

                self.setState({'breadcrumbs':response.data});
            })
            .catch(function (error) {
                console.log(error);
                // window.location = "/login"
                
            });
        }   
    }

    componentDidMount(){
      
    }

    componentWillUnmount() {
       
    }

    componentDidUpdate(prevProps) {
        if (prevProps.title != this.props.title) {  
            this.staticTitleUpdate();      
        } 
    }

    handleChecked = (e)=>{

    }

    staticTitleUpdate() {

        if (this.props.type == 'redeem') {
            if (typeof this.props.title != 'undefined') {
                this.state.breadcrumbs = [];
                this.homeBreadCrumb = {'name':tLang('home'),'link':'/'};
                this.state.breadcrumbs.push([this.homeBreadCrumb]);
                this.state.breadcrumbs[0].push({'name':tLang('account'),'link':'/account'});
                this.state.breadcrumbs[0].push({'name':tLang('redemption'),'link':'/account/redemptions'});
                this.state.breadcrumbs[0].push({'name':this.props.title,'link':'/'});
                this.setState({'breadcrumbs': this.state.breadcrumbs});
            }
        } else {
            this.state.breadcrumbs = [];
            this.homeBreadCrumb = {'name':tLang('home'),'link':'/'};
            this.state.breadcrumbs.push([this.homeBreadCrumb]);
            this.state.breadcrumbs[0].push({'name':this.props.title,'link':'/'});
            this.setState({'breadcrumbs': this.state.breadcrumbs});
        }
    }

    render(){
        let itemList = this.state.breadcrumbs.map((items,k)=>{
            let rowLen = items.length;
            return(
                <ol  key={k} >
                {
                items.map((item,i)=>{
                    return(
                    (rowLen === i + 1) ?
                    <li key={i}>{item.name}</li>
                    : 
                    <li  key={i}><a  href={helpers.buildLink(item.link)} ><span  > {item.name} </span></a> &nbsp; / </li>
                    )
                })
                }
               </ol>
            )
        })

        return(            
            <div className="breadcrumbs clearfix"> 
                {/* {
                this.hTitle != '' ? 
                    <MetaTag desc={this.hDesc} title={ helpers.buildHTitle(this.hTitle)} />
                :
                    ''
                } */}
                {itemList}    

                <LdDataBreadcrumb data={this.state.breadcrumbs}/>

            </div>
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//       user: state.userReducer,
//       notify: state.notifyReducer,
//       session: state.sessionReducer
//     }
//   }

// const mapDispatchToProps = (dispatch)=>{
//     return{
//     }
// }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Breadcrumbs))