import {React} from "Imports";
import {config}  from "Imports/web";

const Loading = (props) => {

    var loading = props.loading;
    return (
        <div className={`preloader-wrapper loader ${loading ? "active" : " "}`} >
        <div className="spinner-layer spinner-red-only">
        <div className="circle-clipper left">
            <div className="circle"></div>
        </div><div className="gap-patch">
            <div className="circle"></div>
        </div><div className="circle-clipper right">
            <div className="circle"></div>
        </div>
        </div>
        </div>
    )
   
}

export default Loading;

