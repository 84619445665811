
import {React,Component,connect,withTranslation,Helmet,Splide,Popup} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config,AdProductsSlide,AdProductsGrid,AdBannerHomeBottom,AdBannerHomeGrid,MetaTag,tLang}  from "Imports/web";
import 'reactjs-popup/dist/index.css';

let axios = require('axios').default;



const PopupExample = () => (
    <Popup trigger={<button>Trigger</button>} position="top left">
      {close => (
        <div>
          Content here
          <a className="close" onClick={close}>
            &times;
          </a>
        </div>
      )}
    </Popup>
  );

class Home extends Component{

    constructor(props){
        super(props)
        helpers.initConstruct(this);

        this.state ={
            'loaded':false,
            'bigSlideShow':[],
            'BannerHomeGrid_1':[],
            'AdProductsGridNewArrival':[],
            'AdProductsGridPopular':[],
            'AdBannerHomeBottom':[],            
        }

        var self = this;

        axios.get(helpers.apiLink('page/home-all')
        ).then(function (response) {
    
            self.setState({
                'loaded':true,
                'bigSlideShow':response.data.bigSlideShow,
                'BannerHomeGrid_1':response.data.BannerHomeGrid_1,
                'AdProductsGridNewArrival':response.data.AdProductsGridNewArrival,
                'AdProductsGridPopular':response.data.AdProductsGridPopular,
                'AdBannerHomeBottom':response.data.AdBannerHomeBottom
            });

            if (response.data.bigSlideShow.length > 0) {
                var bannerSlider = new Splide( '#banner-slider', {
                    type   : 'loop',
                    autoplay   : true,
                    pagination : false, 
                } ).mount();    
            } 
           
            // var newArrivalSlider = new Splide( '#new-arrival-slider', {
            //     perPage  : 2,
            //     trimSpace: false,
            // } ).mount();

            var viewProduct = self.props.page.viewProduct;
            if (viewProduct.length > 0) {
                axios.post(helpers.apiLink('page/viewed-products'),{
                    viewed_products:viewProduct
                }
                ).then(function (response) {
                    self.setState({
                        'viewedProducts':response.data,
                    });   
                    console.log('viewedProductsData',response.data);
                })
                .catch(function (error) {
                    console.log(error);
                // window.location = "/login"
                    
                });
            }
   
        })
        .catch(function (error) {
            console.log(error);
           // window.location = "/login"
            
        });


        // axios.get(helpers.apiLink('ads/ad-products/new_arrival')
        // ).then(function (response) {
        //     console.log('response.data',response.data);
        //     self.setState({'newArrivals':response.data});

        //     var newArrivalsSlider = new Splide( '#new-arrivals-slider', {
        //         perPage  : 2,
        //         trimSpace: false,
        //     } ).mount();
        // })
        // .catch(function (error) {
        //     console.log(error);
        //    // window.location = "/login"
            
        // });

       
      

    }

    handleClick = (id)=>{
        this.props.addToCart(id); 
    }

    componentDidMount(){

       // new Splide( '.splide' ).mount();
        // new Splide( '#image-slider', {
        //     rewind     : true,
        //    // heightRatio: 0.5,
        //     pagination : true,
        // } ).mount();;


        // new Splide( '#related-products-splide', {
        //     type   : 'loop',
        //     perPage: 4,
        //     perMove: 1,
        //     pagination  : false,
        // } ).mount();

    }

    render(){

        let bigSlideShow = this.state.bigSlideShow.map((item,i)=>{
            return(      
                <li className="splide__slide" key={i}>
                   { helpers.isMobile() == true ?  
                    <a target={item.popup == 1 ? '_blank' : '_self' } href={item.url}><img  src={config.imagePathPrefix + item.photo_m} alt={item.banner_title}/></a>
                    :
                    <a target={item.popup == 1 ? '_blank' : '_self' } href={item.url}><img  src={config.imagePathPrefix + item.photo} alt={item.banner_title}/></a>
                   }
                </li>
            )
        })

        return(
         
            <div className="container full">
                <MetaTag  title={helpers.buildHTitle('')} />
               
                
                { this.state.loaded == true ? 
                
                <div>
                <div className="banner">
               
                <div id="banner-slider" className="splide">
                    <div className="splide__track">
                        <ul className="splide__list">
                            {bigSlideShow}
                        </ul>
                    </div>
                </div>
                </div>

                <div className="r-m">
                <AdProductsGrid   title={this.state.AdProductsGridPopular.title} type='popular' data={this.state.AdProductsGridPopular.data}/>
                </div>

                <div className="r-m">
                <AdBannerHomeBottom   p="1" data={this.state.AdBannerHomeBottom}/>
                </div>

                

                </div>
                : ''
                }
                
                

                <div className="box">
                <div className="row">
                  
                </div>
                </div>
                
            </div>

        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Home))