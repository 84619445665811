
//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const SAVE_SHIPMENT_ADDRESS = 'SAVE_SHIPMENT_ADDRESS';
export const SAVE_SHIPMENT_STORE = 'SAVE_SHIPMENT_STORE';
export const SAVE_SHIPMENT_METHOD = 'SAVE_SHIPMENT_METHOD';
export const SAVE_SHIPPING_COUNTRY = 'SAVE_SHIPPING_COUNTRY';
export const SAVE_PAYMENT_METHOD = 'SAVE_PAYMENT_METHOD';
export const SAVE_ORDER_REMARK = 'SAVE_ORDER_REMARK';
export const SAVE_ORDER_REMARK_CONTACT = 'SAVE_ORDER_REMARK_CONTACT';
export const SAVE_ORDER_REMARK_SHIPMENT = 'SAVE_ORDER_REMARK_SHIPMENT';
export const SAVE_ORDER_REMARK_SHIPMENT_TIME = 'SAVE_ORDER_REMARK_SHIPMENT_TIME';
export const SAVE_ORDER_REMARK_GIFT_CARD = 'SAVE_ORDER_REMARK_GIFT_CARD';
export const CLEAN_CART = 'CLEAN_CART';
export const SAVE_CART_ITEM_COUNT = 'SAVE_CART_ITEM_COUNT';
export const SAVE_COUPON_CODE = 'SAVE_COUPON_CODE';



