
import {React,withTranslation,Link,useTranslation} from "Imports";
import {tLang} from "Imports/web";
import helpers from "./Helpers";
//import { useTranslation } from 'react-i18next';

function AccountSideBar() { 
    const {t} = useTranslation();
    return (
      <div className="account box">
      <ul className="collection">
      <li className="collection-item"><a href={helpers.buildLink('/account')}>{tLang('my_profile')} </a></li>
      <li className="collection-item"><a href={helpers.buildLink('/account/orders')}>{tLang('my_orders')}</a></li>
      <li className="collection-item"><a href={helpers.buildLink('/account/address-book')}>{tLang('address_book')}</a></li>
      
      <li className="collection-item"><a href={helpers.buildLink('/account/change-password')}>{tLang('change_password')}</a></li>
      <li className="collection-item"><a href={helpers.buildLink('/logout')}>{tLang('logout')}</a></li>
      </ul>
      </div>
    );
}

export default withTranslation()(AccountSideBar);
