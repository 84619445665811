import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,config}  from "Imports/web";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import store from '../.././configureStore';
let axios = require('axios').default;

//let lang = 'zh-HK';

toast.configure({
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

class Init extends Component{

    constructor(props){
      super(props);
      
      axios = helpers.reviseApiHeader(this,axios);

      // get session key
      var self = this;

      // check app version
      var currentVersion = store.getState().versionReducer.version;
  
      // update version
      this.props.checkVersion(currentVersion);

      axios.post(config.apiEndPoint+'auth/session', {
      })
        .then(function (response) {
          self.props.getSession(response.data);

          // if (response.data.token != '') {
          //     window.location = "/account"
          // } else {
          //     window.location = "/login"
          // }

        })
        .catch(function (error) {
          console.log(error);
          //window.location = "/login"  
        });


      // get setting
      axios.get(config.apiEndPoint+'setting', {
      })
        .then(function (response) {
          self.props.getSetting(response.data);
          
           

          // if (response.data.token != '') {
          //     window.location = "/account"
          // } else {
          //     window.location = "/login"
          // }


            // set language 
            //var lang = 'zh-HK';
            var lang = store.getState().userReducer.lang;

          
            var supportLangs = store.getState().settingReducer.supportLangs;
            if (typeof self.props.user.lang !== 'undefined' && self.props.user.lang !== '' && supportLangs.includes(self.props.user.lang) ) {
                lang = self.props.user.lang;
            }
          

            if (!helpers.checkSystemSupportLangs(lang)) {
                lang = store.getState().settingReducer.defaultLang;
                helpers.updateLanguage(lang);
            }
        
            // nouse change language
            //i18n.changeLanguage('en');
            // change user default language
            //i18n.use(initReactI18next) // passes i18n down to react-i18next
            //.init({lng: lang});
        })
        .catch(function (error) {
          console.log(error);
          //window.location = "/login"  
        });

      // display alert message
        if (!helpers.isEmpty(self.props.notify.successMessage)) {
            toast.success(self.props.notify.successMessage);
        }

        if (!helpers.isEmpty(self.props.notify.errorMessage)) { 
            toast.error(self.props.notify.errorMessage,{autoClose: self.props.notify.autoClose});
        }

        self.props.cleanMessage();

 
      // document init
      $(document).on('click', '.memu-trigger', function(e) {
        $('.sidenav-overlay').css({'opacity':1,'display':'block'});

        $("#mySidenav").css({'width':'250px'});
      });
      
      $(document).on('click', '.closebtn', function(e) {
        console.log('closeNav');
    
        $('.sidenav-overlay').css({'opacity':0,'display':'none'});
        $("#mySidenav").css({'width':0});
      });

      $(document).on('click', '.sidenav-overlay', function(e) {
        console.log('closeNav');
    
        $('.sidenav-overlay').css({'opacity':0,'display':'none'});
        $("#mySidenav").css({'width':0});
      });

     
      $(document).on('click', '.product-detail-sub-title', function(e) {
        $(this).find('.product-detail-sub-title-accordion').toggleClass('active');

   
        $(this).closest('.product-meta-block').find('.desc-content').toggleClass('active');
      });

      $(document).on('click', '.header-search-box-m .fa-search', function(e) {
        $('.sidenav-overlay').css({'opacity':1,'display':'block'});
        $("#mySidenav").css({'width':'250px'});
        $('.searchBox .search-input').focus();
      });


      console.log('abc');

        // end

      $(document).on('mouseover', '.nav-shopping-item', function(e) {

          var top = 60; 
          if($(this).hasClass( "normal")){
              top = 0;
          }

          if($(this).closest("li").find(".nav-category-out").css('opacity') == 0) {
            
            $(this).closest("li").find(".nav-category-out").fadeIn('fast')
            // .css({top:95,position:'absolute'})
            .animate({top:top,opacity:1}, 200, function() {
                //callback
            });
          }

      });

      $(document).on('mouseout', '.nav-shopping-item', function(e) {
          //return false;
          if ($(this).closest("li").find('.nav-category-out:hover').length == 0) {
              // do something ;)
              $(this).closest("li").find(".nav-category-out")
              // .css({top:65,position:'absolute'})
              .animate({top:105,opacity:0}, 200, function() {
                  //callback
                  $(this).closest("li").find(".nav-category-out").hide();
              });
          }
      });

      $(document).on('mouseleave', '.nav-category-out', function(e) {
        //return false;
        if ($(this).closest("li").find('.nav-shopping-item:hover').length == 0) {

          $(this)
          // .css({top:65,position:'absolute'})
          .animate({top:105,opacity:0}, 200, function() {
              //callback
              $(this).hide();
          });
        }
          // $(".nav-category-out")
          // // .css({top:65,position:'absolute'})
          // .animate({top:105,opacity:0}, 200, function() {
          //     //callback
          //     $(".nav-category-out").hide();
          // });
      });

      $(document).on('click','.sideMenuShopItem',function(){
          $('.sideCatMenu').addClass('active');

          var $html = $('.nav-category-block.main-category').clone();
          $('.sideCatMenuContent').html($html);

      });

      $(document).on('click','.closeSideCatMenu',function(){
          $('.sideCatMenu').removeClass('active');
      });

      $(document).on('click','.header-user-m',function(e){
         e.preventDefault(); 
         $('.user-menu-overlay').show();
         $('.sideUserMenu').addClass('active');
         $('.sideUserMenu .search-input').focus();
      });

      $(document).on('click','.closeSideUserMenu',function(){
          $('.sideUserMenu').removeClass('active');
         $('.user-menu-overlay').hide();

      });

      $(document).on('click','.header-search-box-m',function(){
          $('.sideSearchMenu').addClass('active');
          $('.sideSearchMenu .search-input').focus();
      });

      $(document).on('click','.closeSideSearchMenu',function(){
          $('.sideSearchMenu').removeClass('active');
      });

      $(document).on('click','nav .header-search-box .fa-search',function(){
          $('nav .search-input').css({'left':'-135px','width':'150px'}).focus();
      });

      $(document).on('blur','nav .search-input',function(){
          $(this).css({'left':'0px','width':'0px'})
      });

      $(document).on('keyup','.search-input',function(e){
          if(e.keyCode == 13)
          {
              var keyword = encodeURIComponent($(this).val());
              helpers.redirect(  helpers.buildLink('/search/'+keyword)  );
              return true;
              //$(this).trigger("enterKey");
          }
      });

      $(document).on('click','.searchBox .fas.fa-search',function(e){
            var keyword = encodeURIComponent($('.searchBox .search-input').val());
            
            if (keyword == '') {
                return false;
            }
          
            helpers.redirect(  helpers.buildLink('/search/'+keyword)  );
            return true;
      });

      $(document).on('click','input .shipment',function(){

      });

      $(document).on('blur','.phone input',function(){
           // $(this).closest('.input-field.phone').find('.helper-text').attr("data-error",'');
           $(this).closest('.input-field.phone').find('.helper-text').hide();

      });

      $(document).on('click','.copy-coupon-code-btn',function(){
        $(this).closest('.display-coupon-code').addClass('active');
        $(this).html($(this).data('copied-text'));
        copyToClipboard($(this).data('coupon-code'));
     });

     function copyToClipboard(element) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(element).select();
        document.execCommand("copy");
        $temp.remove();
    }

      // const input = document.querySelector(".phone");
      // intlTelInput(input, {
      //     // any initialisation options go here
      // });

      // $(window).scroll(function () {
      //     //set scroll position in session storage
      //     console.log('set sessionStorage.scrollPos',$(window).scrollTop());
      //     sessionStorage.scrollPos = $(window).scrollTop();
      //   });
      //   var init = function () {
      //      //return scroll position in session storage
      //      console.log('init sessionStorage.scrollPos','1400');
      //      $(window).scrollTop(sessionStorage.scrollPos || 0)
      //   };
      //   window.onload = init;       
    }

    render(){
        return(
            <div className="Init">  
             <Helmet>
                <html lang={helpers.getLang()} />
            </Helmet> 
            </div>
        )
    }
}

// const mapStateToProps = (state)=>{
//     return {
//       notify: state.notifyReducer,
//       user: state.userReducer,
//       session: state.sessionReducer
//     }
// }

// const mapDispatchToProps= (dispatch)=>{    
//     return{
//         cleanMessage: (data)=>{dispatch(cleanMessage({}))},
//         getSession: (data)=>{dispatch(getSession(data))}
//     }
// }


export default connect(mapStateToProps,mapDispatchToProps)(Init)
