import {React,useTranslation} from "Imports";

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {mapStateToProps,mapDispatchToProps,helpers,config,Init,tLang}  from "Imports/web";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const oId = props.oId;

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: config.shopDomain+helpers.buildLink('checkout/payment-check-status/'+oId),
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br/>
      <div className="center">
      <button class=" button small" disabled={!stripe}>Pay</button>
      </div>
    </form>
  )
};

export default CheckoutForm;