import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,Breadcrumbs,helpers,config,Loading,MetaTag,tLang}  from "Imports/web";

let axios = require('axios').default;

 class Contact extends Component{

    constructor(props){
        super(props)
        helpers.initConstruct(this);
        
        this.state = {
            loading:true,
            data:''
        }

        var self = this;
        
        axios.get( config.apiEndPoint + 'page/' +'contact?type=contact',
        ).then(function (response) {
         
            self.setState({
                'data':response.data,
                loading:false
            });
            
        })
        .catch(function (error) {
            console.log(error);
            // window.location = "/login"
            
        });
    }

    state = {
        data:{}
    };
    
    componentDidMount(){
    }

    render(){

        return(
            <div className="container contact-us min-height">
                <MetaTag  title={helpers.buildHTitle(tLang('contact'))} />
                <Breadcrumbs type="static" title={this.state.data.title}/>
                <Loading loading={this.state.loading} />  
                <div className="box">

                <div className="row">
                    <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`}>
                    <div dangerouslySetInnerHTML={{
                        __html: this.state.data.desc
                    }}></div> 

                    </div> 
                </div>
                
               
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Contact))