import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,Breadcrumbs,helpers,config,Loading,MetaTag,tLang}  from "Imports/web";

let axios = require('axios').default;

 class Article extends Component{
     
    constructor(props){
        super(props)
        helpers.initConstruct(this);
       
        this.state = {
            loading:true,
            data:''
        }

        var self = this;
        this.id = this.props.match.params.id;
     
        axios.get( config.apiEndPoint + 'articles/' + this.id,
        ).then(function (response) {
         
            self.setState({
                'data':response.data,
                loading:false
            });
        
        })
        .catch(function (error) {
            console.log(error);
            // window.location = "/login"
            
        });
    }

    state = {
        data:{
            'title':'',
            'content':''
        },
    };
    
    componentDidMount(){
    }

    render(){

        return(
            <div className="container article min-height">
                <MetaTag desc={ helpers.isEmpty(this.state.data.meta_desc)? this.state.data.title : this.state.data.meta_desc }  title={helpers.buildHTitle(this.state.data.title)} />  
                <Breadcrumbs type="article" id={this.id}/>


                <Loading loading={this.state.loading} />  
                <div className="box">

                <div className="row">
                    <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`} >
                    <div dangerouslySetInnerHTML={{
                        __html: this.state.data.content
                    }}></div> 

                    </div> 
                </div>
                
               
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Article))