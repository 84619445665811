import {React,Component,connect,withTranslation,$,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,Breadcrumbs,helpers,config,Loading,MetaTag,tLang}  from "Imports/web";

let axios = require('axios').default;

 class About extends Component{
     
    constructor(props){
        super(props)
        helpers.initConstruct(this);
      
        this.state = {
            loading:true,
            data:'',
            pageTitle : helpers.buildHTitle(tLang('about')),
        }

        var self = this;
        
        
            axios.get( config.apiEndPoint + 'page/' +'about?type=about-us',
            ).then(function (response) {
              
                self.setState({
                    'data':response.data,
                    loading:false
                });
    
            })
            .catch(function (error) {
                console.log(error);
                // window.location = "/login"
                
            });
        

       
    }

    state = {
        data:{}
    };
    
    componentDidMount(){
    }

    render(){

        return(
            <div className="container min-height">
                <MetaTag  title={this.state.pageTitle} />
                <Breadcrumbs type="static" title={this.state.data.title}/>
                <Loading loading={this.state.loading} />
                <div className="box">

                <div className="row about-content">
                    <div className={`col s12 m12 l12  ${this.state.loading ? "hide" : " show"}`} >
                    <div dangerouslySetInnerHTML={{
                        __html: this.state.data.desc
                    }}></div> 

                    </div> 
                </div>
                


               
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(About))