
import { SAVE_SHIPMENT_ADDRESS,SAVE_SHIPMENT_METHOD,SAVE_SHIPPING_COUNTRY,SAVE_SHIPMENT_STORE,SAVE_PAYMENT_METHOD,SAVE_ORDER_REMARK,CLEAN_CART,SAVE_CART_ITEM_COUNT,SAVE_COUPON_CODE,SAVE_ORDER_REMARK_CONTACT, SAVE_ORDER_REMARK_SHIPMENT,SAVE_ORDER_REMARK_SHIPMENT_TIME, SAVE_ORDER_REMARK_GIFT_CARD } from '../actions/action-types/cart-actions'


const initState = {
    total: 0,
    addressId: '',
    shipmentId: '',
    paymentId: '',
    storeId: '',
    orderRemark:'',
    orderRemarkContact:'',
    orderRemarkGiftCard:'',
    orderRemarkShipment:'',
    orderRemarkShipmentTime:'',
    cartItemCount:0,
    couponCode:''
}
const cartReducer= (state = initState,action)=>{
   
    //INSIDE HOME COMPONENT 
    // if(action.type === ADD_TO_CART){
    //       let addedItem = state.items.find(item=> item.id === action.id)
    //       //check if the action id exists in the addedItems
    //      let existed_item= state.addedItems.find(item=> action.id === item.id)
    //      if(existed_item)
    //      {
    //         addedItem.quantity += 1 
    //          return{
    //             ...state,
    //              total: state.total + addedItem.price 
    //               }
    //     }
    //      else{
    //         addedItem.quantity = 1;
    //         //calculating the total
    //         let newTotal = state.total + addedItem.price 
            
    //         return{
    //             ...state,
    //             addedItems: [...state.addedItems, addedItem],
    //             total : newTotal
    //         }
            
    //     }
    // }
    // if(action.type === REMOVE_ITEM){
    //     let itemToRemove= state.addedItems.find(item=> action.id === item.id)
    //     let new_items = state.addedItems.filter(item=> action.id !== item.id)
        
    //     //calculating the total
    //     let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
  
    //     return{
    //         ...state,
    //         addedItems: new_items,
    //         total: newTotal
    //     }
    // }
    // //INSIDE CART COMPONENT
    // if(action.type=== ADD_QUANTITY){
    //     let addedItem = state.items.find(item=> item.id === action.id)
    //       addedItem.quantity += 1 
    //       let newTotal = state.total + addedItem.price
    //       return{
    //           ...state,
    //           total: newTotal
    //       }
    // }
    // if(action.type=== SUB_QUANTITY){  
    //     let addedItem = state.items.find(item=> item.id === action.id) 
    //     //if the qt == 0 then it should be removed
    //     if(addedItem.quantity === 1){
    //         let new_items = state.addedItems.filter(item=>item.id !== action.id)
    //         let newTotal = state.total - addedItem.price
    //         return{
    //             ...state,
    //             addedItems: new_items,
    //             total: newTotal
    //         }
    //     }
    //     else {
    //         addedItem.quantity -= 1
    //         let newTotal = state.total - addedItem.price
    //         return{
    //             ...state,
    //             total: newTotal
    //         }
    //     }
        
    if(action.type=== SAVE_SHIPMENT_ADDRESS){
        return{
        ...state,
        addressId:action.id
              }
    }

    if(action.type=== SAVE_SHIPMENT_METHOD){
        return{
        ...state,
        shipmentId:action.id
              }
    }

    if(action.type=== SAVE_SHIPPING_COUNTRY){
        return{
        ...state,
        countryId:action.id
              }
    }

    if(action.type=== SAVE_SHIPMENT_STORE){
        return{
        ...state,
        storeId:action.id
              }
    }

    if(action.type=== SAVE_PAYMENT_METHOD){
        return{
        ...state,
        paymentId:action.id
              }
    }

    if(action.type=== SAVE_ORDER_REMARK){
        return{
        ...state,
        orderRemark:action.id
              }
    }

    if(action.type=== SAVE_ORDER_REMARK_SHIPMENT){
        return{
        ...state,
        orderRemarkShipment:action.id
              }
    }

    if(action.type=== SAVE_ORDER_REMARK_SHIPMENT_TIME){
        return{
        ...state,
        orderRemarkShipmentTime:action.id
              }
    }

    if(action.type=== SAVE_ORDER_REMARK_CONTACT){
        return{
        ...state,
        orderRemarkContact:action.id
              }
    }

    if(action.type=== SAVE_ORDER_REMARK_GIFT_CARD){
        return{
        ...state,
        orderRemarkGiftCard:action.id
              }
    }

    if(action.type === CLEAN_CART){
        state = {};
        return{
            ...state,
       }
    }

    if(action.type === SAVE_CART_ITEM_COUNT){
        return{
            ...state,
            cartItemCount:action.id 
       }
    }

    if(action.type === SAVE_COUPON_CODE){
        return{
            ...state,
            couponCode:action.id 
       }
    }

    return state
}

export default cartReducer