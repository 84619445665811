const initState = {
    successMessage:'',
    errorMessage:'',
    autoClose:true,
}
const notifyReducer= (state = initState,action)=>{
   
    //INSIDE HOME COMPONENT
    if(action.type === 'SAVE_SUCCESS_MESSAGE'){
        //state.token = action.data.token;
        state = {};
        state.successMessage = action.data;
        return{
            ...state,
           // email: '2222'
        }
    }
    if(action.type === 'SAVE_ERROR_MESSAGE'){
        //state.token = action.data.token;
        state = {};
        state.errorMessage = action.data;
        state.autoClose = action.autoClose;
        return{
            ...state,
           // email: '2222'
        }
    }
    if(action.type === 'CLEAN_MESSAGE'){
        state = {};
        return{
            ...state,
       }
    }
    
    return state
}

export default notifyReducer