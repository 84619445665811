import {React,Component,connect,withTranslation,Helmet} from "Imports";
import {mapStateToProps,mapDispatchToProps,helpers,AccountSideBar,AddressForm,Loading,MetaTag,tLang}  from "Imports/web";
import queryString from 'query-string';
    
let axios = require('axios').default;

class AddressDetail extends Component{

    constructor(props){
        super(props)
        
        helpers.initConstruct(this);
        helpers.checkLoginRedirect(this);
    
        var self = this;
        self.aId = this.props.match.params.aId;
        
        let params = queryString.parse(this.props.location.search)
        self.isCheckout = helpers.isEmpty(params.checkout)? 0 : params.checkout ;
        console.log('self.isCheckout',self.isCheckout);
        this.mode = 'edit';
        if (typeof self.aId == 'undefined') {
            this.mode = 'create';
        }

        this.state = {
            recipient_name :'',
            recipient_tel :'',
            address_1 :'',
            address_2 :'',
            loading : true,
            tel_error :'',
        };
        this.changeState = this.changeState.bind(this);
        this.submitFormProfile = this.submitFormProfile.bind(this);
        
        if (this.mode == 'edit' && this.aId !='') {
            axios.get( helpers.apiLink('account/user/address/'+this.aId)
            )
              .then(function (response) {
                self.setState(response.data);
                self.setState({loading:false});
              })
              .catch(function (error) {
                console.log(error);
                helpers.axiosCatch(error);
              });
        }else {
            
        }
    }

    changeState(event){
        let changeName = event.target.name
        this.setState({ [changeName]: event.target.value })
    }

    submitFormProfile(event){
        event.preventDefault()
        var self = this;

        if (this.mode == 'create') {
            axios.post(helpers.apiLink('account/user/address'),this.state    
            )
            .then(function (response) {
                if (response.data.success === true) {
                    self.props.saveSuccessMessage(tLang('updated'));
                    console.log('self.isCheckout',self.isCheckout);
                    if (self.isCheckout == 1) {
                        var link = helpers.buildLink("/checkout");
                    } else {
                        var link = helpers.buildLink("/account/address-book");
                    }
                    window.location = link;
                    return true;
                }
            })
            .catch(function (error) {
                console.log(error);
                var error_data = error.response.data.error;
                helpers.formError(self,error_data);
            });
        } else {
            axios.put(helpers.apiLink('account/user/address/'+this.aId),this.state    
            )
              .then(function (response) {
                if (response.data.success === true) {
                    self.props.saveSuccessMessage(tLang('updated'));
                    console.log('self.isCheckout',self.isCheckout);
                    if (self.isCheckout == 1) {
                        var link = helpers.buildLink("/checkout");
                    } else {
                        var link = helpers.buildLink("/account/address-book");
                    }
                    window.location = link;
                    return true;
                }
              })
              .catch(function (error) {
                console.log(error);
                var error_data = error.response.data.error;
                helpers.formError(self,error_data);
              });
        }
    }

    componentDidMount(){
       var self = this;
       if (this.mode == 'create') {
        self.setState({loading:false});
       }
    }
    
    render(){

        return(  
            
            <div className="container min-height">
            <MetaTag  title={helpers.buildHTitle(tLang('address'))} />
            <Loading loading={this.state.loading} />
            <div className={`row  ${this.state.loading ? "hide" : " show"} pt-3`}>
                  
            <div className="account-info-edge d-none d-sm-none d-md-none d-lg-block col-md-3 " >
                    <AccountSideBar />
                </div>    

            <div className={`col-12 col-md-9 `}>
                <h3 className="center" >{ this.mode == 'create'?  tLang('address_create') : tLang('address_edit')   }
             
                </h3>
                <div className="row justify-content-md-center">
                <div id="profile" className="col-sm-12 col-md-6">
                <div className="account  account-info box">
                   <AddressForm aId={this.aId} chooseAddressModalId={'modal-sf-address-edit-detail'}  redirectLink={this.isCheckout? 'checkout': ''} />    
                </div>
                <br/>
                </div>
                </div>
            </div>

         
            </div>
            </div>
        )
    }
}




export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AddressDetail))